import React from 'react';

import Typography from '../Typography';

import classes from './index.module.scss';

export default function Footer() {
    return (
        <div className={classes['Footer']} />
    );
}
