import useMatchMedia from '@ag-media/use-match-media-hook';
import {faCog, faFileInvoiceDollar} from '@fortawesome/pro-light-svg-icons';
import {faBars, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {faUserTie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {Avatar} from 'antd';
import clsx from 'clsx';
import {motion} from 'framer-motion';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Link, useLocation, useMatch, useParams} from 'react-router-dom';

import {useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import {disableBodyScroll, enableBodyScroll, setBodyOverscrollY} from '../../utils/BrowserTools';
import Typography from '../Typography';

import classes from './index.module.scss';

function HeaderMenuEntry({title, to, pattern, icon}: {title: string; to: string; pattern?: string; icon: FontAwesomeIconProps['icon']}) {
    const active = useMatch(pattern || to);

    return (
        <Link
            className={clsx(classes['Header__menuEntry'], {
                [classes['Header__menuEntry--active']]: active,
            })}
            to={to}
        >
            <FontAwesomeIcon fixedWidth icon={icon} />
            <Typography>{title}</Typography>
        </Link>
    );
}

export default function Header() {
    const {ID: UserID, Photo, FirstName} = useSelector(state => ({...state.user}), shallowEqual);
    const {pathname: path} = useLocation();

    const [isMobileMenu] = useMatchMedia(['(max-width: 640px)']);

    // const OrganizationIDs = useRTDBSubscription([...P_Zoho_Users, UserID, 'Organizations']);

    // const params = useParams();
    // const zohoOrganizationID = useSelector(state => state.generics.zohoOrganizationID);

    // const activeOrganization = useMemo(() => {
    //     const organizationIDsArray = [
    //         ...Object.keys(OrganizationIDs?.Invoice || {}),
    //         ...Object.keys(OrganizationIDs?.Books || {}),
    //     ];

    //     const urlOrg = (params['*'] || '').split('/')[0];
    //     if (organizationIDsArray.includes(urlOrg)) {
    //         return urlOrg;
    //     }
    //     else {
    //         return zohoOrganizationID || organizationIDsArray[0];
    //     }
    // }, [OrganizationIDs, zohoOrganizationID, params['*']]);
    // useEffect(() => {
    //     if (activeOrganization && zohoOrganizationID !== activeOrganization) {
    //         store.dispatch.generics.set({
    //             zohoOrganizationID: activeOrganization,
    //         });
    //     }
    // }, [zohoOrganizationID, activeOrganization]);

    const [hamburgerMenu, setHamburgerMenu] = useState(false);
    const openHamburgerMenu = useCallback(() => {
        setHamburgerMenu(true);

        disableBodyScroll();
        setBodyOverscrollY(false);
    }, []);
    const closeHamburgerMenu = useCallback(() => {
        setHamburgerMenu(false);

        enableBodyScroll();
        setBodyOverscrollY(true);
    }, []);

    useEffect(() => {
        return () => {
            enableBodyScroll();
            setBodyOverscrollY(true);
        };
    }, []);

    useEffect(() => {
        if (!isMobileMenu) {
            closeHamburgerMenu();
        }
    }, [isMobileMenu, closeHamburgerMenu]);
    useEffect(() => {
        if (isMobileMenu && hamburgerMenu) {
            closeHamburgerMenu();

            window.scrollTo({
                top: 0,
            });
        }
    }, [path]);

    const menuEntries = useMemo(() => [
        // {
        //     icon: faFileInvoiceDollar,
        //     title: 'Facturi',
        //     to: `/${activeOrganization}`,
        // },
        // {
        //     icon: faCog,
        //     title: 'Setări',
        //     to: '/setari-cont',
        //     pattern: '/setari-cont/*',
        // },
    ], []);
    const logo = (
        <Link className={classes['Header__logo']} to={`/`}>
            <div className={classes['Header__logo__image']} />
            <Typography color="primary" variant="pMedium" weight="medium">Vantix ERP</Typography>
        </Link>
    );

    return (
        <div className={classes['Header']}>
            <div className={classes['Header__inner']}>
                {logo}
                <nav className={classes['Header__desktopMenu']}>
                    {menuEntries.map((menuEntry, index) => (
                        <HeaderMenuEntry {...menuEntry} key={index} />
                    ))}
                </nav>
                {UserID ? (
                    <Link className={classes['Header__settings']} to="./">
                        <Avatar
                            icon={<FontAwesomeIcon icon={faUserTie} />}
                            size={40}
                            src={Photo}
                        />
                        <Typography color="primary">{FirstName}</Typography>
                    </Link>
                ) : null}
                <div className={classes['Header__hamburger']} onClick={hamburgerMenu ? closeHamburgerMenu : openHamburgerMenu}>
                    <FontAwesomeIcon fontWeight="solid" icon={faBars} />
                </div>
            </div>
            <motion.div
                animate={{
                    x: hamburgerMenu ? '0%' : '100%',
                }}
                className={clsx(classes['Header__mobileMenu'])}
                initial={false}
            >
                <div
                    className={classes['Header__mobileMenu__logo']}
                    onClick={(e) => {
                        e.preventDefault();

                        closeHamburgerMenu();
                    }}
                >
                    {logo}

                    <div className={classes['Header__hamburger']}>
                        <FontAwesomeIcon fontWeight="solid" icon={faTimes} />
                    </div>
                </div>
                <div className={classes['Header__mobileMenu__inner']}>
                    {menuEntries.map((menuEntry, index) => (
                        <HeaderMenuEntry {...menuEntry} key={index} />
                    ))}
                </div>
            </motion.div>
        </div>
    );
}
