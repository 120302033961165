export function validEmail(email: string): boolean {
    /* eslint-disable-next-line unicorn/better-regex, no-useless-escape */
    const pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return pattern.test(email);
}

export function validURL(str: string, httpsOnly = false): boolean {
    const pattern = new RegExp(
        '^(https' + (httpsOnly ? '' : '?') + String.raw`:\/\/)` // protocol
        + String.raw`(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}` // domain name
        + String.raw`(\/[a-z\d%_.~+:/\[\]@!$&'()*,;=#-]*)*` // path
        + String.raw`(\?[a-z\d%_.~+:/\[\]@!$&'()*,;=?-]*)?` // query string
        + String.raw`(\#[a-z\d%_.~+:/\[\]@!$&'()*,;=#?-]*)?$`, // fragment locator
        'i',
    );
    return pattern.test(str);
}

export function validPhone(phone: string): boolean {
    // IMPORTANT - do not trust this pattern
    const pattern = /^\+?([\d+])((\d ?)+)$/i;

    if (typeof phone !== 'string') {
        return false;
    }

    if (phone.length < 3) {
        return false;
    }

    return pattern.test(phone);
}

export function validName(str: string): boolean {
    if (typeof str !== 'string') {
        return false;
    }

    str = str.trim();

    return str.length >= 2 && str.length <= 2048;
}

export function validString(str: string): boolean {
    if (typeof str !== 'string') {
        return false;
    }

    str = str.trim();

    return str.length >= 3 && str.length <= 2048;
}

export function validState(str: string): boolean {
    if (typeof str !== 'string') {
        return false;
    }

    str = str.trim();

    return str.length >= 2 && str.length <= 2048;
}

export function removeUndefined(obj) {
    if (obj === undefined) {
        return null;
    }

    if (typeof obj !== 'object') {
        return obj;
    }

    const newObj = {};
    Object.keys(obj).filter(key => obj[key] !== undefined).map(key => newObj[key] = obj[key]);

    return newObj;
}

export function removeEmptyOrFalse(obj) {
    if (obj === undefined) {
        return null;
    }

    if (typeof obj !== 'object') {
        return obj;
    }

    const newObj = {};
    Object.keys(obj).filter(key => obj[key]).map(key => newObj[key] = obj[key]);

    return newObj;
}

export function falsyToNull(obj) {
    if (obj === undefined) {
        return null;
    }

    if (typeof obj !== 'object') {
        return obj;
    }

    const newObj = {};
    Object.keys(obj).map(key => newObj[key] = (obj[key] ? obj[key] : null));

    return newObj;
}

export function pathToObject(obj) {
    if (typeof obj !== 'object') {
        return obj;
    }

    const clean = {};
    const toCheck = [];

    Object.keys(obj).map(key => {
        if (key.includes('/')) {
            const parts = key.split('/');
            const parent = parts[0];
            parts.shift();
            const child = parts.join('/');

            if (typeof clean[parent] !== 'object') {
                clean[parent] = {};
                toCheck.push(parent);
            }


            clean[parent][child] = obj[key];
        }
        else {
            clean[key] = obj[key];
        }
    });

    toCheck.map(parent => {
        clean[parent] = pathToObject(clean[parent]);
    });

    return clean;
}

export function prioritizeObjectMerge(obj1, obj2) {
    obj1 = obj1 && typeof obj1 === 'object' ? obj1 : {};
    obj2 = obj2 && typeof obj2 === 'object' ? obj2 : {};

    const obj = {
        ...obj1,
    };

    for (const key of Object.keys(obj2)) {
        if (obj[key] && obj2[key]) {
            if (typeof obj[key] === 'object' && typeof obj2[key] === 'object') {
                obj[key] = {
                    ...obj[key],
                    ...obj2[key],
                };
            }
            else if (typeof obj[key] !== 'object') {
                obj[key] = obj2[key];
            }
        }
        else if (obj2[key]) {
            obj[key] = obj2[key];
        }
        else if (obj[key] && obj2[key] === null) {
            obj[key] = null;
        }
    }

    return obj;
}

export function truncate(str: string, chars: number): string {
    if (!str || typeof str !== 'string') {
        return str;
    }

    if (str.length <= chars) {
        return str;
    }

    const subString = str.slice(0, Math.max(0, chars - 1));

    return subString.slice(0, Math.max(0, subString.lastIndexOf(' '))) + '...';
}

export function userName(data: {firstName?: string; lastName?: string}): string {
    return [
        data?.firstName,
        data?.lastName,
    ].filter(Boolean).join(' ') || '';
}

export function getMapsUrl(accommodation) {
    return (
        accommodation?.useLatLong ?
            `https://www.google.com/maps/search/?api=1&query=${accommodation?.latitude},${accommodation.longitude}`
            : `https://www.google.com/maps?q=${encodeURIComponent(accommodation.address?.fullAddress)}`
    );
}

export const ACCEPTED_PASSWORD_THRESHOLD = 60;
export const POSSIBLE_PASSWORD_VARIATIONS = ['digits', 'lower', 'upper', 'nonWords'] as const;
// https://stackoverflow.com/a/11268104/2549030
export function passwordStrength(pass: string): {score: number; variations: typeof POSSIBLE_PASSWORD_VARIATIONS[number][]} {
    let score = 0;
    if (!pass) {
        return {
            score,
            variations: [],
        };
    }

    // award every unique letter until 5 repetitions
    const letters = {};
    for (const element of pass) {
        letters[element] = (letters[element] || 0) + 1;
        score += 5 / letters[element];
    }

    // bonus points for mixing it up
    const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (const check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return {
        score: Math.round(score),
        variations: Object.keys(variations).filter(variation => variations[variation]),
    };
}

export function validNumber(input: string): boolean {
    if (!input) {
        return false;
    }

    const isJSNumber = !Number.isNaN(Number(input.trim()));

    return isJSNumber && !input.trim().endsWith('.');
}
export function validPositiveNumber(input: string): boolean {
    const isValidNumber = validNumber(input);

    return isValidNumber && !input.trim().startsWith('-');
}
export function validNegativeNumber(input: string): boolean {
    const isValidNumber = validNumber(input);

    return isValidNumber && input.trim().startsWith('-');
}
