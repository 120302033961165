import {stripDiacritics} from '@vantix/functions/isomorphic/stringTools';
import {P_StockReception_Source_Shop, P_Supplier_Source_Shop} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import {useEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import FormSelect from '@/components/FormSelect';
import Input from '@/components/Input';
import Typography from '@/components/Typography';
import {RTDB, addToUpdateObject, useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import {FormInputs, calculateFormTotals} from '../common';

import classes from './index.module.scss';

const filterSelect = (input: string | number, option?: {label?: string | number}) => {
    if (typeof input === 'number') {
        input = input.toString();
    }
    else if (typeof input !== 'string') {
        return false;
    }
    if (!input) {
        return false;
    }

    const sanitisedInput = stripDiacritics(input.toLowerCase());

    const searchFor = [option?.label];

    return (
        searchFor
            .map(e => typeof e === 'number' ? e.toString() : e)
            .filter(Boolean)
            .some(term => stripDiacritics(term).toLowerCase().includes(sanitisedInput))
    );
};

export default function InvoiceInfo({ShopID, ReceptionID}: {ShopID: string; ReceptionID: string}) {
    const Suppliers = useRTDBSubscription([...P_Supplier_Source_Shop, ShopID, 'Supplier']);
    const AllSuppliers = useMemo(() => (
        Object.values(Suppliers || {})
            .map(Supplier => ({
                value: Supplier.ID,
                label: Supplier.Name,
            }))
    ), [Suppliers]);

    const {register, formState: {errors, dirtyFields}} = useFormContext<FormInputs>();

    const formData = useWatch<FormInputs>();

    useEffect(() => {
        const Update = {};

        const basePath = [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID] as const;
        const dbReception = RTDB.store.selectPath(basePath);

        for (const key of ([
            'SupplierID',
            'SupplierInvoiceNumber',
            'SupplierInvoiceValueWithoutVAT',
            'SupplierInvoiceValueVAT',

            'SupplierInvoiceDate',
            'SupplierInvoiceDueDate',
        ] satisfies (keyof typeof dbReception)[])) {
            const formValue = formData[key];

            if (
                formValue !== undefined
                && (dbReception[key] || null) !== (formValue || null)
            ) {
                addToUpdateObject(Update, [...basePath, key], formValue || null);
            }
        }

        store.dispatch.sync.merge(Update);
    }, [formData?.SupplierID, formData?.SupplierInvoiceNumber, formData?.SupplierInvoiceValueWithoutVAT, formData?.SupplierInvoiceValueVAT, formData?.SupplierInvoiceDate, formData?.SupplierInvoiceDueDate]);

    const {
        formTotalValueWithoutVAT,
        formTotalValueVAT,
        valueVATDifference,
        valueWithoutVATDifference,
    } = calculateFormTotals(formData);

    return (
        <div className={classes['InvoiceInfo']}>
            <div className={classes['SupplierInfo']}>
                <div style={{flex: '1 1 100%', marginTop: '4px'}}>
                    <Typography
                        color="secondary"
                        style={{margin: '2px 0 3px'}}
                        variant="pSmall"
                        weight="medium"
                    >Furnizor</Typography>
                    <FormSelect
                        showSearch
                        filterOption={filterSelect}
                        name="SupplierID"
                        options={AllSuppliers}
                        placeholder="Alege furnizorul"
                        rules={{
                            required: {
                                message: 'Câmp obligatoriu',
                                value: true,
                            },
                        }}
                        size="large"
                    />
                </div>
                <Input
                    className={clsx({
                        [classes['DirtyInput']]: dirtyFields?.SupplierInvoiceNumber,
                    })}
                    error={errors?.SupplierInvoiceNumber?.message}
                    label="Număr factură furnizor"
                    {...register('SupplierInvoiceNumber', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                    })}
                />
            </div>
            <div className={classes['SupplierInfo']}>
                <Input
                    error={errors?.SupplierInvoiceDate?.message}
                    label="Data emitere factură furnizor"
                    {...register('SupplierInvoiceDate')}
                />
                <Input
                    error={errors?.SupplierInvoiceDueDate?.message}
                    label="Data scadență factură furnizor"
                    {...register('SupplierInvoiceDueDate')}
                />
            </div>
            <div className={classes['InvoiceValueInfo']}>
                <Input
                    className={clsx({
                        [classes[valueWithoutVATDifference.isEqualTo('0') ? '' : 'WarnInput']]: true,
                    })}
                    error={errors?.SupplierInvoiceValueWithoutVAT?.message}
                    label="Valoare factură fără TVA"
                    {...register('SupplierInvoiceValueWithoutVAT', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                    })}
                />
                <Input
                    className={clsx({
                        [classes[valueVATDifference.isEqualTo('0') ? '' : 'WarnInput']]: true,
                    })}
                    error={errors?.SupplierInvoiceValueVAT?.message}
                    label="Valoare TVA factură"
                    {...register('SupplierInvoiceValueVAT', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                        validate: (value, formValues) => {
                            if (BigNumber(value).isGreaterThan(BigNumber(formValues?.SupplierInvoiceValueWithoutVAT).multipliedBy('0.2'))) {
                                return 'Valoare TVA depășește 19% din valoarea facturii';
                            }

                            return true;
                        },
                    })}
                />
                <Typography color="secondary" variant="pLarge" weight="medium">
                    <span>Valoare produse fără TVA:</span>
                    <Typography color="primary" display="inline" style={{marginLeft: '8px'}}>{formTotalValueWithoutVAT.isNaN() ? '-' : `${formTotalValueWithoutVAT.toFixed(2)}RON`}</Typography>
                </Typography>
                <Typography color="secondary" variant="pLarge" weight="medium">
                    <span>Valoare TVA produse:</span>
                    <Typography color="primary" display="inline" style={{marginLeft: '8px'}}>{formTotalValueVAT.isNaN() ? '-' : `${formTotalValueVAT.toFixed(2)}RON`}</Typography>
                </Typography>
            </div>
        </div>
    );
}
