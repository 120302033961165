import {calculateVAT} from '@vantix/functions/isomorphic/products';
import {T_DB_StockReception} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {MRT_ColumnDef} from 'material-react-table';
import {createContext} from 'react';
import {DeepPartialSkipArrayKey} from 'react-hook-form';

export interface TableData {
    ID: string,
    NrCrt: number,
    IsDiscount?: boolean,
}

export type TableEditProps = Parameters<MRT_ColumnDef<TableData>['Edit']>[0];

export type FormInputs = Omit<T_DB_StockReception, 'Items'> & {
    Items: Record<string, T_DB_StockReception['Items'][string] & {
        Loaded?: boolean,

        ProductUnit?: string,
        ProductUnitDecimals?: number,
        RequiresPurchaseOf?: string,

        InvoicedAmount?: string,
        ReceivedAmount?: string,
    }>,
};

export interface LastUpdatedInputs {
    onChange: (fieldName: string) => void,
}

export const OriginalValuesContext = createContext<FormInputs>(null);
export const LastUpdatedInputsContext = createContext<LastUpdatedInputs>(null);

export function calculateFormTotals(formData: DeepPartialSkipArrayKey<FormInputs>) {
    const formTotalValueWithoutVAT = (
        Object.values(formData.Items || {})
            .filter(Boolean)
            .flatMap(Item => {
                return [
                    Item.AcquisitionTotalPrice ? BigNumber(Item.AcquisitionTotalPrice).decimalPlaces(2) : BigNumber(Item.UnitAcquisitionPrice).multipliedBy(Item.InvoicedAmount).decimalPlaces(2),
                    ...(Item?.RequiresPurchaseOf ? (
                        Object.entries(JSON.parse(Item?.RequiresPurchaseOf) || {})
                            .map(([RequiredProductID, multiplier]: [string, string]) => (
                                // todo: make this actually load the product data
                                BigNumber(Item.InvoicedAmount).multipliedBy(multiplier).multipliedBy('0.5')
                            ))
                    ) : []),
                ];
            })
            .filter(val => !val.isNaN())
            .reduce((acc, val) => acc.plus(val), BigNumber('0')) // eslint-disable-line unicorn/no-array-reduce
    ).decimalPlaces(2);
    const formTotalValueVAT = (
        Object.values(formData.Items || {})
            .filter(Boolean)
            .map(Item => {
                const unitPrice = Item.AcquisitionTotalPrice ? BigNumber(Item.AcquisitionTotalPrice).dividedBy(Item.InvoicedAmount).decimalPlaces(6) : Item.UnitAcquisitionPrice;
                const acquisitionTotalPrice = BigNumber(unitPrice).multipliedBy(Item.InvoicedAmount).decimalPlaces(2);

                return calculateVAT(acquisitionTotalPrice, Item.VATRateAcquisition).decimalPlaces(2);
            })
            .filter(val => !val.isNaN())
            .reduce((acc, val) => acc.plus(val), BigNumber('0')) // eslint-disable-line unicorn/no-array-reduce
    ).decimalPlaces(2);

    const valueWithoutVATDifference = BigNumber(formData.SupplierInvoiceValueWithoutVAT).decimalPlaces(2).minus(formTotalValueWithoutVAT).abs();
    const valueVATDifference = BigNumber(formData.SupplierInvoiceValueVAT).decimalPlaces(2).minus(formTotalValueVAT).abs();

    return {
        formTotalValueWithoutVAT,
        formTotalValueVAT,
        valueWithoutVATDifference,
        valueVATDifference,
        totalDifference: valueWithoutVATDifference.plus(valueVATDifference),
    };
}
