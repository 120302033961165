import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faArrowRightArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal, Select, Spin} from 'antd';
import clsx from 'clsx';
import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {RTDB, addToUpdateObject, useRTDBSubscription} from 'project-rtdb';

import Button from '../../../components/Button';
import RoCompanyName from '../../../components/RoCompanyName';
import Typography from '../../../components/Typography';
import {WatchPath} from '../../../utils/Routing';

import classes from './index.module.scss';

function OrganizationCifLink({OrganizationID, CompanyID}: {OrganizationID: string; CompanyID: string}) {
    const userID = useSelector(state => state.user?.ID);

    const OrganizationName = useRTDBSubscription([...P_Zoho_Organizations, OrganizationID, 'Name']);
    const CompanyName = useRTDBSubscription([...P_ANAF_Companies, CompanyID, 'Name']);
    const HasCompanyIDRights = useRTDBSubscription([...P_RBAC_Dynamic_ByUser, userID, 'CompanyAccess', CompanyID, 'ANAFSubmit']);

    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className={classes['OrganizationCifLink']}>
            <Select
                disabled
                options={[{label: `Zoho - ${OrganizationName}`, value: 1}]}
                size="large"
                style={{width: '100%'}}
                value={1}
            />
            <FontAwesomeIcon
                icon={faArrowRightArrowLeft}
                opacity={0.8}
                size="2x"
            />
            <Select
                disabled
                options={[{label: `RO${CompanyID} - ${CompanyName}`, value: 1}]}
                size="large"
                style={{width: '100%'}}
                value={1}
            />
            <Button
                disabled={loading}
                special="red"
                variant="outlined"
                onClick={() => {
                    setModalOpen(true);
                }}
            ><FontAwesomeIcon icon={faTrash} style={{fontSize: '16px'}} /></Button>
            <div style={{display: 'none'}}><RoCompanyName CompanyID={CompanyID} /></div>
            <Modal
                cancelText="Anulează"
                confirmLoading={loading}
                okText="Șterge asocierea"
                okType="danger"
                open={modalOpen}
                title="Șterge asocierea"
                onCancel={() => setModalOpen(false)}
                onOk={async () => {
                    if (loading) {
                        return;
                    }

                    setLoading(true);
                    try {
                        const Update = {};

                        addToUpdateObject(Update, [...P_Zoho_AnafLink_ByOrganization, OrganizationID], null);
                        addToUpdateObject(Update, [...P_Zoho_AnafLink_ByCompany, CompanyID], null);
                        addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'PrefixedVATID'], null);
                        addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'PaysVAT'], null);
                        addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'LegalName'], null);
                        addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'RoComID'], null);

                        await RTDB.write(Update);
                    }
                    catch {}
                    setLoading(false);
                }}
            >
                <Typography>Ești sigur că vrei să ștergi asocierea dintre <Typography display="inline" weight="medium">Zoho - {OrganizationName}</Typography> și <Typography display="inline" weight="medium">RO{CompanyID} - {CompanyName}</Typography>?</Typography>
                <br />
                <Typography>Această acțiune poate întrerupe procese critice în raportarea facturilor afacerii tale!</Typography>
                {HasCompanyIDRights ? (
                    null
                ) : (
                    <Typography style={{marginTop: '8px'}}><Typography color="error" display="inline" weight="medium">Atenție!</Typography> Nu ai drept de access pentru a recrea această asociere!</Typography>
                )}
            </Modal>
        </div>
    );
}

const filterSelect = (input: string, option?: {label: string}) => {
    return (
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    );
};

export function useNonLinkedAvailableCompanies() {
    const userID = useSelector(state => state.user?.ID);
    const ExistingLinks = useSelector(() => RTDB.store.selectPath([...P_Zoho_AnafLink_ByOrganization]));
    const Companies = useSelector(() => RTDB.store.selectPath([...P_ANAF_Companies]));

    const AvailableCompanies = useRTDBSubscription([...P_RBAC_Dynamic_ByUser, userID, 'CompanyAccess']);

    const companyOptions = (
        Object.keys(AvailableCompanies || {})
            .filter(companyID => AvailableCompanies[companyID]?.ANAFSubmit)
            .filter(companyID => !Object.values(ExistingLinks || {}).map(e => e + '').includes(companyID))
            .map(companyID => ({
                value: companyID,
                label: `RO${companyID} - ${Companies?.[companyID]?.Name}`,
            }))
    );

    return companyOptions;
}

export function computeLinkZohoOrganizationToCompany({OrganizationID, Company}: {
    OrganizationID: T_DB_OrganizationID,
    Company: T_DB_ANAFCompanyData,
}) {
    if (!OrganizationID || !Company?.CompanyID) {
        return {};
    }

    const Update = {};

    addToUpdateObject(Update, [...P_Zoho_AnafLink_ByOrganization, OrganizationID], Company?.CompanyID);
    addToUpdateObject(Update, [...P_Zoho_AnafLink_ByCompany, Company?.CompanyID], OrganizationID);
    addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'PrefixedVATID'], `RO${Company?.CompanyID}`);
    addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'PaysVAT'], Company?.PaysVAT || null);
    addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'LegalName'], Company?.Name || null);
    addToUpdateObject(Update, [...P_Zoho_Data, OrganizationID, 'Extra', 'CompanyData', 'RoComID'], Company?.RoComID || null);

    return Update;
}

function NewOrganizationCifLink() {
    const userID = useSelector(state => state.user?.ID);
    const OrganizationIDs = useRTDBSubscription([...P_Zoho_Users, userID, 'Organizations']);
    const Organizations = useSelector(() => RTDB.store.selectPath([...P_Zoho_Organizations]));
    const ExistingLinks = useSelector(() => RTDB.store.selectPath([...P_Zoho_AnafLink_ByOrganization]));

    const Companies = useSelector(() => RTDB.store.selectPath([...P_ANAF_Companies]));
    const nonLinkedCompanies = useNonLinkedAvailableCompanies();

    const organizationOptions = (
        [
            ...Object.keys(OrganizationIDs?.Invoice || {}),
            ...Object.keys(OrganizationIDs?.Books || {}),
        ].filter(organizationID => !ExistingLinks?.[organizationID])
            .map(organizationID => ({
                value: organizationID,
                label: `Zoho - ${Organizations?.[organizationID]?.Name}`,
            }))
    );

    const [organizationID, setOrganizationID] = useState<string>(undefined);
    const [companyID, setCompanyID] = useState<string>(undefined);

    const [loading, setLoading] = useState(false);

    return (
        <div className={clsx(classes['OrganizationCifLink'], classes['OrganizationCifLink--new'])}>
            <Select
                showSearch
                filterOption={filterSelect}
                options={organizationOptions}
                placeholder="Alege organizația"
                size="large"
                style={{width: '100%'}}
                value={organizationID}
                onChange={(value, option: typeof organizationOptions[number]) => {
                    setOrganizationID(option.value);
                }}
            />
            <FontAwesomeIcon
                icon={faArrowRightArrowLeft}
                opacity={0.8}
                size="2x"
            />
            <Select
                showSearch
                filterOption={filterSelect}
                options={nonLinkedCompanies}
                placeholder="Alege societatea"
                size="large"
                style={{width: '100%'}}
                value={companyID}
                onChange={(value, option: typeof nonLinkedCompanies[number]) => {
                    setCompanyID(option.value);
                }}
            />
            <Button
                className={classes['OrganizationCifLink__addButton']}
                disabled={!companyID || !organizationID}
                style={{marginTop: '8px', width: '100%'}}
                variant="secondary"
                onClick={async () => {
                    if (loading) {
                        return;
                    }

                    try {
                        setLoading(true);

                        const Update = computeLinkZohoOrganizationToCompany({
                            OrganizationID: organizationID,
                            Company: Companies?.[companyID],
                        });

                        await RTDB.write(Update);

                        setOrganizationID(undefined);
                        setCompanyID(undefined);
                    }
                    catch {}
                    setLoading(false);
                }}
            >{loading ? <Spin size="small" /> : 'Asociază organizația!'}</Button>
        </div>
    );
}

export default function OrganizationsSettings() {
    const userID = useSelector(state => state.user?.ID);
    const OrganizationIDs = useRTDBSubscription([...P_Zoho_Users, userID, 'Organizations']);
    const AvailableCompanies = useRTDBSubscription([...P_RBAC_Dynamic_ByUser, userID, 'CompanyAccess']);

    const ExistingLinks = useSelector(() => RTDB.store.selectPath([...P_Zoho_AnafLink_ByOrganization]));

    const organizationNotLinked = (
        [
            ...Object.keys(OrganizationIDs?.Invoice || {}),
            ...Object.keys(OrganizationIDs?.Books || {}),
        ].filter(organizationID => !ExistingLinks?.[organizationID])
    );

    const companiesNotLinked = (
        Object.keys(AvailableCompanies || {})
            .filter(companyID => AvailableCompanies[companyID]?.ANAFSubmit)
            .filter(companyID => !Object.values(ExistingLinks || {}).map(e => e + '').includes(companyID))
    );

    return (
        <>
            {[
                ...Object.keys(OrganizationIDs?.Invoice || {}),
                ...Object.keys(OrganizationIDs?.Books || {}),
            ].map(OrganizationID => (
                <Fragment key={OrganizationID}>
                    <WatchPath path={[...P_Zoho_AnafLink_ByOrganization, OrganizationID]} />
                    <WatchPath path={[...P_Zoho_Organizations, OrganizationID, 'Name']} />
                </Fragment>
            ))}
            {Object.keys(AvailableCompanies || {}).map(CompanyID => (
                <WatchPath key={CompanyID} path={[...P_ANAF_Companies, CompanyID]} />
            ))}
            <Typography variant="h6" weight="medium">Organizații Zoho</Typography>
            <Typography color="secondary" variant="pMedium">Aici poți asocia o societate cu o organizație Zoho. Pentru a obține acces la alte societăți trebuie să <Link to="/setari-cont/anaf-auth">autorizezi accesul</Link> la ANAF SPV.</Typography>
            {Object.entries(ExistingLinks || {}).some(([OrganizationID, CompanyID]) => OrganizationID && CompanyID) ? (
                <>
                    <br />
                    <Typography style={{paddingBottom: '4px'}} variant="pSmall" weight="medium">Asocieri active</Typography>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        {Object.entries(ExistingLinks || {}).filter(([OrganizationID, CompanyID]) => OrganizationID && CompanyID).map(([OrganizationID, CompanyID]) => (
                            <OrganizationCifLink
                                key={OrganizationID}
                                CompanyID={CompanyID}
                                OrganizationID={OrganizationID}
                            />
                        ))}
                    </div>
                </>
            ) : null}
            {organizationNotLinked.length > 0 ? (
                <>
                    <br />
                    <Typography style={{paddingBottom: '4px'}} variant="pSmall" weight="medium">Adaugă o nouă asociere</Typography>
                    <NewOrganizationCifLink />
                </>
            ) : null}
        </>
    );
}
