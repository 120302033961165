import qs from 'query-string';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {ZOHO_CLIENT_ID, devMode} from 'config';

import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import {isFirefox} from '../../../utils/BrowserTools';

export default function LoginForm() {
    const {t} = useTranslation('auth');

    const windowRef = useRef<Window>();

    const onSignIn = useCallback(() => {
        console.log({
            url: 'https://accounts.zoho.eu/oauth/v2/auth',
            query: {
                scope: [
                    'ZohoInvoice.settings.READ',
                    'ZohoInvoice.contacts.READ',
                    'ZohoInvoice.invoices.READ',
                    'ZohoInvoice.creditnotes.READ',
                    'ZohoInvoice.expenses.Create',
                    'ZohoInvoice.expenses.UPDATE',
                    'ZohoInvoice.expenses.READ',
                    'ZohoBooks.settings.READ',
                    'ZohoBooks.invoices.READ',
                ].join(','),
                client_id: ZOHO_CLIENT_ID,
                response_type: 'code',
                redirect_uri: (devMode ? 'http://localhost:5000' : 'https://app.zfactura.ro') + '/auth/zoho/callback',
                access_type: 'offline',
                // prompt: 'consent',
            },
        });

        const url = qs.stringifyUrl({
            url: 'https://accounts.zoho.eu/oauth/v2/auth',
            query: {
                scope: [
                    'ZohoInvoice.settings.READ',
                    'ZohoInvoice.contacts.READ',
                    'ZohoInvoice.invoices.READ',
                    'ZohoInvoice.creditnotes.READ',
                    'ZohoInvoice.expenses.Create',
                    'ZohoInvoice.expenses.UPDATE',
                    'ZohoInvoice.expenses.READ',
                    'ZohoBooks.settings.READ',
                    'ZohoBooks.invoices.READ',
                ].join(','),
                client_id: ZOHO_CLIENT_ID,
                response_type: 'code',
                redirect_uri: (devMode ? 'http://localhost:5000' : 'https://app.zfactura.ro') + '/auth/zoho/callback',
                access_type: 'offline',
                // prompt: 'consent',
            },
        });

        const popupWidth = 500;
        const popupHeight = 600;

        const options: {[key: string]: string} = {
            location: 'yes',
            resizable: 'yes',
            statusbar: 'yes',
            toolbar: 'no',
            width: popupWidth.toString(),
            height: popupHeight.toString(),
            top: Math.max((window.screen.availHeight - popupHeight) / 2, 0).toString(),
            left: Math.max((window.screen.availWidth - popupWidth) / 2, 0).toString(),
        };

        if (isFirefox()) {
            options.scrollbars = 'yes';
        }

        const optionsString = Object.entries(options).map(([key, value]) => `${key}=${value}`).join(',');

        windowRef.current = window.open(url || '', '', optionsString);
        try {
            windowRef.current.focus();
        }
        catch {}
    }, []);

    useEffect(() => {
        return () => {
            try {
                windowRef.current?.close?.();
            }
            catch {}
        };
    });

    return (
        <>
            <Typography color="primary" style={{paddingTop: '8px'}} variant="h6">
                Folosește contul tău Zoho și integrează eFactura fără bătăi de cap!
            </Typography>
            <Typography color="secondary" style={{padding: '8px 0 24px'}} variant="pXSmall">
                Zoho este o marcă înregistrată a ZOHO Corporation. Serviciul zFactura nu este afiliat, susținut sau sponsorizat de către ZOHO Corporation sau oricare dintre subsidiarele sale.
            </Typography>
            <Button
                style={{width: '100%'}}
                variant="primary"
                onClick={onSignIn}
            >
                {t('signInWith')} Zoho
            </Button>
        </>
    );
}
