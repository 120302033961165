import {atLeastTwoDecimals, calculateMathExpression} from '@vantix/functions/isomorphic/math';
import {calculatePriceWithVAT} from '@vantix/functions/isomorphic/products';
import {
    P_StockReception_Computed_LastAcquisitionPrice,
    P_StockReception_Source_Shop,
    T_DB_LastAcquisitionPrice,
    T_DB_ReceptionItem,
    T_DB_ShopProduct,
} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {MutableRefObject, memo, useCallback, useEffect} from 'react';
import {DeepPartial, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {useProductData} from '@/utils/indexeddb';
import {useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

import {FormInputs} from '../../common';

function PopulateReceptionFields({
    ShopID,
    ReceptionID,
    ItemID,
    focusedFieldNameRef,
}: {
    ShopID: string,
    ReceptionID: string,
    ItemID: string,
    focusedFieldNameRef: MutableRefObject<string>,
}) {
    const {formState: {defaultValues}, resetField, getValues} = useFormContext<FormInputs>();

    const loadDefaults = useCallback(({Item, defaultValues, Product, LastAcquisitionPrice}: {
        Item: T_DB_ReceptionItem,
        defaultValues: DeepPartial<FormInputs['Items'][string]>,
        Product?: T_DB_ShopProduct,
        LastAcquisitionPrice?: T_DB_LastAcquisitionPrice,
    }) => {
        if (Item?.ID !== ItemID) {
            return;
        }
        const FormItem = getValues()?.Items?.[ItemID];

        const VATRateSale = Item.VATRateSale ?? Product?.VATRate;
        const ReceivedAmountExpression = Item.ReceivedAmountExpression;
        const ReceivedAmount = calculateMathExpression(ReceivedAmountExpression).toString();
        const InvoicedAmountExpression = Item.InvoicedAmountExpression ?? ReceivedAmount;
        const InvoicedAmount = calculateMathExpression(InvoicedAmountExpression).toString();

        let UnitAcquisitionPrice = '';
        let AcquisitionTotalPrice = '';

        if (Item.AcquisitionTotalPrice) {
            AcquisitionTotalPrice = Item.AcquisitionTotalPrice;

            const temp = BigNumber(AcquisitionTotalPrice).dividedBy(BigNumber(InvoicedAmount).abs()).decimalPlaces(6);
            if (!temp.isNaN() && temp.isFinite()) {
                UnitAcquisitionPrice = atLeastTwoDecimals(temp);
            }
        }
        else {
            UnitAcquisitionPrice = Item.UnitAcquisitionPrice ?? LastAcquisitionPrice?.Price;

            const temp = BigNumber(UnitAcquisitionPrice).multipliedBy(BigNumber(InvoicedAmount).abs()).decimalPlaces(2);
            if (!temp.isNaN() && temp.isFinite()) {
                AcquisitionTotalPrice = temp.toFixed(2);
            }
        }

        const newDefaults = {
            ID: Item.ID,

            ProductCode: Product?.Code?.Value ?? Item.ProductCode,
            ProductID: Product?.ID ?? Item.ProductID,
            ProductUnit: Product?.Unit,
            ProductUnitDecimals: Product?.UnitDecimals,
            RequiresPurchaseOf: Product?.RequiresPurchaseOf ? JSON.stringify(Product?.RequiresPurchaseOf) : undefined,

            IsDiscount: Item.IsDiscount,
            Name: Item.Name,

            VATRateSale,
            VATRateAcquisition: Item.VATRateAcquisition ?? VATRateSale,

            UnitAcquisitionPrice,
            AcquisitionTotalPrice,
            UnitSalePrice: Item.UnitSalePrice ?? (Product?.ID ? calculatePriceWithVAT(Product.Price, VATRateSale).toFixed(2) : ''),

            InvoicedAmountExpression,
            ReceivedAmountExpression,
        };

        for (const key of Object.keys(newDefaults) as (keyof typeof newDefaults)[]) {
            const rtdbValue = newDefaults[key];

            if (rtdbValue === undefined) {
                continue;
            }
            if (rtdbValue !== defaultValues?.[key] && focusedFieldNameRef.current !== key) {
                resetField(`Items.${ItemID}.${key}`, {
                    defaultValue: rtdbValue,
                    keepError: true,
                });
            }
        }

        if (!FormItem?.Loaded) {
            resetField(`Items.${ItemID}.Loaded`, {
                defaultValue: true,
            });
        }
    }, [ItemID]);

    const hasPendingUpdates = useSelector(state => Object.keys(state.sync.Update || {}).length > 0);

    const RTDBItem = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items', ItemID]);
    const Product = useProductData({ProductID: RTDBItem?.ProductID, ProductCode: RTDBItem?.ProductCode});
    const LastAcquisitionPrice = useRTDBSubscription([...P_StockReception_Computed_LastAcquisitionPrice, ShopID, Product?.ID]);

    const loadedData = useRTDBLoaded([
        [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items', ItemID],
        [...P_StockReception_Computed_LastAcquisitionPrice, ShopID, Product?.ID],
    ]);

    useEffect(() => {
        if (!loadedData || hasPendingUpdates) {
            return;
        }

        loadDefaults({
            Item: RTDBItem,
            Product,
            defaultValues: defaultValues?.Items?.[ItemID],
            LastAcquisitionPrice,
        });
    }, [RTDBItem, Product, LastAcquisitionPrice, defaultValues?.Items?.[ItemID], hasPendingUpdates, loadedData]);

    return null;
}

export default memo(PopulateReceptionFields);
