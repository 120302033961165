import {dequal} from 'dequal';
import {MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MRT_TableState} from 'material-react-table';
import {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';

interface LocationState {
    columnFilters?: MRT_ColumnFiltersState,
    sorting?: MRT_SortingState,
    pagination?: MRT_PaginationState,
}

export function useRouteSavedTablePreferences<TData>({defaults}: {
    defaults?: LocationState,
}): {
    state: Partial<MRT_TableState<TData>>,
} {
    const navigate = useNavigate();
    const locationState = useLocation()?.state as LocationState;

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(locationState?.columnFilters || defaults?.columnFilters);
    const [sorting, setSorting] = useState<MRT_SortingState>(locationState?.sorting || defaults?.sorting);
    const [pagination, setPagination] = useState<MRT_PaginationState>(locationState?.pagination || defaults?.pagination);

    useEffect(() => {
        const newLocationState = {
            columnFilters,
            sorting,
            pagination,
        };

        if (dequal(newLocationState, locationState)) {
            return;
        }

        navigate('./', {
            replace: true,
            state: newLocationState,
        });
    }, [columnFilters, sorting, pagination]);

    return useMemo(() => ({
        state: {
            columnFilters,
            sorting,
            pagination,
        },
        onColumnFiltersChange: (updater) => {
            setColumnFilters(typeof updater === 'function' ? updater(columnFilters) : updater);
        },
        onSortingChange: (updater) => {
            setSorting(typeof updater === 'function' ? updater(sorting) : updater);
        },
        onPaginationChange: (updater) => {
            setPagination(typeof updater === 'function' ? updater(pagination) : updater);
        },
    }), [columnFilters, sorting, pagination]);
}
