import {P_StockReception_Source_Shop, T_DB_ReceptionItem} from '@vantix/rtdb-rules/default';
import {MutableRefObject, memo, useEffect} from 'react';
import {useWatch} from 'react-hook-form';

import {useProductData} from '@/utils/indexeddb';
import {addToUpdateObject, useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import {FormInputs} from '../../common';

function SyncReceptionItem({
    ShopID,
    ReceptionID,
    ItemID,
    lastUpdatedInputsRef,
}: {
    ShopID: string,
    ReceptionID: string,
    ItemID: string,
    lastUpdatedInputsRef: MutableRefObject<string[]>,
}) {
    const formItem = useWatch({name: `Items.${ItemID}`}) as FormInputs['Items'][string];
    const formLoaded = formItem?.Loaded; // formItem is mutable so this is essential to "freeze" the value

    const dbItem = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items', ItemID]);
    const Product = useProductData({ProductID: formItem?.ProductID, ProductCode: formItem?.ProductCode});

    useEffect(() => {
        if (!ItemID || !formLoaded || Product === undefined) {
            return;
        }

        const Update = {};

        const basePath = [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID] as const;
        const itemBasePath = [...basePath, 'Items', ItemID] as const;

        const newDBItem: Partial<T_DB_ReceptionItem> = {
            ID: ItemID,
            IsDiscount: formItem.IsDiscount || null,
            VATRateSale: formItem.VATRateSale || null,
            Remarks: formItem.Remarks || null,
        };

        newDBItem.InvoicedAmountExpression = formItem.InvoicedAmountExpression ?? null;
        newDBItem.ReceivedAmountExpression = (formItem.IsDiscount ? formItem.InvoicedAmountExpression : formItem.ReceivedAmountExpression) ?? null;

        if (lastUpdatedInputsRef.current.indexOf('AcquisitionTotalPrice') > lastUpdatedInputsRef.current.indexOf('UnitAcquisitionPrice')) {
            newDBItem.UnitAcquisitionPrice = formItem.UnitAcquisitionPrice ?? null;
            if (dbItem?.AcquisitionTotalPrice) {
                newDBItem.AcquisitionTotalPrice = null;
            }
        }
        else {
            if (dbItem?.UnitAcquisitionPrice) {
                newDBItem.UnitAcquisitionPrice = null;
            }
            newDBItem.AcquisitionTotalPrice = formItem.AcquisitionTotalPrice ?? null;
        }

        if (formItem.IsDiscount) {
            newDBItem.UnitAcquisitionPrice = formItem.UnitAcquisitionPrice ?? null;
            newDBItem.Name = formItem.Name ?? null;
        }
        else {
            newDBItem.UnitSalePrice = formItem.UnitSalePrice ?? null;

            if (Product?.ID) {
                newDBItem.ProductCode = null;
                newDBItem.ProductID = Product.ID;
            }
            else if (formItem.ProductCode) {
                newDBItem.ProductCode = formItem.ProductCode;
                newDBItem.ProductID = null;
            }
        }

        for (const [key, value] of Object.entries(newDBItem)) {
            if (
                value !== undefined
                && !(
                    value === dbItem?.[key]
                    || (value === null && dbItem?.[key] === undefined)
                )
            ) {
                addToUpdateObject(Update, [...itemBasePath, key], value || null);
            }
        }

        store.dispatch.sync.merge(Update);
    }, [ItemID, JSON.stringify(formItem), formLoaded, Product]);

    return null;
}

export default memo(SyncReceptionItem);
