import {Modal} from 'antd';

import Typography from '@/components/Typography';

import {ProductEditor, ProductEditorProps} from '../../../ProductEditor';

export default function EditOrCreateProductModal({
    ProductID,
    ShopID,
    defaultValues,
    images,
    onUpdate,
    create,
}: {
    ProductID?: string,
    ShopID: string,
    defaultValues?: ProductEditorProps['defaultValues'],
    images?: ProductEditorProps['images'],
    onUpdate: (newState) => void,
    create?: boolean,
}) {
    return (
        <Modal
            destroyOnClose
            footer={null}
            open={!!ProductID}
            width={600}
            onCancel={() => onUpdate(undefined)}
            onClose={() => onUpdate(undefined)}
        >
            <Typography variant="h6">
                Editare produs
            </Typography>

            {create ? (
                <ProductEditor
                    ProductID={ProductID}
                    ShopID={ShopID}
                    defaultValues={defaultValues}
                    images={images}
                    onSaved={() => onUpdate(undefined)}
                />
            ) : (
                <ProductEditor
                    ProductID={ProductID}
                    ShopID={ShopID}
                    onSaved={() => onUpdate(undefined)}
                />
            )}
        </Modal>
    );
}
