import {callable} from '@agmedia/firebase-browser-helpers';
import {validEmail} from '@vantix/functions/isomorphic/DataValidation';
import {T_MINUTE, sortBy} from '@vantix/functions/isomorphic/generics';
import {P_Users_Emails, P_Users_Info} from '@vantix/rtdb-rules/default';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faCrown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Spin} from 'antd';
import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {RTDB, useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import {Tooltip, TooltipContent, TooltipTrigger} from '../../../components/Tooltip';
import Typography from '../../../components/Typography';
import {currentUserID} from '../../../utils/Auth';

import classes from './index.module.scss';

function VerifiedEmail({emailB64}: {emailB64: string}) {
    const PrimaryEmail = useSelector(state => state.user?.Email);
    const email = useMemo(() => {
        try {
            return atob(emailB64);
        }
        catch {}

        return '';
    }, [emailB64]);

    return (
        <div className={classes['SettingsEmailForm']}>
            <div className={classes['SettingsEmailForm__content']}>
                <Input
                    disabled
                    readOnly
                    className={classes['SettingsEmailForm__input']}
                    value={email}
                />
                <div className={classes['SettingsEmailForm__buttons']}>
                    {email !== PrimaryEmail ? (
                        <>
                            <Button
                                variant="secondary"
                                onClick={async () => {
                                    try {
                                        await RTDB.set([...P_Users_Info, currentUserID(), 'Email'], email);
                                    }
                                    catch {}
                                }}
                            >Setează ca email principal</Button>
                            <Tooltip>
                                <TooltipTrigger>
                                    <Button
                                        special="red"
                                        variant="secondary"
                                        onClick={async () => {
                                            try {
                                                await RTDB.set([...P_Users_Emails, currentUserID(), 'Verified', emailB64], null);
                                            }
                                            catch {}
                                        }}
                                    ><FontAwesomeIcon icon={faTrash} style={{fontSize: '16px'}} /></Button>
                                </TooltipTrigger>
                                <TooltipContent size="small"><Typography variant="pSmall">Șterge</Typography></TooltipContent>
                            </Tooltip>
                        </>
                    ) : (
                        <Typography color="secondary" style={{alignSelf: 'center'}}><FontAwesomeIcon icon={faCrown} />&nbsp; Email principal</Typography>
                    )}
                </div>
            </div>
        </div>
    );
}
function UnverifiedEmail({emailB64, updateTime, onSendVerification}: {emailB64: string; updateTime: number; onSendVerification: (email: string) => Promise<boolean>}) {
    const [loading, setLoading] = useState(false);
    const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
    const email = useMemo(() => {
        try {
            return atob(emailB64);
        }
        catch {}

        return '';
    }, [emailB64]);
    const sentRecently = (Date.now() - updateTime < T_MINUTE);

    return (
        <div className={classes['SettingsEmailForm']}>
            <div className={classes['SettingsEmailForm__content']}>
                <Input
                    disabled
                    readOnly
                    className={classes['SettingsEmailForm__input']}
                    value={email}
                />
                <div className={classes['SettingsEmailForm__buttons']}>
                    <Button
                        disabled={loading || sentVerificationEmail || sentRecently}
                        style={{width: '215px'}}
                        variant="outlined"
                        onClick={async () => {
                            if (loading || sentVerificationEmail || sentRecently) {
                                return;
                            }

                            setLoading(true);
                            if (await onSendVerification(email)) {
                                setSentVerificationEmail(true);
                            }
                            setLoading(false);
                        }}
                    >{loading ? <Spin size="small" /> : (sentVerificationEmail || sentRecently) ? 'Email de verificare trimis!' : 'Trimite email de verificare'}</Button>
                    <Tooltip>
                        <TooltipTrigger>
                            <Button
                                special="red"
                                variant="outlined"
                                onClick={async () => {
                                    try {
                                        await RTDB.set([...P_Users_Emails, currentUserID(), 'Unverified', emailB64], null);
                                    }
                                    catch {}
                                }}
                            ><FontAwesomeIcon icon={faTrash} style={{fontSize: '16px'}} /></Button>
                        </TooltipTrigger>
                        <TooltipContent size="small"><Typography variant="pSmall">Șterge</Typography></TooltipContent>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

function EmailForm({
    onSubmit: onPropsSubmit,
}: {
    onSubmit: (email: string) => Promise<void>,
}) {
    const [email, setEmail] = useState('');
    const [errorEnabled, setErrorEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (event) => {
        event?.preventDefault?.();
        setErrorEnabled(true);

        if (loading || !validEmail(email)) {
            return;
        }

        setLoading(true);
        await onPropsSubmit(email);
        setLoading(false);
    };

    return (
        <form className={classes['SettingsEmailForm']} onSubmit={onSubmit}>
            <div className={classes['SettingsEmailForm__content']}>
                <Input
                    className={classes['SettingsEmailForm__input']}
                    error={errorEnabled && !validEmail(email) ? 'Adresa de email nu este validă!' : undefined}
                    helper="O să-ți trimitem un link pentru a-ți confirma emailul."
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value.replaceAll(' ', ''))}
                />
                <div className={classes['SettingsEmailForm__buttons']}>
                    <Button
                        disabled={loading}
                        style={{width: '90px', height: '40px'}}
                        variant="secondary"
                        onClick={onSubmit}
                    >{loading ? <Spin size="small" /> : 'Adaugă'}</Button>
                </div>
            </div>
        </form>
    );
}

export default function EmailsSettings() {
    const userID = useSelector(state => state.user?.ID);

    const VerifiedEmails = useRTDBSubscription([...P_Users_Emails, userID, 'Verified']);
    const UnverifiedEmails = useRTDBSubscription([...P_Users_Emails, userID, 'Unverified']);

    const [error, setError] = useState('');

    const [newEmailKey, setNewEmailKey] = useState(Date.now());
    const onNewEmail = useCallback(async (email: string) => {
        setError('');

        try {
            await callable.europe_west1.sendEmailVerification({email, product: store.getState().generics.product});
            setNewEmailKey(Date.now());
        }
        catch (error) {
            console.log(error, error?.code, error?.message);

            setError(error?.message);
        }
    }, []);
    const onSendVerification = useCallback(async (email: string) => {
        setError('');

        try {
            await callable.europe_west1.sendEmailVerification({email, product: store.getState().generics.product});

            return true;
        }
        catch (error) {
            console.log(error, error?.code, error?.message);

            setError(error?.message);
        }

        return false;
    }, []);

    return (
        <>
            <Typography variant="h6" weight="medium">Adrese de email</Typography>
            <Typography color="secondary" variant="pMedium">Adresa de email principală este cea pe care vei primi notificări din partea noastră</Typography>
            {error ? (
                <Typography
                    color="error"
                    variant="pLarge"
                >{
                    error === 'server/email-verification/resend-too-soon'
                        ? 'Te rugăm aștepți un minut înainte de a adăuga altă adresă de email. Dacă îți verifici ultima adresă de email adăugată, acestă limitare va fi ridicată.'
                        : error
                }</Typography>
            ) : null}
            <div className={classes['SettingsEmails']}>
                {Object.entries(VerifiedEmails || {}).length > 0 ? (
                    <>
                        <Typography color="primary" style={{margin: '16px 0 -8px'}} variant="pSmall" weight="medium">Adrese de email verificate</Typography>
                        {Object.entries(VerifiedEmails || {}).sort(sortBy(([,value]) => [value, 'desc'])).map(([emailB64]) => (
                            <VerifiedEmail
                                key={emailB64}
                                emailB64={emailB64}
                            />
                        ))}
                    </>
                ) : null}
                {Object.entries(UnverifiedEmails || {}).length > 0 ? (
                    <>
                        <Typography color="primary" style={{margin: '16px 0 -8px'}} variant="pSmall" weight="medium">Adrese de email neverificate</Typography>
                        {Object.entries(UnverifiedEmails || {}).sort(sortBy(([,value]) => [value, 'desc'])).map(([emailB64, updateTime]) => (
                            <UnverifiedEmail
                                key={emailB64}
                                emailB64={emailB64}
                                updateTime={updateTime}
                                onSendVerification={onSendVerification}
                            />
                        ))}
                    </>
                ) : null}
                <Typography color="primary" style={{margin: '16px 0 -8px'}} variant="pSmall" weight="medium">Adaugă încă o adresă de email</Typography>
                <EmailForm
                    key={newEmailKey}
                    onSubmit={onNewEmail}
                />
            </div>
        </>
    );
}
