import {P_StockReception_Source_Shop, T_DB_StockReception} from '@vantix/rtdb-rules/default';
import {ReactElement, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

import {useRTDBSubscription} from 'project-rtdb';

import {FormInputs, OriginalValuesContext} from '../common';

function getDefaultValues({Reception}: {
    Reception: T_DB_StockReception,
}) {
    const defaultValues: Partial<FormInputs> = {
        SupplierID: Reception?.SupplierID || '',
        SupplierInvoiceNumber: Reception?.SupplierInvoiceNumber || '',
        SupplierInvoiceDate: Reception?.SupplierInvoiceDate || '',
        SupplierInvoiceDueDate: Reception?.SupplierInvoiceDueDate || '',
        SupplierInvoiceValueWithoutVAT: Reception?.SupplierInvoiceValueWithoutVAT || '',
        SupplierInvoiceValueVAT: Reception?.SupplierInvoiceValueVAT || '',

        Items: {},
    };

    return defaultValues;
}

export function PopulateFormDefaults({ShopID, ReceptionID, children}: {ShopID: string; ReceptionID: string; children: ReactElement}) {
    const Reception = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);

    const operatorReception = useMemo(() => {
        if (!Reception?.OperatorDataJSON) {
            return null;
        }

        try {
            const operatorReception = JSON.parse(Reception.OperatorDataJSON);

            return getDefaultValues({
                Reception: operatorReception,
            });
        }
        catch {}
    }, [Reception?.OperatorDataJSON]);


    const {formState: {defaultValues}, resetField} = useFormContext<FormInputs>();

    useEffect(() => {
        if (!Reception?.ID) {
            return;
        }

        const newDefaultValues = getDefaultValues({Reception});

        for (const key of Object.keys(newDefaultValues || {}) as (keyof typeof newDefaultValues)[]) {
            if (key === 'Items') {
                continue;
            }
            const rtdbValue = newDefaultValues[key];

            if (rtdbValue !== defaultValues?.[key]) {
                resetField(key, {
                    defaultValue: rtdbValue,
                });
            }
        }
    }, [defaultValues, Reception, operatorReception]);

    return (
        <OriginalValuesContext.Provider value={operatorReception as FormInputs}>
            {/* <form ref={wrapperRef} onChange={onChange} onSubmit={onSubmit}> */}
            {children}
            {/* </form> */}
        </OriginalValuesContext.Provider>
    );
}
