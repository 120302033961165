import React from 'react';

import {NIR, NIRData} from './documents/NIR';

export interface PDFProps<TData> {
    data: TData,
    t: TFunction,
}

export interface T_PDFDeclaration<TData> {
    PDF: React.ComponentType<PDFProps<TData>>,
}

export const PDFs = {
    NIR,
};

export type T_PDFs = typeof PDFs;

export interface T_PDFSdata {
    NIR: NIRData,
}
