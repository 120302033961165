import {ServerTimestamp} from '@agmedia/firebase-browser-helpers';
import {faMoneyBill} from '@fortawesome/pro-regular-svg-icons';
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {computeProductUpdate} from '@vantix/functions/isomorphic/compute/products';
import {formatEANCode, looksLikeEAN, sanitiseEAN} from '@vantix/functions/isomorphic/ean';
import {calculatePriceWithVAT} from '@vantix/functions/isomorphic/products';
import {P_ProductData_Compute_Trigger_Shop, P_ProductData_Source_Shop, T_DB_RemoteImage, T_DB_Unit} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {useCallback, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';

import {getProductDataViaCode} from '@/apps/checkout-station/views/CashierCheckout/Themes/Vistrum/Numpad';
import BlurHashImage from '@/components/BlurHashImage';
import Button from '@/components/Button';
import FormCheckbox from '@/components/FormCheckbox';
import FormSelect from '@/components/FormSelect';
import Input from '@/components/Input';
import Spinner from '@/components/Spinner';
import Typography from '@/components/Typography';
import {RTDB, addToUpdateObject, mergeUpdateObjects, useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

import classes from './index.module.scss';

const VAT_RATES = ['19', '9', '5', '0'].map(e => ({
    value: e,
    label: e + '%',
}));

interface FormInputs {
    Name: string,
    Code: string,
    Price: string,
    VATRate: string,

    Unit: T_DB_Unit,
    Enabled: boolean,
    Category: string,

    HasSGR: boolean,
    Fractional: boolean,
}

const SGR_PRODUCT_ID = '2l6yfqcAhM4PnkjhKyFUhY'; // todo: handle this better

export interface ProductEditorProps {
    ShopID: string,
    ProductID: string,
    defaultValues?: Partial<FormInputs>,
    images?: Record<string, T_DB_RemoteImage>,
    onSaved?: () => void,
}

export function ProductEditor({
    ShopID,
    ProductID,
    onSaved,
    defaultValues,
    images,
}: ProductEditorProps) {
    const {t} = useTranslation('product');

    const Product = useRTDBSubscription([...P_ProductData_Source_Shop, ShopID, 'Product', ProductID]);
    const ProductCategories = useRTDBSubscription([...P_ProductData_Source_Shop, ShopID, 'Category']);

    const unitsOptions = useMemo(() => (
        Object.keys({
            kg: '',
            l: '',
            m: '',
            pcs: '',
        } satisfies Record<T_DB_Unit, string>).map(e => ({
            value: e,
            label: t(`unit/${e}`),
        }))
    ), []);
    const categoriesOptions = useMemo(() => (
        Object.values(ProductCategories || {})
            .filter(Category => Category?.ID)
            .map(Category => ({
                value: Category.ID,
                label: Category.Name,
            }))
    ), [ProductCategories]);

    const form = useForm<FormInputs>({
        defaultValues: {
            Name: '',
            Code: '',
            Price: '',
            VATRate: '',
            Unit: 'pcs',
            Enabled: true,
            HasSGR: false,
            Fractional: false,
            Category: '',
            ...defaultValues,
        },
    });
    const {reset, register, handleSubmit, watch, setValue, setError, formState: {errors, isDirty, dirtyFields, isSubmitting}} = form;

    const productEnabled = watch('Enabled');
    const unit = watch('Unit');
    useEffect(() => {
        if (!dirtyFields?.Unit) {
            return;
        }

        setValue('Fractional', unit !== 'pcs');
    }, [unit === 'pcs', dirtyFields?.Unit]);

    const resetForm = useCallback(() => {
        if (!Product?.ID) {
            return;
        }

        reset({
            Name: Product.Name || '',
            Price: Product.Price ? calculatePriceWithVAT(Product.Price, Product.VATRate).toFixed(2) : '',
            VATRate: Product.VATRate,
            Unit: Product.Unit,
            Enabled: !Product.Disabled,
            HasSGR: BigNumber(Product.RequiresPurchaseOf?.[SGR_PRODUCT_ID]).isGreaterThan('0'),
            Fractional: Product.UnitDecimals === '3',
            Category: Product.Category,
            Code: formatEANCode(Product?.Code?.Value) || Product?.Code?.Value,
        });
    }, [Product]);
    useEffect(() => {
        if (Product?.ID && !isDirty) {
            resetForm();
        }
    }, [isDirty, Product]);

    const onSubmit = useCallback(async (data: FormInputs) => {
        const eanCode = sanitiseEAN(data.Code);
        const codeValue = eanCode || data.Code || '';

        const codeCollisionProduct = await getProductDataViaCode(codeValue);
        if (codeCollisionProduct?.ID && codeCollisionProduct?.ID !== ProductID) {
            setError('Code', {
                message: `Acest cod este deja folosit de "${codeCollisionProduct?.Name}"`,
            });
            return;
        }

        try {
            const Update = {};

            mergeUpdateObjects(Update, computeProductUpdate({
                ShopID,
                ProductID,
                CurrentProduct: Product,
                data: {
                    Name: data.Name,
                    Category: data.Category || null,
                    PriceWithVAT: data.Price,
                    VATRate: data.VATRate,
                    Unit: data.Unit,
                    Disabled: !data.Enabled,
                    UnitDecimals: data.Fractional ? '3' : '0',
                    RequiresPurchaseOf: {
                        [SGR_PRODUCT_ID]: data.HasSGR ? '1' : null,
                    },
                    Code: {
                        Type: eanCode.length === 8 ? 'EAN8' : eanCode.length === 13 ? 'EAN13' : 'CODE128',
                        Value: codeValue,
                    },
                },
            }));

            if (Object.keys(Update).length > 0) {
                addToUpdateObject(Update, [...P_ProductData_Compute_Trigger_Shop, ShopID], ServerTimestamp);
            }

            await RTDB.write(Update);

            resetForm();
            onSaved?.();
        }
        catch (error) {
            setError('root', {
                message: error.message || 'Eroare neașteptată',
            });
        }
    }, [ShopID, ProductID, Product, resetForm, onSaved]);

    return (
        <FormProvider {...form}>
            {Object.values(images || {}).length > 0 ? (
                <div style={{display: 'flex', height: '100px', gap: '8px'}}>
                    {Object.values(images || {}).filter(image => image?.URL).map((image, index) => (
                        <a
                            key={index}
                            href={image.URL}
                            rel="noreferrer"
                            style={{width: '100px', display: 'flex'}}
                            target="_blank"
                        >
                            <BlurHashImage
                                blur={image.BlurHash || 'vantix-erp'}
                                src={image.URL}
                                style={{flex: '1 1 100%'}}
                            />
                        </a>
                    ))}
                </div>
            ) : null}
            <form className={classes['ProductEditor__form']} onSubmit={(e) => e.preventDefault()}>
                <Input
                    errorProps={{
                        variant: 'pLarge',
                    }}
                    label="Nume produs"
                    labelSize="pMedium"
                    placeholder="Nume produs"
                    {...register('Name', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                    })}
                />
                <Input
                    error={errors?.Code?.message}
                    errorProps={{
                        variant: 'pLarge',
                    }}
                    label="Cod de bare"
                    labelSize="pMedium"
                    placeholder="5 942105 007124"
                    {...register('Code', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                        validate: (input) => {
                            if (looksLikeEAN(input) && !sanitiseEAN(input)) {
                                return 'Cod invalid';
                            }
                        },
                    })}
                />
                <div>
                    <Typography
                        color="secondary"
                        style={{marginBottom: '4px'}}
                        variant="pMedium"
                        weight="medium"
                    >Categorie</Typography>
                    <FormSelect
                        allowClear
                        showSearch
                        name="Category"
                        options={categoriesOptions}
                        placeholder="Categorie"
                        size="large"
                    />
                </div>
                <div className={classes['ProductEditor__priceLine']}>
                    <div>
                        <Typography
                            color="secondary"
                            style={{marginBottom: '4px'}}
                            variant="pMedium"
                            weight="medium"
                        >Preț vânzare (TVA inclus)</Typography>
                        <div className={classes['ProductEditor__unitInput']}>
                            <Input
                                autoComplete="off"
                                error={errors?.Price?.message}
                                errorProps={{
                                    variant: 'pLarge',
                                }}
                                icon={faMoneyBill}
                                placeholder="Preț vânzare"
                                {...register('Price', {
                                    required: {
                                        message: 'Câmp obligatoriu',
                                        value: true,
                                    },
                                    pattern: {
                                        message: 'Număr invalid',
                                        value: /^\s*\d*(\.\d+)?\s*$/,
                                    },
                                })}
                            />
                            <Typography
                                className={classes['ProductEditor__unitInput__unit']}
                                color="secondary"
                                variant="pMedium"
                            >RON</Typography>
                        </div>
                    </div>
                    <div>
                        <Typography
                            color="secondary"
                            style={{marginBottom: '4px'}}
                            variant="pMedium"
                            weight="medium"
                        >Cotă TVA</Typography>
                        <FormSelect
                            name="VATRate"
                            options={VAT_RATES}
                            placeholder="Cotă TVA"
                            rules={{
                                required: {
                                    message: 'Câmp obligatoriu',
                                    value: true,
                                },
                            }}
                            size="large"
                        />
                    </div>
                    <div style={{marginLeft: 'auto'}}>
                        <Typography
                            color="secondary"
                            style={{marginBottom: '4px'}}
                            variant="pMedium"
                            weight="medium"
                        >Unitate de măsură</Typography>
                        <FormSelect
                            name="Unit"
                            options={unitsOptions}
                            placeholder="UM"
                            size="large"
                        />
                    </div>
                </div>
                <div className={classes['ProductEditor__checkboxesGrid']}>
                    <FormCheckbox
                        label={
                            <Typography
                                color="primary"
                                variant="pMedium"
                            >
                                Disponibil pentru vânzare
                                {productEnabled ? (
                                    <Typography color="secondary">&nbsp;</Typography>
                                ) : (
                                    <Typography color="secondary">
                                        casa de marcat va afișa o eroare când acest produs va fi scanat
                                    </Typography>
                                )}
                            </Typography>
                        }
                        {...register('Enabled')}
                    />
                    <FormCheckbox
                        label={
                            <Typography
                                color="primary"
                                variant="pMedium"
                            >
                                RetuRO SGR
                                <Typography color="secondary">
                                    colectează garanția SGR pentru acest produs
                                </Typography>
                            </Typography>
                        }
                        {...register('HasSGR')}
                    />
                    <FormCheckbox
                        label={
                            <Typography
                                color="primary"
                                variant="pMedium"
                            >
                                Produs fracționabil
                            </Typography>
                        }
                        {...register('Fractional')}
                    />
                </div>
                {errors?.root?.message ? (
                    <Typography
                        color="error"
                        variant="pLarge"
                    >{errors.root.message}</Typography>
                ) : null}
                <div className={classes['ProductEditor__actions']}>
                    <Button
                        disabled={!isDirty || isSubmitting}
                        size="large"
                        type="button"
                        variant="primary"
                        onClick={async () => {
                            await (handleSubmit(onSubmit))();
                        }}
                    >{isSubmitting ? <Spinner size="small" /> : 'Salvează'}</Button>
                </div>
            </form>
        </FormProvider>
    );
}

export default function ProductEditorView() {
    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);
    const {ProductID} = useParams<{ProductID: string}>();
    const [queryParams] = useSearchParams();
    const isNew = (queryParams.get('new') || '').length > 0;

    const Product = useRTDBSubscription([...P_ProductData_Source_Shop, ShopID, 'Product', ProductID]);
    const ProductLoaded = useRTDBLoaded([...P_ProductData_Source_Shop, ShopID, 'Product', ProductID]);

    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!Product?.ID && ProductLoaded && !isNew) {
            navigate('../');
        }
    }, [Product, ProductLoaded, ProductID, isNew]);

    return (
        <div className={classes['ProductEditor']}>
            <div style={{display: 'flex', gap: '8px'}}>
                <Button size="small" variant="outlined" onClick={() => navigate(location?.key ? -1 : '../../')}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <Typography variant="h4">{Product?.ID ? 'Editare' : 'Adăugare'} produs</Typography>
            </div>
            <ProductEditor
                ProductID={ProductID}
                ShopID={ShopID}
                onSaved={() => navigate('../')}
            />
        </div>
    );
}
