import {validNegativeNumber, validNumber, validPositiveNumber} from '@vantix/functions/isomorphic/DataValidation';
import {calculateMathExpression} from '@vantix/functions/isomorphic/math';
import {T_DB_ReceptionItem} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import {memo, useCallback, useContext, useMemo} from 'react';
import {ValidateResult, useFormContext, useWatch} from 'react-hook-form';
import {useDeepCompareMemo} from 'use-deep-compare';

import Input, {InputProps} from '@/components/Input';

import {FormInputs, LastUpdatedInputsContext, OriginalValuesContext} from '../common';

import classes from './index.module.scss';

const validations: Partial<Record<Exclude<keyof T_DB_ReceptionItem, 'Images'>, (ItemID: string, value: string, formValues: FormInputs) => ValidateResult>> = {
    InvoicedAmountExpression: (ItemID, value, formValues) => {
        const calculatedInvoicedAmount = calculateMathExpression(value, 4);

        if (!validNumber(calculatedInvoicedAmount.toString()) || calculatedInvoicedAmount.toString() === '0') {
            return 'Cantitate invalidă';
        }
        if (calculatedInvoicedAmount.toString() !== calculatedInvoicedAmount.decimalPlaces(BigNumber(formValues?.Items?.[ItemID]?.ProductUnitDecimals ?? '3').toNumber()).toString()) {
            return 'Prea multe zecimale';
        }
    },
    ReceivedAmountExpression: (ItemID, value) => {
        const calculatedReceivedAmount = calculateMathExpression(value, 4);

        if (!validNumber(calculatedReceivedAmount.toString())) {
            return 'Cantitate invalidă';
        }
    },
    UnitAcquisitionPrice: (ItemID, value, formValues) => {
        const formItem = formValues?.Items?.[ItemID];

        if (formItem?.IsDiscount) {
            if (!validNegativeNumber(value)) {
                return 'Valoarea trebuie să fie negativă';
            }
        }
        else {
            if (!validPositiveNumber(value)) {
                return 'Preț invalid';
            }
        }

        return true;
    },
    AcquisitionTotalPrice: (ItemID, value) => {
        if (!validPositiveNumber(value) || BigNumber(value).isEqualTo('0')) {
            return 'Preț invalid';
        }

        return true;
    },
    UnitSalePrice: (ItemID, value) => {
        if (!validPositiveNumber(value) || BigNumber(value).toString() === '0') {
            return 'Preț invalid';
        }

        return true;
    },
};

export type EditableCellProps<TFieldName extends Exclude<keyof T_DB_ReceptionItem, 'Images'>> = Omit<InputProps, 'onFocus'> & {
    ItemID: string,
    FieldName: TFieldName,
    onFocus?: (fieldName: TFieldName) => void,
    color?: 'warning',
};

function EditableCell<TFieldName extends Exclude<keyof T_DB_ReceptionItem, 'Images'>>({ItemID, FieldName, color, disabled, onFocus, ...props}: EditableCellProps<TFieldName>) {
    const formName = `Items.${ItemID}.${FieldName}` as const;

    const {onChange: lastUpdatedInputsOnChange} = useContext(LastUpdatedInputsContext);

    const originalValueContext = useContext(OriginalValuesContext);
    const originalValue = originalValueContext?.Items?.[ItemID]?.[FieldName];

    const {register, trigger, formState: {errors}, getFieldState} = useFormContext<FormInputs>();
    const fieldError = errors?.Items?.[ItemID]?.[FieldName];
    const fieldValue = useWatch({name: formName});

    const isDirty = ItemID.endsWith('-autosgr') ? false : (fieldValue !== originalValue && (!!fieldValue || !!originalValue));
    const hasError = fieldError?.message?.length > 0;

    const dependentFields = useMemo(() => {
        const dependentFieldNames = {} as const;

        return (dependentFieldNames[FieldName] || []).map(e => `Items.${ItemID}.${e}`);
    }, [ItemID, FieldName]);

    const onChange = useCallback(() => {
        lastUpdatedInputsOnChange(FieldName);

        if (getFieldState(formName).invalid) {
            trigger([formName, ...dependentFields], {
                shouldFocus: false,
            });
        }
    }, [formName, dependentFields, lastUpdatedInputsOnChange]);
    const validate = useMemo(() => validations[FieldName]?.bind(undefined, ItemID), [ItemID, FieldName]);

    const boundOnFocus = useMemo(() => onFocus.bind(undefined, FieldName), [onFocus, FieldName]);

    const content = useDeepCompareMemo(() => (
        <Input
            {...props}
            {...register(formName, {
                required: {
                    message: 'Câmp obligatoriu',
                    value: true,
                },
                onChange,
                onBlur: props.onBlur,
                validate,
                deps: dependentFields,
            })}
            className={clsx(classes['EditableCell'], {
                [classes['EditableCell--dirty']]: isDirty,
                [classes[`EditableCell--${color}`]]: color,
                [classes['EditableCell--error']]: hasError,
                [classes['EditableCell--disabled']]: disabled,
            })}
            disabled={disabled}
            error={fieldError?.message}
            onFocus={boundOnFocus}
        />
    ), [formName, onChange, props.onBlur, boundOnFocus, dependentFields, fieldError?.message, disabled, color, isDirty, validate, props]);

    return content;
}

export default memo(EditableCell);
