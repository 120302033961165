export function isValidEAN(input: string) {
    if (input.length !== 13 && input.length !== 8) {
        return false;
    }

    const parsed = [...input].map(e => Number.parseInt(e));

    return parsed[input.length - 1] === checksum(parsed.slice(0, input.length - 1));
}


export function checksum(input: string | string[] | number[]) {
    let sum = 0;

    for (const [index, elem] of [...input].reverse().entries()) {
        const weight = (index + 1) % 2 === 0 ? 1 : 3;

        sum += Number.parseInt(elem as string) * weight;
    }

    return (10 - sum % 10) % 10;
}

export function looksLikeEAN(input: string | number) {
    const trimmedInput = ((typeof input === 'number' ? input.toString() : input) || '').replaceAll(' ', '');

    return (
        [...trimmedInput].filter(e => Number.isNaN(Number.parseInt(e))).length === 0
        && [7, 8, 12, 13].includes(trimmedInput.length)
    );
}

export function sanitiseEAN(eanCode: string | number) {
    if (looksLikeEAN(eanCode)) {
        const trimmedEan = (typeof eanCode === 'number' ? eanCode.toString() : eanCode || '').replaceAll(' ', '');

        if (trimmedEan.length === 7 || trimmedEan.length === 12) {
            return (trimmedEan + checksum([...trimmedEan]));
        }
        else if (isValidEAN(trimmedEan)) {
            return trimmedEan;
        }
    }

    return '';
}

export function formatEANCode(input: string | number) {
    const eanCode = typeof input === 'number' ? input.toString() : input;

    if (!isValidEAN(eanCode)) {
        return '';
    }

    if (eanCode.length === 8) {
        return `${eanCode.slice(0, 4)} ${eanCode.slice(4)}`;
    }
    else {
        return `${eanCode[0]} ${eanCode.slice(1, 7)} ${eanCode.slice(7)}`;
    }
}
