import {ServerIncrement, ServerTimestamp} from '@agmedia/firebase-browser-helpers';
import {faBadgeDollar, faChevronLeft, faCircleExclamation, faDownToLine, faPlus, faPrint} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {calculatePriceWithVAT, calculatePriceWithoutVAT, calculateUnitPercentGrossProfit} from '@vantix/functions/isomorphic/products';
import {calculateCompleteStockReceptionItem} from '@vantix/functions/isomorphic/stockReceptions';
import {randomUUID} from '@vantix/functions/isomorphic/uuid';
import {
    P_ProductData_Compute_Trigger_Shop,
    P_ProductData_Source_Shop,
    P_StockReception_Computed_LastAcquisitionPrice,
    P_StockReception_Source_Shop,
} from '@vantix/rtdb-rules/default';
import {notification} from 'antd';
import confirm from 'antd/es/modal/confirm';
import BigNumber from 'bignumber.js';
import {ref, runTransaction} from 'firebase/database';
import {Fragment, ReactNode, Suspense, lazy, memo, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {FormProvider, UseFormTrigger, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';

import {getProductDataViaID} from '@/apps/checkout-station/views/CashierCheckout/Themes/Vistrum/Numpad';
import Button from '@/components/Button';
import {PageLoader} from '@/components/PageLoader';
import Spinner from '@/components/Spinner';
import Typography from '@/components/Typography';
import {useProductData} from '@/utils/indexeddb';
import {WatchPath} from '@/utils/Routing';
import {RTDB, ResolveDBType, addToUpdateObject, useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

import {FormInputs, calculateFormTotals} from './common';
import InvoiceInfo from './InvoiceInfo';
import AddReceptionItemModal from './Modals/AddReceptionItem';
import {PopulateFormDefaults} from './PopulateFormDefaults';
import StockReceptionTable from './StockReceptionTable';
import SyncTableEdits from './SyncReception';

import classes from './index.module.scss';

function modalConfirm({content}: {content: (props: {onOk: () => void; onCancel: () => void}) => ReactNode}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const modal = confirm({
            footer: null,
            title: null,
            width: 600,
            icon: null,
            closable: true,
            maskClosable: true,
            content: content({
                onOk: () => {
                    modal.destroy();
                    resolve();
                },
                onCancel: () => {
                    modal.destroy();
                    reject(new Error('User cancelled'));
                },
            }),
            onCancel: () => {
                modal.destroy();
                reject(new Error('User cancelled'));
            },
            onClose: () => {
                modal.destroy();
                reject(new Error('User cancelled'));
            },
        });
    });
}

function ProductName({ProductID}: {ProductID: string}) {
    const ProductName = useProductData({ProductID})?.Name;

    return (
        <Typography>- {ProductName || ''}</Typography>
    );
}

const GenerateNIRPDF = lazy(() => import('./GenerateNIRPDF'));

function StockReceptionView({ShopID, ReceptionID, registeredItemIDs, trigger}: {
    ShopID: string,
    ReceptionID: string,
    registeredItemIDs: Record<string, boolean>,
    trigger: UseFormTrigger<FormInputs>,
}) {
    const navigate = useNavigate();

    const Reception = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);

    const [forceEdit, setForceEdit] = useState(false);

    const scrollRef = useRef<HTMLDivElement>();

    const hasPendingUpdates = useSelector(state => Object.keys(state.sync.Update || {}).length > 0);
    const [printReception, setPrintReception] = useState<false | 'print' | 'download'>(false);
    const [addReceptionItem, setAddReceptionItem] = useState(false);

    const [finishingReception, setFinishingReception] = useState(false);
    const onFinishReception = async () => {
        if (finishingReception) {
            return;
        }

        try {
            const isValid = await trigger(undefined, {
                shouldFocus: true,
            });

            if (!isValid) {
                return;
            }

            if (Object.values(Reception?.Items || {}).filter(Item => Item?.ID).length === 0) {
                const notificationID = randomUUID();
                notification.error({
                    key: notificationID,
                    message: <Typography style={{paddingLeft: '8px'}} variant="h6" weight="medium">Recepția nu conține niciun produs</Typography>,
                    icon: <FontAwesomeIcon icon={faCircleExclamation} size="xl" style={{color: 'red'}} />,
                    duration: 5,
                    closeIcon: null,
                    showProgress: true,
                    placement: 'topRight',
                    onClick: () => notification.destroy(notificationID),
                });
                return;
            }
            if (Object.values(Reception?.Items || {}).some(Item => !Item?.ProductID && !Item?.IsDiscount)) {
                const notificationID = randomUUID();
                notification.error({
                    key: notificationID,
                    message: <Typography style={{paddingLeft: '8px'}} variant="h6" weight="medium">Recepția conține produse necunoscute</Typography>,
                    icon: <FontAwesomeIcon icon={faCircleExclamation} size="xl" style={{color: 'red'}} />,
                    duration: 5,
                    closeIcon: null,
                    showProgress: true,
                    placement: 'topRight',
                    onClick: () => notification.destroy(notificationID),
                });
                return;
            }

            setFinishingReception(true);

            const completeItems = Object.fromEntries(Object.entries(Reception?.Items || {}).map(([key, Item]) => [key, calculateCompleteStockReceptionItem(Item)]));
            const hasAmountDifferences = (
                Object.values(Reception?.Items || {})
                    .filter(Item => !Item?.IsDiscount)
                    .map(Item => BigNumber(completeItems[Item.ID].InvoicedAmount).minus(completeItems[Item.ID].ReceivedAmount).abs().isEqualTo('0'))
                    .includes(false)
            );
            if (hasAmountDifferences) {
                await modalConfirm({
                    content: ({onOk, onCancel}) => (
                        <>
                            <Typography variant="h6" weight="medium">
                                Confirmă diferențe față de factură
                            </Typography>
                            <Typography style={{margin: '16px 0 8px'}} variant="pLarge">
                                Produsele următoare au cantitatea facturată diferită de cea recepționată:
                            </Typography>
                            {Object.values(Reception?.Items || {})
                                .filter(Item => !BigNumber(completeItems[Item.ID]?.InvoicedAmount).isEqualTo(completeItems[Item.ID]?.ReceivedAmount))
                                .map((Item, index) => (
                                    <Typography key={index} variant="pLarge"><ProductName ProductID={Item?.ProductID} /></Typography>
                                ))}

                            <Typography style={{marginTop: '8px'}} variant="h6">
                                Alături de Nota de intrare recepție (NIR) va fi generat și un <Typography display="inline" weight="bold">Proces Verbal de Diferente la Receptie</Typography>.
                            </Typography>

                            <br />
                            <div style={{display: 'flex', justifyContent: 'center', gap: '12px', margin: '0 auto'}}>
                                <Button
                                    size="large"
                                    variant="primary"
                                    onClick={onCancel}
                                >Anulează</Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    onClick={onOk}
                                >Continuă cu diferențe față de factură</Button>
                            </div>
                        </>
                    ),
                });
            }

            const formTotals = calculateFormTotals(Reception);
            if (!formTotals.totalDifference.isEqualTo('0')) {
                await modalConfirm({
                    content: ({onOk, onCancel}) => (
                        <>
                            <Typography variant="h6" weight="medium">
                                Valoarea recepției diferă de valoarea facturii
                            </Typography>
                            <Typography style={{marginTop: '8px'}} variant="h6">
                                V-ați asigurat că datele din formular corespund celor de pe factură?
                            </Typography>

                            <br />
                            <div style={{display: 'flex', justifyContent: 'center', gap: '12px', margin: '0 auto'}}>
                                <Button
                                    size="large"
                                    variant="primary"
                                    onClick={onCancel}
                                >Anulează</Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    onClick={onOk}
                                >Continuă cu diferențe față de valoarea facturii</Button>
                            </div>
                        </>
                    ),
                });
            }

            const productsWithLowGrossProfit = (await Promise.all(Object.values(Reception?.Items || {}).map(async Item => {
                if (Item?.IsDiscount) {
                    return;
                }

                const percentUnitGrossProfit = calculateUnitPercentGrossProfit({
                    UnitSalePrice: completeItems[Item.ID].UnitSalePriceWithVat,
                    VATRateAcquisition: completeItems[Item.ID].VATRateAcquisition,
                    UnitAcquisitionPrice: completeItems[Item.ID].UnitAcquisitionPrice,
                });

                if (percentUnitGrossProfit.isLessThan('0.01')) {
                    return {
                        Product: await getProductDataViaID(Item.ProductID),
                        percentUnitGrossProfit,
                    };
                }
                else {
                    return;
                }
            }))).filter(Boolean);
            if (productsWithLowGrossProfit.length > 0) {
                await modalConfirm({
                    content: ({onOk, onCancel}) => (
                        <>
                            <Typography style={{marginBottom: '16px'}} variant="h6" weight="medium">
                                Următoarele produse au adaos procentual scăzut:
                            </Typography>
                            {productsWithLowGrossProfit.map(({Product, percentUnitGrossProfit}, index) => {
                                return (
                                    <Typography
                                        key={index}
                                        style={{marginTop: '8px'}}
                                        variant="pLarge"
                                    >{index + 1}. {Product?.Name}: <Typography display="inline" variant="h6" weight="bold">{percentUnitGrossProfit.multipliedBy('100').toFixed(2)}%</Typography></Typography>
                                );
                            })}

                            <br />
                            <div style={{display: 'flex', justifyContent: 'center', gap: '12px', margin: '0 auto'}}>
                                <Button
                                    size="large"
                                    variant="primary"
                                    onClick={onCancel}
                                >Anulează</Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    onClick={onOk}
                                >Continuă cu adaos scăzut</Button>
                            </div>
                        </>
                    ),
                });
            }

            const productsWithPriceUpdates = (await (
                Promise.all(Object.values(Reception?.Items || {}).map(async (Item) => {
                    if (Item?.IsDiscount) {
                        return;
                    }
                    const Product = await getProductDataViaID(Item.ProductID);
                    const completeItem = completeItems[Item.ID];

                    if (
                        completeItem.VATRateSale !== Product.VATRate
                        || !BigNumber(completeItem.UnitSalePriceWithVat).isEqualTo(calculatePriceWithVAT(Product.Price, Product.VATRate).decimalPlaces(2))
                    ) {
                        return Product;
                    }
                }))
            )).filter(Boolean);
            if (productsWithPriceUpdates.length > 0) {
                await modalConfirm({
                    content: ({onOk, onCancel}) => (
                        <>
                            <Typography style={{marginBottom: '16px'}} variant="h6" weight="medium">
                                Închiderea acestei recepții va modifica următoarele produse:
                            </Typography>
                            {productsWithPriceUpdates.map((Product, index) => {
                                const Item = Object.values(Reception?.Items || {}).find(Item => Item?.ProductID === Product?.ID);

                                const currentSalePrice = calculatePriceWithVAT(Product?.Price, Product?.VATRate).decimalPlaces(2);
                                const completeItem = completeItems[Item.ID];

                                const vatRateChanged = completeItem?.VATRateSale !== Product?.VATRate;
                                const priceChanged = !BigNumber(completeItem?.UnitSalePriceWithVat).isEqualTo(currentSalePrice);

                                return (
                                    <Fragment key={index}>
                                        <Typography style={{marginTop: '8px'}} variant="pLarge" weight="medium">{index + 1}. {Product?.Name}:</Typography>
                                        {vatRateChanged ? <Typography style={{paddingLeft: '8px'}} variant="pLarge">- Cotă TVA: <span style={{textDecoration: 'line-through'}}>{Product?.VATRate}%</span> -&gt; {completeItem?.VATRateSale}%</Typography> : null}
                                        {priceChanged ? <Typography style={{paddingLeft: '8px'}} variant="pLarge">- Preț vânzare: <span style={{textDecoration: 'line-through'}}>{currentSalePrice.toFixed(2)}RON</span> -&gt; {completeItem?.UnitSalePriceWithVat}RON</Typography> : null}
                                    </Fragment>
                                );
                            })}

                            <br />
                            <div style={{display: 'flex', justifyContent: 'center', gap: '12px', margin: '0 auto'}}>
                                <Button
                                    size="large"
                                    variant="primary"
                                    onClick={onCancel}
                                >Anulează</Button>
                                <Button
                                    size="large"
                                    variant="outlined"
                                    onClick={onOk}
                                >Încheie recepția și modifică produsele</Button>
                            </div>
                        </>
                    ),
                });
            }

            try {
                let NIRNumber: number;

                if (!Reception?.NIRID) {
                    const NIRNumberRef = ref(RTDB.db, [...P_StockReception_Source_Shop, ShopID, 'LastUsedNIRNumber'].join('/'));

                    let committed: boolean;
                    try {
                        const result = await runTransaction(NIRNumberRef, (currentData: ResolveDBType<[...P_StockReception_Source_Shop, string, 'NIR']>) => {
                            return (currentData || 0) + 1;
                        });
                        NIRNumber = result.snapshot.val();
                        committed = result.committed;
                    }
                    catch {}

                    if (!committed || !(NIRNumber > 0)) {
                        throw new Error('Could not increment NIR number');
                    }
                }

                try {
                    const Update = {};

                    addToUpdateObject(Update, [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Status'], 'processed');
                    if (!Reception?.NIRID) {
                        addToUpdateObject(Update, [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'NIRID'], NIRNumber.toString());
                        addToUpdateObject(Update, [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'NIRDate'], ServerTimestamp);
                    }

                    const SGR_PRODUCT_ID = '2l6yfqcAhM4PnkjhKyFUhY';
                    let updatedProducts = false;
                    await Promise.all(Object.values(Reception?.Items || {}).map(async (Item) => {
                        if (Item?.IsDiscount || Item.ProductID === SGR_PRODUCT_ID) {
                            return;
                        }
                        const Product = await getProductDataViaID(Item.ProductID);
                        const completeItem = calculateCompleteStockReceptionItem(Item);

                        if (completeItem.VATRateSale !== Product.VATRate) {
                            addToUpdateObject(Update, [...P_ProductData_Source_Shop, ShopID, 'Product', Product.ID, 'VATRate'], completeItem.VATRateSale);
                            updatedProducts = true;
                        }
                        if (
                            completeItem.VATRateSale !== Product.VATRate
                            || !BigNumber(completeItem.UnitSalePriceWithVat).isEqualTo(calculatePriceWithVAT(Product.Price, Product.VATRate).decimalPlaces(2))
                        ) {
                            addToUpdateObject(Update, [...P_ProductData_Source_Shop, ShopID, 'Product', Product.ID, 'Price'], calculatePriceWithoutVAT(completeItem.UnitSalePriceWithVat, completeItem.VATRateSale).decimalPlaces(6).toString());
                            updatedProducts = true;
                        }

                        addToUpdateObject(Update, [...P_StockReception_Computed_LastAcquisitionPrice, ShopID, completeItem.ProductID], {
                            Modified: ServerTimestamp,
                            Price: completeItem.UnitAcquisitionPrice,
                        });
                    }));
                    if (updatedProducts) {
                        addToUpdateObject(Update, [...P_ProductData_Compute_Trigger_Shop, ShopID], ServerTimestamp);
                    }

                    await RTDB.write(Update);
                }
                catch (error) {
                    await RTDB.set([...P_StockReception_Source_Shop, ShopID, 'LastUsedNIRNumber'], ServerIncrement(-1));

                    throw error;
                }

                setFinishingReception(false);
            }
            catch (error) {
                const notificationID = randomUUID();
                notification.error({
                    key: notificationID,
                    message: <Typography style={{paddingLeft: '8px'}} variant="h6" weight="medium">{error.message || 'Eroare neașteptată'}</Typography>,
                    icon: <FontAwesomeIcon icon={faCircleExclamation} size="xl" style={{color: 'red'}} />,
                    duration: 5,
                    closeIcon: null,
                    showProgress: true,
                    placement: 'topRight',
                    onClick: () => notification.destroy(notificationID),
                });
                setFinishingReception(false);
            }
        }
        catch (error) {
            setFinishingReception(false);

            if (error.message === 'User cancelled') {
                return;
            }

            console.log(error);
        }
    };

    return (
        <PopulateFormDefaults
            ReceptionID={ReceptionID}
            ShopID={ShopID}
        ><div className={classes['StockReceptionEditorWrapper']}><div className={classes['StockReceptionEditor']}>
            {printReception ? (
                <div style={{display: 'none'}}>
                    <Suspense fallback={<Spinner size="small" />}>
                        <GenerateNIRPDF
                            ReceptionID={ReceptionID}
                            ShopID={ShopID}
                            mode={printReception}
                            onDone={() => setPrintReception(false)}
                        />
                    </Suspense>
                </div>
            ) : null}
            <div style={{maxWidth: '1024px', width: '100%', margin: '0 auto', padding: '0 12px'}}>
                <div style={{display: 'flex', gap: '8px'}}>
                    <Button size="small" variant="outlined" onClick={() => navigate('../')}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Typography variant="h4">Editare recepție</Typography>
                    {Reception?.Status === 'submitted' || forceEdit ? (
                        <SyncTableEdits ReceptionID={ReceptionID} ShopID={ShopID} />
                    ) : null}
                </div>

                <br />

                <InvoiceInfo ReceptionID={ReceptionID} ShopID={ShopID} />

                <div style={{display: 'flex', gap: '8px', margin: '16px 0'}}>
                    <div style={{marginLeft: 'auto'}} />
                    {Reception?.Status === 'submitted' ? (
                        <Button
                            disabled={hasPendingUpdates}
                            variant="primary"
                            onClick={onFinishReception}
                        >
                            {finishingReception ? <Spinner size="small" /> : 'Încheie recepția'}
                        </Button>
                    ) : (
                        Reception?.Status === 'processed' ? (
                            <Button disabled variant="primary">
                                Recepție închisă
                            </Button>
                        ) : (
                            null
                        )
                    )}
                    <Button disabled={hasPendingUpdates || !!printReception} variant="outlined" onClick={() => printReception ? undefined : setPrintReception('print')}>
                        {printReception === 'print' ? <Spinner size="small" /> : <FontAwesomeIcon icon={faPrint} size="lg" />}
                    </Button>
                    <Button disabled={hasPendingUpdates || !!printReception} variant="outlined" onClick={() => printReception ? undefined : setPrintReception('download')}>
                        {printReception === 'download' ? <Spinner size="small" /> : <FontAwesomeIcon icon={faDownToLine} size="lg" />}
                    </Button>
                    <Button
                        disabled={Reception?.Status !== 'submitted'}
                        variant="outlined"
                        onClick={() => setAddReceptionItem(true)}
                    >
                        Adaugă produs în recepție&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus} size="lg" />
                    </Button>
                    <Button
                        disabled={Reception?.Status !== 'submitted'}
                        variant="outlined"
                        onClick={async () => {
                            const basePath = [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items'] as const;
                            const newID = RTDB.newKey(basePath);
                            await RTDB.set([...basePath, newID], {
                                ID: newID,
                                IsDiscount: true,
                                Name: 'Discount',
                                InvoicedAmountExpression: '1',
                            });

                            requestAnimationFrame(() => {
                                window.scrollTo({
                                    top: window.document.body.scrollHeight,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                                scrollRef.current.scrollTo({
                                    top: scrollRef.current.scrollHeight,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            });
                        }}
                    >
                        Adaugă discount&nbsp;&nbsp;<FontAwesomeIcon icon={faBadgeDollar} size="lg" />
                    </Button>
                </div>
            </div>
            <StockReceptionTable
                ref={scrollRef}
                ReceptionID={ReceptionID}
                ShopID={ShopID}
                disabled={Reception?.Status !== 'submitted'}
                registeredItemIDs={registeredItemIDs}
            />
            <div style={{flex: '0 0 16px'}} />
            <AddReceptionItemModal
                ReceptionID={ReceptionID}
                ShopID={ShopID}
                open={addReceptionItem}
                onClose={(newItemID) => {
                    setAddReceptionItem(false);

                    if (newItemID) {
                        requestAnimationFrame(() => {
                            window.scrollTo({
                                top: window.document.body.scrollHeight,
                                left: 0,
                                behavior: 'smooth',
                            });
                            scrollRef.current.scrollTo({
                                top: scrollRef.current.scrollHeight,
                                left: 0,
                                behavior: 'smooth',
                            });
                        });
                    }
                }}
            />
        </div></div></PopulateFormDefaults>
    );
}
const StockReceptionViewMemo = memo(StockReceptionView);

export default function StockReceptionViewLoader({isNew}: {isNew?: boolean}) {
    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);
    const {ReceptionID} = useParams<{ReceptionID: string}>();

    const Reception = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);
    const ReceptionLoaded = useRTDBLoaded([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);
    const [formFieldsLoaded, setFormFieldsLoaded] = useState(false);
    const loaded = ShopID && ReceptionID && ReceptionLoaded && formFieldsLoaded;

    const navigate = useNavigate();

    useEffect(() => {
        if (isNew) {
            if (Reception?.ID) {
                navigate('./../', {replace: true});
            }
        }
        else {
            if (!Reception?.ID && loaded) {
                navigate('../');
            }
        }
    }, [Reception?.ID, loaded, isNew]);

    const form = useForm<FormInputs>({
        // disabled: forceEdit || isNew ? false : Reception?.Status !== 'submitted',
        mode: 'onBlur',
    });
    const {register, unregister, trigger, getValues} = form;
    const [registeredItemIDs, setRegisteredItemIDs] = useState<Record<string, true>>({});
    useLayoutEffect(() => {
        if (!Reception?.ID) {
            return;
        }

        register('SupplierID');
        register('SupplierInvoiceNumber');
        register('SupplierInvoiceDate');
        register('SupplierInvoiceDueDate');
        register('SupplierInvoiceValueWithoutVAT');
        register('SupplierInvoiceValueVAT');

        const ItemIDsToUnregister = Object.fromEntries(Object.keys(getValues()?.Items || {}).map(e => [e, true]));

        for (const Item of Object.values(Reception.Items || {})) {
            if (!Item?.ID) {
                continue;
            }

            ItemIDsToUnregister[Item.ID] = false;

            register(`Items.${Item.ID}.ID`);
            register(`Items.${Item.ID}.Loaded`);
            register(`Items.${Item.ID}.IsDiscount`);

            register(`Items.${Item.ID}.ProductID`);
            register(`Items.${Item.ID}.ProductCode`);
            register(`Items.${Item.ID}.ProductUnit`);
            register(`Items.${Item.ID}.ProductUnitDecimals`);
            register(`Items.${Item.ID}.RequiresPurchaseOf`);
            register(`Items.${Item.ID}.Name`);

            register(`Items.${Item.ID}.InvoicedAmountExpression`);
            register(`Items.${Item.ID}.ReceivedAmountExpression`);

            register(`Items.${Item.ID}.UnitAcquisitionPrice`);
            register(`Items.${Item.ID}.AcquisitionTotalPrice`);
            register(`Items.${Item.ID}.VATRateAcquisition`);
            register(`Items.${Item.ID}.AcquisitionTotalVAT`);
            register(`Items.${Item.ID}.UnitSalePrice`);
            register(`Items.${Item.ID}.VATRateSale`);
            register(`Items.${Item.ID}.Remarks`);

            register(`Items.${Item.ID}.InvoicedAmount`);
            register(`Items.${Item.ID}.ReceivedAmount`);
        }

        for (const [ItemID, shouldUnregister] of Object.entries(ItemIDsToUnregister)) {
            if (!shouldUnregister) {
                continue;
            }

            unregister(`Items.${ItemID}`);
        }

        setRegisteredItemIDs(oldRegistered => {
            const output = {...oldRegistered};

            for (const [ItemID, shouldUnregister] of Object.entries(ItemIDsToUnregister)) {
                if (shouldUnregister) {
                    delete output[ItemID];
                }
                else {
                    output[ItemID] = true;
                }
            }

            return output;
        });

        setFormFieldsLoaded(true);
    }, [register, Reception]);

    return (
        <>
            {[...new Set(Object.values(Reception?.Items || {}).map(Item => Item?.ProductID).filter(Boolean))].map(ProductID => (
                <WatchPath
                    key={ProductID}
                    path={[...P_StockReception_Computed_LastAcquisitionPrice, ShopID, ProductID]}
                />
            ))}
            {loaded ? (
                <FormProvider {...form}>
                    <StockReceptionViewMemo
                        ReceptionID={ReceptionID}
                        ShopID={ShopID}
                        isNew={isNew}
                        registeredItemIDs={registeredItemIDs}
                        trigger={trigger}
                    />
                </FormProvider>
            ) : (
                <PageLoader />
            )}
        </>
    );
}
