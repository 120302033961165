import {sortBy} from '@vantix/functions/isomorphic/generics';
import clsx from 'clsx';
import {ForwardedRef, ReactNode, forwardRef, memo, useCallback, useLayoutEffect, useMemo, useReducer, useRef, useState} from 'react';

import StockReceptionRow from '../StockReceptionRow';

import classes from './index.module.scss';

export interface StockReceptionTableProps {
    ShopID: string,
    ReceptionID: string,
    registeredItemIDs: Record<string, boolean>,
    disabled?: boolean,
}

function StockReceptionTable({ShopID, ReceptionID, registeredItemIDs, disabled}: StockReceptionTableProps, propRef: ForwardedRef<HTMLDivElement>) {
    const tableData = Object.keys(registeredItemIDs).sort(sortBy(ItemID => [ItemID, 'lexicoAsc']));

    const scrollState = useRef({
        stickyLeft: false,
        stickyRight: false,
    });
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const tableContainerRef = useRef<HTMLDivElement>();
    useLayoutEffect(() => {
        if (!tableContainerRef.current) {
            return;
        }

        const element = tableContainerRef.current;
        const onScroll = () => {
            if (!element) {
                return;
            }

            const nextStickyLeft = element.scrollLeft > 0;
            const nextStickyRight = element.scrollLeft + element.offsetWidth < element.scrollWidth;

            if (scrollState.current.stickyLeft !== nextStickyLeft) {
                scrollState.current.stickyLeft = nextStickyLeft;
                forceUpdate();
            }
            if (scrollState.current.stickyRight !== nextStickyRight) {
                scrollState.current.stickyRight = nextStickyRight;
                forceUpdate();
            }
        };

        window.addEventListener('resize', onScroll);
        element.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('resize', onScroll);
            element?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const tableRef = useRef<HTMLDivElement>();
    const [gridTemplateColumns, setGridTemplateColumns] = useState('80px 250px 150px 130px 130px 150px 150px 120px 130px 100px 100px 160px 130px auto');
    const [activeResizeHandle, setActiveResizeHandle] = useState<{
        index: number,
        startX: number,
        startColumns: string[],
        startColumnWidth: number,
    }>(undefined);
    const mouseMove = useCallback((event: MouseEvent) => {
        if (!tableRef.current || !activeResizeHandle) {
            return;
        }

        activeResizeHandle.startColumns[activeResizeHandle.index] = activeResizeHandle.startColumnWidth + Math.round(event.clientX - activeResizeHandle.startX) + 'px';

        tableRef.current.style.gridTemplateColumns = activeResizeHandle.startColumns.join(' ');
    }, [activeResizeHandle]);

    useLayoutEffect(() => {
        if (!activeResizeHandle) {
            return;
        }

        const onMouseUp = () => {
            if (tableRef.current) {
                setGridTemplateColumns(tableRef.current.style.gridTemplateColumns);
            }

            setActiveResizeHandle(undefined);
        };

        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    }, [activeResizeHandle, mouseMove]);


    const columns = useMemo<{
        text: ReactNode,
        align?: 'left' | 'center' | 'right',
    }[]>(() => [
        {
            text: 'Nr. Crt.',
            align: 'left',
        },
        {
            text: 'Nume produs',
        },
        {
            text: 'Cod',
        },
        {
            text: 'Cantitate Facturată',
        },
        {
            text: 'Cantitate Fizică',
        },
        {
            text: 'Preț Unitar Achiziție',
        },
        {
            text: 'Valoare Achiziție',
        },
        {
            text: 'TVA total Achiziție',
            align: 'right',
        },
        {
            text: 'Preț Vânzare (TVA inclus)',
        },
        {
            text: 'TVA',
            align: 'center',
        },
        {
            text: 'Adaos',
            align: 'right',
        },
        {
            text: 'Valoare Vânzare (fără TVA)',
            align: 'right',
        },
        {
            text: 'Valoare TVA Vânzare',
            align: 'right',
        },
    ], []);

    const renderColumn = useCallback((column: typeof columns[number], index: number) => (
        <div
            key={index}
            className={classes['StockReceptionTable__th']}
            style={{textAlign: column.align}}
        >
            {column.text}
            <div
                className={clsx(classes['StockReceptionTable__resizeHandle'], {
                    [classes['StockReceptionTable__resizeHandle--active']]: activeResizeHandle?.index === index,
                })}
                onMouseDown={(event) => setActiveResizeHandle({
                    index,
                    startX: event.clientX,
                    startColumns: gridTemplateColumns.split(' '),
                    startColumnWidth: Number.parseFloat(gridTemplateColumns.split(' ')[index]),
                })}
            >
                <div className={classes['StockReceptionTable__resizeHandle__inner']} />
            </div>
        </div>
    ), [activeResizeHandle, gridTemplateColumns]);
    const memoedColumns = useMemo(() => columns.map(renderColumn), [columns, renderColumn]); // eslint-disable-line unicorn/no-array-callback-reference

    return (
        <div
            ref={ref => {
                tableContainerRef.current = ref;

                if (typeof propRef === 'function') {
                    propRef(ref);
                }
                else if (propRef) {
                    propRef.current = ref;
                }
            }}
            className={classes['StockReceptionTableContainer']}
        >
            <div
                ref={tableRef}
                className={clsx(classes['StockReceptionTable'], {
                    [classes['StockReceptionTable--stickyLeft']]: scrollState.current.stickyLeft,
                    [classes['StockReceptionTable--stickyRight']]: scrollState.current.stickyRight,
                })}
                style={{
                    gridTemplateColumns,
                }}
            >
                <div className={classes['StockReceptionTable__headerRow']}>
                    <div
                        className={classes['StockReceptionTable__stickyLeftColumn']}
                        style={{overflow: scrollState.current.stickyLeft ? 'hidden' : undefined}}
                    >
                        {memoedColumns.slice(0, 2)}
                    </div>
                    {memoedColumns.slice(2)}
                    <div className={classes['StockReceptionTable__stickyRightColumn']}>
                        <div className={classes['StockReceptionTable__th']}>Acțiuni</div>
                    </div>
                </div>
                {tableData.map((ItemID, index) => (
                    <StockReceptionRow
                        key={ItemID}
                        ItemID={ItemID}
                        ReceptionID={ReceptionID}
                        ShopID={ShopID}
                        disabled={disabled}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
}

export default memo(forwardRef<HTMLDivElement, StockReceptionTableProps>(StockReceptionTable));
