import {ServerTimestamp, T_RTDBUpdate} from '@agmedia/firebase-browser-helpers';
import {faCloud, faRefresh, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {P_StockReception_Source_Shop} from '@vantix/rtdb-rules/default';
import {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useDeepCompareEffect} from 'use-deep-compare';

import {Tooltip, TooltipContent, TooltipTrigger} from '@/components/Tooltip';
import Typography from '@/components/Typography';
import {RTDB, addToUpdateObject, mergeUpdateObjects, useRTDBSubscription} from 'project-rtdb';
import store from 'project-store';

import classes from './index.module.scss';

export default function SyncTableEdits({ShopID, ReceptionID}: {ShopID: string; ReceptionID: string}) {
    const Reception = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);

    const [synced, setSynced] = useState<'synced' | 'syncing' | 'not-synced'>('not-synced');

    const debouncedWriteTimeout = useRef<ReturnType<typeof setTimeout>>();
    const writeSync = useCallback((Update: T_RTDBUpdate) => {
        clearTimeout(debouncedWriteTimeout.current);

        if (Object.keys(Update).length === 0) {
            setSynced('synced');
            return;
        }

        debouncedWriteTimeout.current = setTimeout(async () => {
            // console.log(Update);

            addToUpdateObject(Update, [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Modified'], ServerTimestamp);
            try {
                await RTDB.write(Update);

                store.dispatch.sync.updated(Update);
            }
            catch (error) {
                console.error(error);

                setSynced('not-synced');
                return;
            }
            setSynced('synced');
        }, 500);
    }, [ShopID, ReceptionID]);

    const reduxUpdate = useSelector(state => state.sync.Update);
    useDeepCompareEffect(() => {
        if (!Reception?.ID) {
            return;
        }

        const basePath = [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID] as const;
        const dbReception = RTDB.store.selectPath(basePath);

        if (!dbReception?.ID || dbReception.Status !== 'submitted') {
            return;
        }

        const Update = {};

        mergeUpdateObjects(Update, reduxUpdate);

        setSynced('syncing');

        if (Object.keys(Update).length > 0 && !dbReception.OperatorDataJSON) {
            addToUpdateObject(Update, [...basePath, 'OperatorDataJSON'], JSON.stringify(dbReception));
        }

        writeSync(Update);
    }, [reduxUpdate]);

    return (
        <Tooltip>
            <TooltipTrigger className={classes['SyncReception']}>
                <FontAwesomeIcon
                    icon={
                        synced === 'syncing'
                            ? faRefresh
                            : synced === 'synced'
                                ? faCloud
                                : faTimes
                    }
                />
            </TooltipTrigger>
            <TooltipContent>
                <Typography>{
                synced === 'syncing'
                    ? 'Se sincronizează datele'
                    : synced === 'synced'
                        ? 'Toate datele sunt sincronizate'
                        : 'Datele nu sunt sincronizate'
                }</Typography>
            </TooltipContent>
        </Tooltip>
    );
}
