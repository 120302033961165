import {faUserTie} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Card} from 'antd';
import React from 'react';
import {useSelector} from 'react-redux';

import CompaniesSettings from './Companies';
import EmailsSettings from './Emails';
import NameSettings from './Name';
import OrganizationsSettings from './Organizations';

import classes from './index.module.scss';

export default function AccountSettingsView() {
    const ProfileImage = useSelector(state => state.user?.Photo);

    return (
        <div className={classes['AccountSettings']}>
            <Card bordered={false} className={classes['AccountSettings__card']}>
                <div style={{textAlign: 'center', margin: '-86px 0 24px'}}>
                    <Avatar
                        icon={<FontAwesomeIcon icon={faUserTie} />}
                        size={120}
                        src={ProfileImage}
                        style={{border: '1px solid rgba(0, 0, 0, 0.25)', background: 'rgb(191, 191, 191)'}}
                    />
                </div>
                <NameSettings />
                <hr />
                <EmailsSettings />
            </Card>
            <Card bordered={false} className={classes['AccountSettings__card']}>
                <CompaniesSettings />
            </Card>
            <Card bordered={false} className={classes['AccountSettings__card']}>
                <OrganizationsSettings />
            </Card>
        </div>
    );
}
