export function loadFonts(Font: any, basepath: string): void {
    Font.register({
        family: 'Roboto',
        fonts: [
            {src: basepath + '/Roboto/Roboto-Black.ttf', fontWeight: 900},
            {src: basepath + '/Roboto/Roboto-BlackItalic.ttf', fontWeight: 900, fontStyle: 'italic'},
            {src: basepath + '/Roboto/Roboto-Bold.ttf', fontWeight: 700},
            {src: basepath + '/Roboto/Roboto-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic'},
            {src: basepath + '/Roboto/Roboto-Medium.ttf', fontWeight: 500},
            {src: basepath + '/Roboto/Roboto-MediumItalic.ttf', fontWeight: 500, fontStyle: 'italic'},
            {src: basepath + '/Roboto/Roboto-Regular.ttf', fontWeight: 400},
            {src: basepath + '/Roboto/Roboto-Italic.ttf', fontWeight: 400, fontStyle: 'italic'},
            {src: basepath + '/Roboto/Roboto-Light.ttf', fontWeight: 300},
            {src: basepath + '/Roboto/Roboto-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic'},
            {src: basepath + '/Roboto/Roboto-Thin.ttf', fontWeight: 100},
            {src: basepath + '/Roboto/Roboto-ThinItalic.ttf', fontWeight: 100, fontStyle: 'italic'},
        ],
    });
    Font.register({
        family: 'Arial Narrow',
        fonts: [
            {src: basepath + '/Arial Narrow/Arial Narrow Bold.ttf', fontWeight: 700},
            {src: basepath + '/Arial Narrow/Arial Narrow Bold Italic.ttf', fontWeight: 700, fontStyle: 'italic'},
            {src: basepath + '/Arial Narrow/Arial Narrow.ttf', fontWeight: 400},
            {src: basepath + '/Arial Narrow/Arial Narrow Italic.ttf', fontWeight: 400, fontStyle: 'italic'},
        ],
    });
    Font.register({
        family: 'Times New Roman',
        fonts: [
            {src: basepath + '/Times New Roman/Times New Roman Bold.ttf', fontWeight: 700},
            {src: basepath + '/Times New Roman/Times New Roman Bold Italic.ttf', fontWeight: 700, fontStyle: 'italic'},
            {src: basepath + '/Times New Roman/Times New Roman Italic.ttf', fontStyle: 'italic'},
            {src: basepath + '/Times New Roman/Times New Roman.ttf', fontWeight: 400},
        ],
    });
}
