import {validName} from '@vantix/functions/isomorphic/DataValidation';
import {T_SECOND} from '@vantix/functions/isomorphic/generics';
import {P_Users_Info, P_Users_PublicInfo} from '@vantix/rtdb-rules/default';
import {Spin} from 'antd';
import React, {useCallback, useEffect, useMemo} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {shallowEqual, useSelector} from 'react-redux';
import {throttle} from 'throttle-debounce';

import {RTDB, addToUpdateObject} from 'project-rtdb';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Typography from '../../../components/Typography';
import {currentUserID, logout} from '../../../utils/Auth';

import classes from './index.module.scss';

interface Inputs {
    firstName: string,
    lastName: string,
}

function saveName({firstName, lastName}: {firstName: string; lastName: string}) {
    const Update = {};

    addToUpdateObject(Update, [...P_Users_Info, currentUserID(), 'FirstName'], firstName);
    addToUpdateObject(Update, [...P_Users_Info, currentUserID(), 'LastName'], lastName);
    addToUpdateObject(Update, [...P_Users_PublicInfo, currentUserID(), 'Name'], [firstName, lastName].map(e => (e || '').trim()).filter(Boolean).join(' '));

    return RTDB.write(Update);
}

export default function NameSettings() {
    const {FirstName, LastName} = useSelector(state => ({...state.user}), shallowEqual);

    const {register, handleSubmit, setError, getValues, setValue, watch, formState: {errors, isDirty, isSubmitting}} = useForm<Inputs>({});
    const onSubmit: SubmitHandler<Inputs> = useCallback(async data => {}, [setError]);

    useEffect(() => {
        if (isDirty) {
            return;
        }

        setValue('firstName', FirstName);
        setValue('lastName', LastName);
    }, [isDirty, FirstName, LastName]);

    const throttledSave = useMemo(() => throttle(T_SECOND, saveName), []);

    const formValues = watch();
    useEffect(() => {
        if (
            formValues.firstName
            && formValues.lastName
            && (
                formValues.firstName !== FirstName
                || formValues.lastName !== LastName
            )
        ) {
            throttledSave(formValues);
        }
    }, [formValues.firstName, formValues.lastName]);

    return (
        <div className={classes['NameSettings']}>
            <Typography className={classes['NameSettings__title']} variant="h6" weight="medium">
                Numele tău&nbsp;&nbsp;{formValues.firstName !== FirstName || formValues.lastName !== LastName ? <Spin size="small" /> : null}
                <Button
                    special="red"
                    style={{marginLeft: 'auto', padding: '0', borderWidth: '0'}}
                    variant="link"
                    onClick={() => logout()}
                >
                    Log out
                </Button>
            </Typography>
            <form className={classes['NameSettings__form']} onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Input
                        autoComplete="firstName"
                        error={errors.firstName?.message}
                        id="firstName"
                        label="Prenume"
                        placeholder="Ion"
                        type="firstName"
                        {...register('firstName', {
                            required: true,
                            validate: validName,
                            onBlur: () => setValue('firstName', (getValues().firstName || '').trim()),
                        })}
                    />
                </div>
                <div>
                    <Input
                        autoComplete="lastName"
                        error={errors.lastName?.message}
                        id="lastName"
                        label="Nume"
                        placeholder="Popescu"
                        type="lastName"
                        {...register('lastName', {
                            required: true,
                            validate: validName,
                            onBlur: () => setValue('lastName', (getValues().lastName || '').trim()),
                        })}
                    />
                </div>
            </form>
        </div>
    );
}
