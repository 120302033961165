import {atLeastTwoDecimals, calculateMathExpression} from '@vantix/functions/isomorphic/math';
import BigNumber from 'bignumber.js';
import {MutableRefObject, memo, useLayoutEffect, useMemo} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

import {FormInputs} from '../../common';

function FieldSync({ItemID, lastUpdatedInputsRef}: {
    ItemID: string,
    lastUpdatedInputsRef: MutableRefObject<string[]>,
}) {
    const {setValue, getFieldState} = useFormContext<FormInputs>();
    const FormItem = useWatch<FormInputs>({name: `Items.${ItemID}`}) as FormInputs['Items'][string];
    const itemLoaded = !!FormItem?.Loaded;

    const calculatedInvoicedAmount = useMemo(() => calculateMathExpression(FormItem?.InvoicedAmountExpression), [FormItem?.InvoicedAmountExpression]);
    useLayoutEffect(() => {
        if (!itemLoaded) {
            return;
        }

        setValue(`Items.${ItemID}.InvoicedAmount`, calculatedInvoicedAmount.isNaN() ? '0' : calculatedInvoicedAmount.toString());
    }, [itemLoaded, calculatedInvoicedAmount.toString()]);

    const calculatedReceivedAmount = useMemo(() => calculateMathExpression(FormItem?.ReceivedAmountExpression), [FormItem?.ReceivedAmountExpression]);
    useLayoutEffect(() => {
        if (!itemLoaded) {
            return;
        }

        setValue(`Items.${ItemID}.ReceivedAmount`, calculatedReceivedAmount.isNaN() ? '0' : calculatedReceivedAmount.toString());
    }, [itemLoaded, calculatedReceivedAmount.toString()]);

    useLayoutEffect(() => {
        if (!itemLoaded) {
            return;
        }

        const unitAcquisitionPriceLastUpdate = lastUpdatedInputsRef.current.indexOf('UnitAcquisitionPrice');
        const acquisitionTotalPriceLastUpdate = lastUpdatedInputsRef.current.indexOf('AcquisitionTotalPrice');

        if (acquisitionTotalPriceLastUpdate > unitAcquisitionPriceLastUpdate) {
            const UnitAcquisitionPrice = FormItem?.UnitAcquisitionPrice;
            const AcquisitionTotalPrice = BigNumber(UnitAcquisitionPrice).multipliedBy(BigNumber(FormItem?.InvoicedAmount).abs()).decimalPlaces(2);

            if (!AcquisitionTotalPrice.isNaN() && AcquisitionTotalPrice.isFinite()) {
                setValue(`Items.${ItemID}.AcquisitionTotalPrice`, AcquisitionTotalPrice.toFixed(2), {
                    shouldDirty: false,
                    shouldValidate: getFieldState(`Items.${ItemID}.AcquisitionTotalPrice`).invalid,
                });
            }
        }
        else {
            const AcquisitionTotalPrice = FormItem?.AcquisitionTotalPrice;
            const UnitAcquisitionPrice = BigNumber(AcquisitionTotalPrice).dividedBy(BigNumber(FormItem?.InvoicedAmount).abs()).decimalPlaces(6);

            if (!UnitAcquisitionPrice.isNaN() && UnitAcquisitionPrice.isFinite()) {
                setValue(`Items.${ItemID}.UnitAcquisitionPrice`, atLeastTwoDecimals(UnitAcquisitionPrice), {
                    shouldDirty: false,
                    shouldValidate: getFieldState(`Items.${ItemID}.UnitAcquisitionPrice`).invalid,
                });
            }
        }
    }, [itemLoaded, FormItem?.UnitAcquisitionPrice, FormItem?.AcquisitionTotalPrice, FormItem?.InvoicedAmount]);

    useLayoutEffect(() => {
        if (!itemLoaded) {
            return;
        }

        setValue(`Items.${ItemID}.VATRateAcquisition`, FormItem?.VATRateSale, {
            shouldValidate: true,
        });
    }, [itemLoaded, FormItem?.VATRateSale]);

    return null;
}

export default memo(FieldSync);
