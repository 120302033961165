import {callable} from '@agmedia/firebase-browser-helpers';
import {getAuth, signInWithCustomToken} from 'firebase/auth';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {devMode} from 'config';
import store from 'project-store';

import {PageLoader} from '../../../components/PageLoader';
import Typography from '../../../components/Typography';

export default function ZohoLoginCallback() {
    const [params] = useSearchParams();
    const [sendRequest, setSendRequest] = useState(false);
    const [error, setError] = useState('');

    useLayoutEffect(() => setSendRequest(true), []);

    useEffect(() => {
        if (!sendRequest) {
            return;
        }

        if (!params.get('code')) {
            setError('Invalid auth URL');
            return;
        }

        (async () => {
            try {
                const {data: response} = await callable.europe_west1.zohoLogin({
                    code: params.get('code'),
                    accountsServer: params.get('accounts-server') || 'https://accounts.zoho.eu',
                    devMode,
                });

                if (response.authToken) {
                    store.dispatch.generics.update({
                        preventUserLoad: true,
                    });

                    await signInWithCustomToken(getAuth(), response.authToken);
                }
                else {
                    setError('Unexpected error');
                }
            }
            catch (error) {
                console.error(error);
                setError(error.message as string || 'Unexpected error');
            }
        })();
    }, [sendRequest]);

    if (error) {
        return (
            <Typography align="center" color="error">{error}</Typography>
        );
    }

    return (
        <PageLoader />
    );
}
