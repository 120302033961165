interface StoreGraphicsOptions {
    device: 'R' | 'E' | 'B' | 'A',
}

export const ZebraFontFamily = {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    ZERO: '0',
};

export const Orientation = {
    Normal: 'N',
    Rotated90Degrees: 'R',
    Inverted180Degrees: 'I',
    BottomUp270Degrees: 'B',
};

interface Position {
    x: number,
    y: number,
}

export type {StoreGraphicsOptions, Position};
