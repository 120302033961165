import {faPen, faPlus, faPrint} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatEANCode} from '@vantix/functions/isomorphic/ean';
import {decodeRTDBNumber} from '@vantix/functions/isomorphic/firebase';
import {calculatePriceWithVAT} from '@vantix/functions/isomorphic/products';
import {stripDiacritics} from '@vantix/functions/isomorphic/stringTools';
import {P_ProductData_Source_Shop, T_DB_ShopProduct} from '@vantix/rtdb-rules/default';
import {Modal} from 'antd';
import {format} from 'date-fns';
import roLocale from 'date-fns/locale/ro';
import {useLiveQuery} from 'dexie-react-hooks';
import {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_TableOptions,
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {MRT_Localization_RO} from 'material-react-table/locales/ro';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import useEvent from 'react-use-event-hook';

import GatedSerialScannerAutoConnect from '@/apps/checkout-station/views/CashierCheckout/Themes/Vistrum/SerialScannerAutoConnect';
import Button from '@/components/Button';
import {PageLoader} from '@/components/PageLoader';
import Typography from '@/components/Typography';
import {db} from '@/dexie';
import {devMode} from 'config';
import {RTDB, useRTDBSubscription} from 'project-rtdb';

import ProductLabelPrinter from '../../components/ProductLabelPrinter';
import {ProductEditor} from '../ProductEditor';

import classes from './index.module.scss';

function ProductsList({tableData, ShopID}: {tableData: (
    Omit<T_DB_ShopProduct, 'Created'> & {
        Created: number,
        PriceWithVAT: string,
        CategoryName: string,
    }
)[], ShopID: string,}) {
    const {t} = useTranslation('product');

    const columns = useMemo<MRT_ColumnDef<typeof tableData[number]>[]>(() => [
        {
            id: 'Created',
            header: 'Dată ultimei modificări',
            accessorKey: 'Created',
            filterVariant: 'date-range',
            muiFilterDatePickerProps: {
                views: ['year', 'month', 'day'],
                format: 'dd.MM.yyyy',
            },
            muiTableHeadCellProps: {
                className: classes['DateHeaderCell'],
            },
            sortDescFirst: true,
            Cell: ({cell}) => format(cell.getValue() as number, 'HH:mm dd MMM yyyy', {locale: roLocale}),
            size: 200,
        },
        {
            id: 'Enabled',
            header: 'Disponibil pentru vânzare',
            accessorFn: (row) => row?.Disabled ? 'Nu' : 'Da',
            filterVariant: 'select',
            size: 150,
        },
        {
            id: 'Code',
            header: 'Cod',
            accessorFn: (row) => formatEANCode(row.Code?.Value) || row.Code?.Value || '',
            filterFn: (row, columnID, searchFor) => (
                stripDiacritics(row.original.Code?.Value).toLowerCase().replaceAll(' ', '')
                    .startsWith(stripDiacritics(searchFor).toLowerCase().replaceAll(' ', ''))
            ),
            size: 200,
        },
        {
            id: 'Name',
            header: 'Denumire',
            accessorKey: 'Name',
            filterFn: (row, columnID, searchFor) => (
                stripDiacritics(row.original.Name).toLowerCase()
                    .includes(stripDiacritics(searchFor).toLowerCase())
            ),
            size: 400,
        },
        {
            id: 'Category',
            header: 'Categorie',
            accessorKey: 'CategoryName',
            filterVariant: 'multi-select',
            size: 150,
        },
        {
            id: 'PriceWithVAT',
            header: 'Preț (cu TVA)',
            accessorKey: 'PriceWithVAT',
            filterVariant: 'range',
            muiTableHeadCellProps: {
                className: classes['DateHeaderCell'],
            },
            size: 130,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            id: 'Unit',
            header: 'UM',
            accessorFn: (product) => t(`unit/${product.Unit}`),
            filterVariant: 'select',
            size: 100,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            id: 'VATRate',
            header: 'TVA',
            accessorFn: (product) => product.VATRate + '%',
            filterVariant: 'select',
            size: 100,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        // {
        //     id: 'Stoc',
        //     header: 'Stoc',
        //     // accessorKey: 'PriceWithVAT',
        //     size: 150,
        //     muiTableBodyCellProps: {
        //         align: 'right',
        //     },
        // },
    ], []);

    const [editModalProductID, setEditModalProductID] = useState('');
    const [labelModalProductID, setLabelModalProductID] = useState('');
    const renderRowActions = useCallback<MRT_TableOptions<typeof tableData[number]>['renderRowActions']>(({row}) => (
        <div style={{margin: '2px 0', display: 'flex', gap: '4px'}}>
            <Link
                key="edit"
                style={{textDecoration: 'none'}}
                to={`./edit/${row.original.ID}`}
                onClick={(e) => {
                    e.preventDefault();

                    setEditModalProductID(row.original.ID);
                }}
            >
                <Button size="small" variant="outlined">
                    <FontAwesomeIcon icon={faPen} size="1x" />
                </Button>
            </Link>
            <Button size="small" variant="outlined" onClick={() => setLabelModalProductID(row.original.ID)}>
                <FontAwesomeIcon icon={faPrint} size="1x" />
            </Button>
        </div>
    ), []);

    const location = useLocation();
    const [columnFiltersState, setColumnFiltersState] = useState<MRT_ColumnFiltersState>(location.state?.columnFiltersState || [
        {
            id: 'Enabled',
            value: 'Da',
        },
    ]);
    const [paginationState, setPaginationState] = useState<MRT_PaginationState>(location.state?.paginationState || {
        pageIndex: 0,
        pageSize: devMode ? 25 : 50,
    });
    const navigate = useNavigate();

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableSortingRemoval: false,
        enableGrouping: false,
        enableExpanding: false,
        enableColumnActions: false,
        enableRowActions: true,
        enableRowVirtualization: devMode,
        enableFacetedValues: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableTableFooter: true,
        autoResetAll: false,
        enableTopToolbar: true,
        enableGlobalFilter: false,
        renderTopToolbarCustomActions: () => !ShopID ? null : (
            <>
                <div style={{marginLeft: 'auto'}} />
                <Link style={{textDecoration: 'none'}} to={`./edit/${RTDB.newKey([...P_ProductData_Source_Shop, ShopID, 'Product'])}?new=true`}>
                    <Button variant="outlined">
                        Adaugă produs&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus} size="lg" />
                    </Button>
                </Link>
            </>
        ),
        positionActionsColumn: 'last',
        renderRowActions,
        memoMode: 'cells',
        localization: MRT_Localization_RO,
        initialState: {
            sorting: [
                {
                    id: 'Created',
                    desc: true,
                },
            ],
            columnVisibility: {
                // id: false,
            },
            density: 'compact',
            showColumnFilters: true,
            columnSizing: {
                'mrt-row-actions': 150,
            },
        },
        state: {
            columnFilters: columnFiltersState,
            pagination: paginationState,
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
        onColumnFiltersChange: (updater) => {
            const newColumnFiltersState = typeof updater === 'function' ? updater(columnFiltersState) : updater;

            setColumnFiltersState(newColumnFiltersState);
            navigate('./', {
                replace: true,
                state: {
                    columnFiltersState: newColumnFiltersState,
                    paginationState,
                },
            });
        },
        onPaginationChange: (updater) => {
            const newPaginationState = typeof updater === 'function' ? updater(paginationState) : updater;

            setPaginationState(newPaginationState);
            navigate('./', {
                replace: true,
                state: {
                    columnFiltersState,
                    paginationState: newPaginationState,
                },
            });
        },
    });

    const onCode = useEvent((code) => {
        const newColumnFiltersState = columnFiltersState.filter(e => e?.id !== 'Code');

        newColumnFiltersState.push({
            id: 'Code',
            value: code,
        });

        setColumnFiltersState(newColumnFiltersState);
        navigate('./', {
            replace: true,
            state: {
                columnFiltersState: newColumnFiltersState,
                paginationState,
            },
        });
    });

    return (
        <>
            <GatedSerialScannerAutoConnect
                onCode={onCode}
            />
            <div style={{background: '#FFF'}}>
                <div className={classes['ProductsList']}>
                    <MaterialReactTable
                        table={table}
                    />
                </div>
            </div>
            <Modal
                destroyOnClose
                footer={null}
                open={!!editModalProductID}
                title={null}
                width={600}
                onCancel={() => setEditModalProductID('')}
                onClose={() => setEditModalProductID('')}
            >
                <Typography variant="h5">Editare produs</Typography>
                <ProductEditor
                    ProductID={editModalProductID}
                    ShopID={ShopID}
                    onSaved={() => {
                        setEditModalProductID('');
                    }}
                />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                open={!!labelModalProductID}
                title={null}
                width={600}
                onCancel={() => setLabelModalProductID('')}
                onClose={() => setLabelModalProductID('')}
            >
                <ProductLabelPrinter
                    ProductID={labelModalProductID}
                    ShopID={ShopID}
                    onClose={() => setLabelModalProductID('')}
                />
            </Modal>
        </>
    );
}

export default function ProductsListView() {
    const products = useLiveQuery(() => db.productData.toArray());

    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);

    const ProductCategories = useRTDBSubscription([...P_ProductData_Source_Shop, ShopID, 'Category']);

    const tableData = useMemo(() => (
        (products || [])
            .filter(product => product?.ID && !product.Disabled)
            .map(product => ({
                ...product,
                Created: decodeRTDBNumber(product.Created),
                PriceWithVAT: calculatePriceWithVAT(product.Price, product.VATRate).toFixed(2),
                CategoryName: ProductCategories?.[product.Category]?.Name,
            }))
            .slice(0, devMode ? 1000 : undefined)
    ), [products, ProductCategories]);

    if (!products) {
        return (
            <PageLoader />
        );
    }

    return (
        <ProductsList
            ShopID={ShopID}
            tableData={tableData}
        />
    );
}
