import {LocalizationProvider} from '@mui/x-date-pickers'; // eslint-disable-line import/no-extraneous-dependencies
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'; // eslint-disable-line import/no-extraneous-dependencies
import {Route, Routes} from 'react-router';
import {Link} from 'react-router-dom';

import Button from '@/components/Button';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {PageLoader} from '../../components/PageLoader';
import {EnsureUser, Redirect} from '../../utils/Routing';
import AccountSettingsView from '../../views/AccountSettings';
// import ANAFNewTokenView from '../../views/ANAFNewToken';
// import ANAFExternalInviteFlowEnd from '../../views/ANAFNewToken/ExternalInvite/flowEnd';
// import ANAFRevokeToken from '../../views/ANAFRevokeToken';
import AuthenticationView from '../../views/Authentication';
// import InvoiceInfoView from '../../views/InvoiceInfo';
// import InvoicesView from '../../views/Invoices';
import VerifyEmailView from '../../views/VerifyEmail';
import {createApp} from '../common';

import SerialLabelPrinter from './components/SerialLabelPrinter';
import AdoptSharedDeviceView from './views/AdoptSharedDevice';
import DocumentRendererView from './views/DocumentRenderer';
import EFacturaListView from './views/EFactura';
import ProductEditorView from './views/ProductEditor';
import ProductsListView from './views/ProductsList';
import StockReceptionView from './views/StockReceptionEditor';
import StockReceptionsListView from './views/StockReceptionsList';
import {WatchOrganizationData} from './watchers';

function ManagerApp() {
    return (
        <Routes>
            <Route
                element={
                    <EnsureUser
                        callback={({loadingUser, user}, location, navigate) => {
                            if (!loadingUser && !user?.ID) {
                                navigate('/auth', {
                                    replace: true,
                                    state: {
                                        from: location,
                                    },
                                });
                            }
                        }}
                        fallback={<PageLoader />}
                        shouldRenderChildren={({user}) => !!user?.ID}
                    >
                        <WatchOrganizationData />
                        <SerialLabelPrinter />
                        <LocalizationProvider dateAdapter={AdapterDateFns}><Routes>
                            <Route
                                element={
                                    <>
                                        <Header />
                                        <Routes>
                                            <Route
                                                index
                                                element={
                                                    <div style={{display: 'flex', gap: '16px', flexWrap: 'wrap', padding: '12px 32px'}}>
                                                        <Link style={{textDecoration: 'none'}} to="./products">
                                                            <Button size="large" variant="primary">Produse</Button>
                                                        </Link>
                                                        <Link style={{textDecoration: 'none'}} to="./stock-receptions">
                                                            <Button size="large" variant="primary">Recepții stoc</Button>
                                                        </Link>
                                                        <Link style={{textDecoration: 'none'}} to="./efactura">
                                                            <Button size="large" variant="primary">E-Factura</Button>
                                                        </Link>
                                                    </div>
                                                }
                                            />
                                            <Route element={<AdoptSharedDeviceView />} path="adopt-station">
                                                <Route index element={null} />
                                                <Route element={null} path=":SharedDeviceID" />
                                            </Route>
                                            <Route path="pdf">
                                                <Route path="nir">
                                                    <Route element={<DocumentRendererView type="NIR" />} path=":nirID" />
                                                </Route>
                                            </Route>
                                            <Route path="products">
                                                <Route index element={<ProductsListView />} />
                                                <Route element={<ProductEditorView />} path="edit/:ProductID" />
                                                <Route element={<Redirect to="../" />} path="edit" />
                                            </Route>
                                            <Route path="stock-receptions">
                                                <Route index element={<StockReceptionsListView />} />
                                                <Route element={<StockReceptionView />} path="edit/:ReceptionID" />
                                                <Route element={<StockReceptionView isNew />} path="edit/:ReceptionID/new" />
                                                <Route element={<Redirect to="../" />} path="edit" />
                                            </Route>
                                            <Route path="efactura">
                                                <Route index element={<EFacturaListView />} />
                                            </Route>
                                            {/* <Route index element={<OrganizationPicker />} />
                                            <Route path=":organizationID">
                                                <Route index element={<InvoicesView />} />
                                            </Route> */}

                                            <Route element={<AccountSettingsView />} path="setari-cont" />
                                            <Route element={<Redirect replace to="/setari-cont" />} path="setari" />
                                            <Route element={<Redirect replace to="/setari-cont" />} path="settings" />
                                            {/* <Route element={<ANAFNewTokenView />} path="setari-cont/anaf-auth/*" /> */}
                                            <Route element={<Redirect to="/" />} path="*" />
                                            {/* todo: in progress \/ */}
                                            {/* <Route element={<SendInvoiceToANAFView />} path="trimite-factura/:invoiceID" /> */}
                                        </Routes>
                                        <Footer />
                                    </>
                                    }
                                path="*"
                            />
                            {/* <Route path="embedded">
                                <Route path=":organizationID">
                                    <Route index element={<InvoicesView forcedOrganization />} />
                                    <Route element={<InvoiceInfoView />} path="invoice/:invoiceID" />
                                </Route>
                            </Route> */}
                        </Routes></LocalizationProvider>
                    </EnsureUser>
                        }
                path="*"
            />
            <Route element={<VerifyEmailView />} path="verify-email/:token" />

            {/* <Route element={<Redirect replace to="/extern/anaf-auth/:inviteCode" />} path="eaa/:inviteCode" /> */}
            {/* <Route path="extern"> */}
            {/* <Route path="anaf-auth"> */}
            {/* <Route element={<ANAFExternalInvite />} path=":inviteCode" />
                    <Route element={<ANAFExternalInvite />} path="code" /> */}
            {/* <Route element={<ANAFExternalInviteFlowEnd />} path="gata/:tokenID" />
                    <Route element={<ANAFRevokeToken />} path="revocare" /> */}
            {/* <Route element={<Redirect to="/" />} path="*" /> */}
            {/* </Route> */}
            {/* <Route element={<Redirect to="/" />} path="*" /> */}
            {/* </Route> */}

            <Route
                element={
                    <EnsureUser
                        callback={({loadingUser, user}, location, navigate) => {
                            if (!loadingUser && user?.ID) {
                                try {
                                    const from = location.state?.from as Location;

                                    if (from?.pathname && !(from?.pathname || '').startsWith('auth')) {
                                        navigate(from, {
                                            replace: true,
                                        });

                                        return;
                                    }
                                }
                                catch {}

                                navigate('/');
                            }
                        }}
                        fallback={<PageLoader />}
                        shouldRenderChildren={({loadingUser, user}) => !loadingUser && !user?.ID}
                    >
                        <AuthenticationView />
                    </EnsureUser>
                        }
                path="auth/*"
            />
        </Routes>
    );
}

createApp(<ManagerApp />);
