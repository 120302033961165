import {Zip, ZipPassThrough} from 'fflate';

// todo: support streams
export async function zipFiles(files: {
    name: string,
    content: Uint8Array,
}[]): Promise<Blob> {
    const buffer = [];

    await new Promise<void>((resolve, reject) => {
        const zip = new Zip((error, data, final) => {
            if (error) {
                return reject(error);
            }
            buffer.push(data);

            if (final) {
                resolve();
            }
        });

        Promise.all(files.map(async file => {
            const zippedFile = new ZipPassThrough(file.name);
            zip.add(zippedFile);

            zippedFile.push(file.content, true);
        })).then(() => zip.end()).catch(reject);
    });

    return new Blob(buffer, {
        type: 'application/zip',
    });
}
