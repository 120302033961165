import {sortBy} from '@vantix/functions/isomorphic/generics';
import {P_ProductData_Compute_ModifiedAt, P_ProductData_Compute_Patches, P_Supplier_Source_Shop, T_DB_ShopProduct} from '@vantix/rtdb-rules/default';
import {dequal} from 'dequal';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {db} from '@/dexie';
import {updateLocalProducts} from '@/utils/indexeddb';
import {useRTDBSubscription} from 'project-rtdb';

import {getProductDataViaID} from '../checkout-station/views/CashierCheckout/Themes/Vistrum/Numpad';

// todo: this should share code with SharedDevice/watchers.ts
export function WatchOrganizationData(): null {
    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);

    const ProductPatches = useRTDBSubscription([...P_ProductData_Compute_Patches, ShopID]);
    const ProductDataModified = useRTDBSubscription([...P_ProductData_Compute_ModifiedAt, ShopID]);
    const Suppliers = useRTDBSubscription([...P_Supplier_Source_Shop, ShopID, 'Supplier']);

    const [localProductDataModified, setLocalProductDataModified] = useState(localStorage.getItem('ProductDataModified'));

    useEffect(() => {
        if (!ProductDataModified) {
            return;
        }

        let cancelled = false;

        (async () => {
            if (localProductDataModified !== ProductDataModified.toString()) {
                console.log('PDB full update');

                await updateLocalProducts(ShopID);

                localStorage.setItem('ProductDataModified', ProductDataModified.toString());
                setLocalProductDataModified(ProductDataModified.toString());
            }

            if (cancelled) {
                return;
            }

            await Promise.all(
                Object.values(ProductPatches || {})
                    .filter(e => e?.ProductID && e?.Patch)
                    .sort(sortBy(ProductPatch => [ProductPatch.Modified, 'lexicoAsc']))
                    .map(async (ProductPatch) => {
                        const {ProductID, Patch} = ProductPatch;

                        const dbProduct = await getProductDataViaID(ProductID);

                        const changes: Partial<T_DB_ShopProduct> = {};
                        for (const key of Object.keys(Patch || {})) {
                            if (!dequal(Patch[key], dbProduct?.[key])) {
                                changes[key] = Patch[key];
                            }
                        }

                        // todo: send telemetry events?
                        if (!dbProduct?.ID && Patch?.ID && Patch?.Name) {
                            console.log('PDB incremental add', Patch);
                            await db.productData.add(Patch);
                        }
                        else if (Object.keys(changes).length > 0) {
                            console.log('PDB incremental change', ProductID, changes);
                            await db.productData.update(ProductID, changes);
                        }

                        if (changes?.Code?.Value) {
                            await db.productCodes.put({
                                Code: changes.Code.Value,
                                ProductID,
                            });
                        }
                    }),
            );
        })();

        return () => {
            cancelled = true;
        };
    }, [ProductPatches, localProductDataModified, ProductDataModified]);

    return null;
}
