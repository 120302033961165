import {AnimatePresence, motion} from 'framer-motion';
import React from 'react';
import {Route, Routes, useLocation} from 'react-router';

import Typography from '../../components/Typography';
import {Redirect} from '../../utils/Routing';

import LoginForm from './Login';
import ZohoLoginCallback from './ZohoCallback';

import classes from './index.module.scss';

const transition = {
    type: 'spring',
    bounce: 0.25,
    duration: 0.4,
};

export default function AuthenticationView() {
    const location = useLocation();

    const logo = (
        <div className={classes['Auth__logo']}>
            <div className={classes['Auth__logo__image']} />
            <Typography
                className={classes['Auth__logo__text']}
                variant="pMedium"
                weight="medium"
            >
                Autentificare zFactură
            </Typography>
        </div>
    );

    return (
        <div className={classes['Auth']}>
            <div className={classes['Auth__inner']}>
                <div className={classes['Auth__content']}>
                    {logo}
                    <AnimatePresence initial={false} mode="popLayout">
                        <div key={location.pathname}>
                            <Routes location={location}>
                                <Route
                                    index
                                    element={
                                        <motion.div
                                            key="login"
                                            animate={{x: 0, opacity: 1}}
                                            exit={{x: '-100%', opacity: 0}}
                                            initial={{x: '-100%', opacity: 0}}
                                            style={{background: '#FFF'}}
                                            transition={transition}
                                        >
                                            <LoginForm />
                                        </motion.div>}
                                />
                                <Route element={<ZohoLoginCallback />} path="zoho/callback" />
                                <Route element={<Redirect to="./" />} path="*" />
                            </Routes>
                        </div>
                    </AnimatePresence>
                </div>
                {/* TODO: add this on desktop */}
                {/* <div className={classes['Auth__banner']}>
                    <div className={classes['Auth__banner__carousel']} />
                </div> */}
            </div>
        </div>
    );
}
