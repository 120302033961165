import {SharedDeviceAdoptionTokenData} from '@vantix/functions/isomorphic/data';
import {decodeEasyToWrite, decodeRTDBKey, encodeEasyToWrite, encodeRTDBKey} from '@vantix/functions/isomorphic/firebase';
import {
    P_ERPClient_Source_SharedDevice,
    P_ERPClient_Source_SharedDeviceAdoptionCode,
    P_SharedDeviceReadyToAdopt,
    P_Users_Settings,
} from '@vantix/rtdb-rules/default';
import {SignJWT} from 'jose';
import {useCallback, useEffect, useMemo} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router';
import {base64} from 'rfc4648';

import Button from '@/components/Button';
import FormSelect from '@/components/FormSelect';
import Input from '@/components/Input';
import Spinner from '@/components/Spinner';
import Typography from '@/components/Typography';
import {currentUserID} from '@/utils/Auth';
import {RTDB, useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

interface AdoptForm {
    SharedDeviceID?: string,
    RestoreAccessCheckoutStationID?: string,
    Name: string,
}

export default function AdoptSharedDeviceView() {
    const {SharedDeviceID: URLSharedDeviceID} = useParams<{
        SharedDeviceID?: string,
    }>();
    const encodedURLSharedDeviceID = useMemo(() => {
        try {
            return encodeEasyToWrite(decodeRTDBKey(URLSharedDeviceID)).replaceAll('-', ' - ');
        }
        catch {}
    }, [URLSharedDeviceID]);

    const form = useForm<AdoptForm>({
        defaultValues: {
            SharedDeviceID: encodedURLSharedDeviceID,
        },
    });
    const {watch, register, handleSubmit, formState: {errors, isSubmitting, isSubmitSuccessful}} = form;

    const ERPClientID = useRTDBSubscription([...P_Users_Settings, currentUserID(), 'DefaultCompanyID']);

    const EncodedSharedDeviceID = watch('SharedDeviceID');
    const SharedDeviceID = useMemo(() => {
        try {
            return encodeRTDBKey(decodeEasyToWrite(EncodedSharedDeviceID)) || undefined;
        }
        catch {}
    }, [EncodedSharedDeviceID]);

    const sharedDeviceAdoptionData = useRTDBSubscription([...P_SharedDeviceReadyToAdopt, SharedDeviceID]);
    const sharedDeviceAdoptionDataLoaded = useRTDBLoaded([...P_SharedDeviceReadyToAdopt, SharedDeviceID]);
    const CheckoutStationAdoptionCode = useRTDBSubscription([...P_ERPClient_Source_SharedDeviceAdoptionCode, ERPClientID]);

    const existingSharedDevices = useRTDBSubscription([...P_ERPClient_Source_SharedDevice, ERPClientID]);
    const existingSharedDevicesOptions = useMemo(() => (
        Object.values(existingSharedDevices || {})
            .filter(sharedDevice => sharedDevice.Type === sharedDeviceAdoptionData?.DeviceType)
            .map(sharedDevice => ({
                value: sharedDevice.ID,
                label: sharedDevice.Name,
            }))
    ), [existingSharedDevices, sharedDeviceAdoptionData?.DeviceType]);

    const navigate = useNavigate();
    useEffect(() => {
        if (
            SharedDeviceID
            && sharedDeviceAdoptionDataLoaded
            && !sharedDeviceAdoptionData
        ) {
            if (isSubmitSuccessful) {
                // todo: maybe navigate to checkout stations list
                navigate('/');
            }
            else {
                navigate('./');
            }
        }
    }, [SharedDeviceID, sharedDeviceAdoptionData, sharedDeviceAdoptionDataLoaded]);

    const onSubmit = useCallback<SubmitHandler<AdoptForm>>(async (data) => {
        if (!CheckoutStationAdoptionCode || !ERPClientID) {
            return;
        }

        const decodedCheckoutStationID = encodeRTDBKey(decodeEasyToWrite(data.SharedDeviceID));
        const DeviceType = (await RTDB.get([...P_SharedDeviceReadyToAdopt, decodedCheckoutStationID]))?.DeviceType;

        const tokenData: SharedDeviceAdoptionTokenData = {
            Name: data.Name,
            ERPClientID,
            ShopID: '0tnlYGx-xSzcscu86ppoD0',
            SharedDeviceID: data.RestoreAccessCheckoutStationID || decodedCheckoutStationID,
            DeviceType,
        };

        const token = await(
            new SignJWT(tokenData)
                .setProtectedHeader({alg: 'HS256'})
                .setIssuedAt()
                .setExpirationTime('30min')
                .sign(base64.parse(CheckoutStationAdoptionCode))
        );

        try {
            await RTDB.set([...P_SharedDeviceReadyToAdopt, decodedCheckoutStationID, 'Token'], token);
        }
        catch (error) {
            console.error(error);
        }
    }, [CheckoutStationAdoptionCode, ERPClientID]);

    return (
        <FormProvider {...form}><form
            style={{
                display: 'flex',
                gap: '8px',
                padding: '16px',
                maxWidth: '600px',
                margin: '0 auto',
                flexDirection: 'column',
            }}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Input
                error={errors.SharedDeviceID?.message}
                label="Cod conectare stație"
                name="CheckoutStationID"
                placeholder="HKJ2WR - 4N7ZA4 - PJ43X7 - 7R3WZJ - LY"
                {...register('SharedDeviceID')}
            />

            <Typography color="secondary">Restaurare acces stație</Typography>
            <FormSelect
                disabled={!sharedDeviceAdoptionData?.ID}
                name="RestoreAccessCheckoutStationID"
                options={existingSharedDevicesOptions}
                placeholder="Alege stația"
                size="large"
            />
            <Input
                disabled={!sharedDeviceAdoptionData?.ID}
                error={errors.Name?.message}
                label="Nume stație"
                name="CheckoutStationName"
                placeholder="Casa 1"
                {...register('Name', {
                    required: {
                        message: 'Câmp obligatoriu',
                        value: true,
                    },
                })}
            />
            <Button
                disabled={!sharedDeviceAdoptionData?.ID}
                size="large"
                type="submit"
                variant="primary"
            >{isSubmitting || (isSubmitSuccessful && sharedDeviceAdoptionData?.Token) ? <Spinner size="small" /> : 'Adopt'}</Button>
        </form></FormProvider>
    );
}
