import {faReceipt} from '@fortawesome/pro-light-svg-icons';
import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {faCircleExclamation, faPrint} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {randomUUID} from '@vantix/functions/isomorphic/uuid';
import {
    P_ANAF_eInvoice_Computed_ByCompany,
    P_ANAF_eInvoice_Computed_ByInvoice,
    P_ERPClient_Data,
    P_Supplier_Source_Shop,
    P_Users_Settings,
    T_DB_ANAFInvoice,
    T_DB_ANAFInvoiceDirection,
} from '@vantix/rtdb-rules/default';
import {Card, Select, Spin, notification} from 'antd';
import {Semaphore} from 'async-mutex';
import clsx from 'clsx';
import {format} from 'date-fns';
import roLocale from 'date-fns/locale/ro';
import {saveAs} from 'file-saver';
import {MRT_ColumnDef, MRT_TableInstance, MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {MRT_Localization_RO} from 'material-react-table/locales/ro';
import printJS from 'print-js-updated';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import Button from '@/components/Button';
import {PageLoader} from '@/components/PageLoader';
import Spinner from '@/components/Spinner';
import Typography from '@/components/Typography';
import {currentUserID} from '@/utils/Auth';
import {WatchPath} from '@/utils/Routing';
import {useRouteSavedTablePreferences} from '@/utils/tables';
import {zipFiles} from '@/utils/zip';
import {devMode} from 'config';
import {RTDB, useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

import classes from './index.module.scss';

interface TableRowData extends T_DB_ANAFInvoice {}

interface InvoiceFileData {
    XML: string,
    Signature: string,
    JSON: string,
}

// browser handles caching these files
async function getInvoiceFileData(fileID: string): Promise<InvoiceFileData> {
    return (await fetch(`https://storage.vantix-erp.com/e-factura/${fileID}.json`)).json();
}

async function getInvoiceANAFPDF(InvoiceID: string): Promise<ArrayBuffer> {
    const {XML} = await getInvoiceFileData(InvoiceID);

    const response = await fetch('https://app.vantix-erp.com/api/anaf/pdf', {
        method: 'POST',
        body: XML,
    });

    if (response.headers.get('Content-Type') === 'application/pdf') {
        const buffer = await response.arrayBuffer();

        return buffer;
    }
    else if (response.headers.get('Content-Type') === 'application/json') {
        throw new Error(Object.values((await response.json())?.Messages || {}).map(e => e?.message || e || '').join('\n'));
    }
    else {
        throw new Error(await response.text());
    }
}

async function getZipFilesForInvoice(Invoice: T_DB_ANAFInvoice): Promise<Parameters<typeof zipFiles>[0]> {
    const {XML, Signature} = await getInvoiceFileData(Invoice.ID);

    const textEncoder = new TextEncoder();

    return [
        {
            name: `${Invoice.FileID}.xml`,
            content: textEncoder.encode(XML),
        },
        {
            name: `semnatura_${Invoice.FileID}.xml`,
            content: textEncoder.encode(Signature),
        },
        ...(await Promise.all(Object.values(Invoice.Messages || {}).map(async Message => {
            if (!Message?.ID) {
                return [];
            }

            const {XML, Signature} = await getInvoiceFileData(Message.ID);

            return [
                {
                    name: `mesaje/${Message.FileID}.xml`,
                    content: textEncoder.encode(XML),
                },
                {
                    name: `mesaje/semnatura_${Message.FileID}.xml`,
                    content: textEncoder.encode(Signature),
                },
            ];
        }))).flat(),
    ];
}

function RowActions({Invoice}: {
    Invoice: T_DB_ANAFInvoice,
}) {
    const [loading, setLoading] = useState(false);

    const downloadXML = useCallback(async () => {
        setLoading(true);
        const {XML} = await getInvoiceFileData(Invoice.ID);

        saveAs(new Blob([XML], {type: 'application/xml'}), `${Invoice.FileID}.xml`);
        setLoading(false);
    }, [Invoice?.ID]);
    const downloadZIP = useCallback(async () => {
        setLoading(true);

        const zipBlob = await zipFiles(await getZipFilesForInvoice(Invoice));

        saveAs(zipBlob, `${Invoice.ID}.zip`);

        setLoading(false);
    }, [Invoice]);
    const printPDF = useCallback(async () => {
        setLoading(true);

        try {
            const buffer = await getInvoiceANAFPDF(Invoice.ID);

            printJS({
                printable: URL.createObjectURL(new Blob([buffer], {type: 'application/pdf'})),
                type: 'pdf',
            });
        }
        catch (error) {
            console.error(error);

            const notificationID = randomUUID();
            notification.error({
                key: notificationID,
                message: <Typography style={{paddingLeft: '8px'}} variant="h6" weight="medium">Eroare serviciu ANAF PDF</Typography>,
                description: (/your support id is: \d+/gi).exec(error.message)?.[0] || 'Eroare necunoscută',
                icon: <FontAwesomeIcon icon={faCircleExclamation} size="xl" style={{color: 'red'}} />,
                duration: 15,
                closeIcon: null,
                showProgress: true,
                placement: 'topRight',
                onClick: () => notification.destroy(notificationID),
            });
        }

        setLoading(false);
    }, [Invoice?.ID]);

    return (
        <div
            className={clsx(classes['IncomingInvoiceActions'], {
                [classes['IncomingInvoiceActions--loading']]: loading || !Invoice?.ID,
            })}
        >
            <div className={classes['IncomingInvoiceActions__inner']}>
                <Button
                    disabled
                    size="small"
                    style={{background: '#FFFFFF'}}
                    variant="outlined"
                    onClick={downloadXML}
                >
                    <FontAwesomeIcon icon={faReceipt} size="lg" style={{marginRight: '4px'}} />
                    Previz.
                </Button>
                <Button
                    size="small"
                    style={{background: '#FFFFFF'}}
                    variant="outlined"
                    onClick={printPDF}
                >
                    <FontAwesomeIcon icon={faPrint} size="lg" />
                </Button>
                <Button
                    size="small"
                    style={{background: '#FFFFFF'}}
                    variant="outlined"
                    onClick={downloadZIP}
                >
                    <FontAwesomeIcon icon={faDownload} size="lg" style={{marginRight: '4px'}} />
                    ZIP
                </Button>
            </div>
            {loading ? (
                <div className={classes['IncomingInvoiceActions__loader']}><Spin /></div>
            ) : null}
        </div>
    );
}

function SelectToolbar({table, children}: {
    table: MRT_TableInstance<TableRowData>,
    children: ReactNode,
}) {
    const [loading, setLoading] = useState(false);
    const options = useMemo(() => ([
        loading ? ({
            label: (
                <>
                    Se încarcă
                    <Spinner size="small" style={{marginTop: '-4px'}} />
                </>
            ),
            value: 'loading',
        }) : null,
        {
            label: 'Alege acțiunea',
            value: '',
        },
        {
            label: (
                <>
                    <FontAwesomeIcon icon={faPrint} style={{marginRight: '8px'}} />
                    Imprimă
                </>
            ),
            value: 'print',
        },
    ] as const).filter(Boolean), [loading]);
    const [selectedValue, setSelectedValue] = useState<typeof options[number]['value']>('');

    const rowSelection = table.getState().rowSelection;
    useEffect(() => {
        if (Object.keys(rowSelection).length === 0) {
            setSelectedValue('');
        }
    }, [Object.keys(rowSelection).length === 0]);

    const onSelect = useCallback(async (value: typeof selectedValue) => {
        const invoices = Object.keys(table.getState().rowSelection).map(rowID => table.getRow(rowID)?.original);

        setLoading(true);

        try {
            if (value === 'zip') {
                const RequestMutex = new Semaphore(10);

                const files = (await Promise.all(invoices.map(Invoice => (
                    RequestMutex.runExclusive(() => (
                        getZipFilesForInvoice(Invoice)
                    ), 1)
                )))).flat();

                saveAs(await zipFiles(files), 'facturi.zip');
            }
            else if (value === 'print') {
                const RequestMutex = new Semaphore(10);

                const files = (await Promise.all(invoices.map(Invoice => (
                    RequestMutex.runExclusive(() => (
                        getInvoiceANAFPDF(Invoice.ID)
                    ), 1)
                )))).flat();

                const {PDFDocument} = await import('pdf-lib');
                const pdfDoc = await PDFDocument.create();

                for (const file of files) {
                    const parsedFile = await PDFDocument.load(file);

                    const pages = await pdfDoc.copyPages(parsedFile, parsedFile.getPageIndices());

                    for (const page of pages) {
                        pdfDoc.addPage(page);
                    }
                }

                const pdfBytes = await pdfDoc.save();

                printJS({
                    printable: URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'})),
                    type: 'pdf',
                });
            }
        }
        catch (error) {
            console.error(error);
        }

        setLoading(false);
    }, [rowSelection]);

    return (
        <div style={{padding: '0 1rem', height: '40px', display: 'flex', gap: '16px', alignItems: 'center'}}>
            <div>
                {children}
            </div>
            <Select
                disabled={loading}
                options={options}
                size="large"
                value={loading ? 'loading' : selectedValue}
                onChange={onSelect}
            />
        </div>
    );
}

function SupplierName({CompanyID}: {CompanyID: string}) {
    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);
    const Suppliers = useRTDBSubscription([...P_Supplier_Source_Shop, ShopID, 'Supplier']);

    const Supplier = useMemo(() => Object.values(Suppliers || {}).find(Supplier => Supplier?.CompanyID === CompanyID), [Suppliers]);

    return (
        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {Supplier?.Name || CompanyID}
        </div>
    );
}

function EFacturaList({direction, CompanyID}: {
    CompanyID: string,
    direction: T_DB_ANAFInvoiceDirection,
}) {
    const CompanyInvoices = useRTDBSubscription([...P_ANAF_eInvoice_Computed_ByCompany, CompanyID]); // todo: paginate these
    const invoicesToLoad = Object.entries(CompanyInvoices || {}).filter(([, d]) => d === direction).map(([InvoiceID]) => InvoiceID);
    const Invoices = useSelector(() => RTDB.store.selectPath([...P_ANAF_eInvoice_Computed_ByInvoice]));

    const loaded = useRTDBLoaded([
        [...P_ANAF_eInvoice_Computed_ByCompany, CompanyID],
        ...invoicesToLoad.map(InvoiceID => [...P_ANAF_eInvoice_Computed_ByInvoice, InvoiceID]),
    ]);
    const tableData = useMemo<TableRowData[]>(() => (
        Object.values(Invoices || {})
            .filter(Invoice => Invoice?.[direction === 'received' ? 'ToCompanyID' : 'FromCompanyID'] === CompanyID)
    ), [Invoices, CompanyID, direction]);

    const watchers = (
        <>
            {invoicesToLoad.map(InvoiceID => (
                <WatchPath key={InvoiceID} path={[...P_ANAF_eInvoice_Computed_ByInvoice, InvoiceID]} />
            ))}
        </>
    );

    const columns = useMemo<MRT_ColumnDef<TableRowData>[]>(() => [
        {
            id: 'SubmittedDate',
            header: 'Dată e-Factura',
            accessorKey: 'SubmittedDate',
            filterVariant: 'date-range',
            sortDescFirst: true,
            Cell: ({cell}) => cell.getValue() && format(cell.getValue() as number, 'HH:mm dd MMM yyyy', {locale: roLocale}),
            muiTableBodyCellProps: {
                style: {
                    paddingLeft: '24px',
                },
            },
            muiTableHeadCellProps: {
                style: {
                    paddingLeft: '24px',
                },
            },
            size: 160,
        },
        {
            id: 'ID',
            header: 'Index e-Factura',
            accessorKey: 'ID',
            filterFn: 'startsWith',
            size: 150,
        },
        {
            id: 'IssueDate',
            header: 'Emis',
            accessorKey: 'IssueDate',
            filterVariant: 'date-range',
            sortDescFirst: true,
            Cell: ({cell}) => cell.getValue() && format(cell.getValue() as number, 'dd MMM yyyy', {locale: roLocale}),
            size: 120,
        },
        {
            id: 'DueDate',
            header: 'Scadență',
            accessorKey: 'DueDate',
            sortDescFirst: true,
            Cell: ({cell}) => cell.getValue() && format(cell.getValue() as number, 'dd MMM yyyy', {locale: roLocale}),
            size: 120,
        },
        {
            id: 'SellerInvoiceID',
            header: 'Nr. Factură',
            accessorKey: 'SellerInvoiceID',
            size: 150,
            enableColumnFilter: true,
            filterFn: 'contains',
        },
        {
            id: 'OtherParty',
            header: direction === 'received' ? 'Vânzător' : 'Cumpărător',
            accessorKey: direction === 'received' ? 'FromCompanyID' : 'ToCompanyID',
            Cell: ({cell}) => <SupplierName CompanyID={cell.getValue()} />,
            filterFn: 'startsWith',
            size: 200,
        },
        {
            id: 'Total',
            header: 'Total',
            accessorKey: 'Total',
            size: 120,
        },
    ], []);

    const tablePreferences = useRouteSavedTablePreferences<TableRowData>({
        defaults: {
            columnFilters: [],
            sorting: [
                {
                    id: 'SubmittedDate',
                    desc: true,
                },
                {
                    id: 'IssueDate',
                    desc: true,
                },
            ],
            pagination: {
                pageIndex: 0,
                pageSize: devMode ? 25 : 100,
            },
        },
    });

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableSortingRemoval: false,
        enableGrouping: false,
        enableExpanding: false,
        enableColumnActions: false,
        enableRowActions: true,
        enableRowVirtualization: devMode,
        enableFacetedValues: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableTableFooter: true,
        enableSelectAll: true,
        enableRowSelection: true,
        autoResetAll: false,
        enableTopToolbar: true,
        enableGlobalFilter: false,
        enableStickyHeader: true,
        enableColumnFilters: true,
        positionActionsColumn: 'last',
        positionToolbarAlertBanner: 'top',
        renderToolbarAlertBannerContent: ({selectedAlert, table}) => (
            <SelectToolbar table={table}>
                {selectedAlert}
            </SelectToolbar>
        ),
        renderRowActions: ({row}) => (
            <RowActions Invoice={row.original} />
        ),
        memoMode: 'cells',
        localization: MRT_Localization_RO,
        initialState: {
            density: 'compact',
            showColumnFilters: true,
            columnSizing: {
                'mrt-row-actions': 250,
            },
        },
        defaultColumn: {
            enableColumnFilter: false,
        },
        state: {
            ...tablePreferences.state,
        },
        muiTablePaperProps: {
            elevation: 0,
            className: classes['InvoicesTablePaper'],
        },
        muiTableContainerProps: {
            className: classes['InvoicesTableContainer'],
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
        ...tablePreferences,
    });

    if (!loaded) {
        return (
            <div className={classes['IncomingInvoicePlaceholder']}>
                {watchers}
                <Spinner />
            </div>
        );
    }


    return (
        <>
            {watchers}
            <MaterialReactTable
                table={table}
            />
        </>
    );
}

export default function EFacturaListView() {
    const ERPClientID = useRTDBSubscription([...P_Users_Settings, currentUserID(), 'DefaultCompanyID']);
    const CompanyID = useRTDBSubscription([...P_ERPClient_Data, ERPClientID])?.CompanyID;

    if (!CompanyID) {
        return (
            <PageLoader />
        );
    }

    return (
        <div style={{maxWidth: '1350px', margin: '0 auto'}}>
            <Card
                className={classes['InvoicesCard']}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography>Facturi primite</Typography>
                        {/* <RefreshANAFInvoicesButton CompanyID={CompanyID} /> */}
                    </div>
                }
            >
                <EFacturaList
                    CompanyID={CompanyID}
                    direction="received"
                />
            </Card>
            <Card
                className={classes['InvoicesCard']}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography>Facturi emise</Typography>
                        {/* <RefreshANAFInvoicesButton CompanyID={CompanyID} /> */}
                    </div>
                }
            >
                <EFacturaList
                    CompanyID={CompanyID}
                    direction="sent"
                />
            </Card>
        </div>
    );
}
