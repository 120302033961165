import {T_DB_ReceptionItem} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';

import {atLeastTwoDecimals, calculateMathExpression} from './math';
import {calculatePriceWithVAT, calculatePriceWithoutVAT, calculateUnitPercentGrossProfit} from './products';

export function calculateCompleteStockReceptionItem(Item: T_DB_ReceptionItem) {
    if (!Item?.ID) {
        return null;
    }

    const ReceivedAmountExpression = Item.IsDiscount ? Item.InvoicedAmountExpression : Item.ReceivedAmountExpression;
    const VATRateAcquisition = Item.VATRateAcquisition ?? Item.VATRateSale;

    const InvoicedAmount = calculateMathExpression(Item.InvoicedAmountExpression);
    const ReceivedAmount = calculateMathExpression(ReceivedAmountExpression);

    let AcquisitionTotalPrice = BigNumber('invalid');
    let UnitAcquisitionPrice = BigNumber('invalid');
    if (Item.AcquisitionTotalPrice) {
        AcquisitionTotalPrice = BigNumber(Item.AcquisitionTotalPrice).decimalPlaces(2);
        UnitAcquisitionPrice = BigNumber(AcquisitionTotalPrice).dividedBy(BigNumber(InvoicedAmount).abs()).decimalPlaces(6);
    }
    else if (Item.UnitAcquisitionPrice) {
        UnitAcquisitionPrice = BigNumber(Item.UnitAcquisitionPrice).decimalPlaces(6);
        AcquisitionTotalPrice = BigNumber(UnitAcquisitionPrice).multipliedBy(BigNumber(InvoicedAmount).abs()).decimalPlaces(2);
    }
    const AcquisitionTotalVat = (
        calculatePriceWithVAT(UnitAcquisitionPrice, VATRateAcquisition)
            .minus(UnitAcquisitionPrice)
            .multipliedBy(InvoicedAmount)
    );

    const UnitSalePriceWithVat = BigNumber(Item.UnitSalePrice).decimalPlaces(2);

    const unitPercentGrossProfit = calculateUnitPercentGrossProfit({
        UnitSalePrice: UnitSalePriceWithVat,
        VATRateAcquisition,
        UnitAcquisitionPrice,
    });

    const unitSaleVAT = UnitSalePriceWithVat.minus(calculatePriceWithoutVAT(UnitSalePriceWithVat, Item.VATRateSale)).decimalPlaces(2);
    const totalSaleVAT = unitSaleVAT.multipliedBy(ReceivedAmount);
    const totalSaleWithoutVAT = UnitSalePriceWithVat.minus(unitSaleVAT).decimalPlaces(2).multipliedBy(ReceivedAmount);

    return {
        ID: Item.ID,
        IsDiscount: Item.IsDiscount || false,
        Name: Item.IsDiscount ? Item.Name : '',
        Remarks: Item.Remarks || '',

        ProductID: Item.ProductID || '',

        VATRateAcquisition: VATRateAcquisition || '',
        VATRateSale: Item.VATRateSale || '',

        InvoicedAmount: InvoicedAmount.isNaN() || !Item.InvoicedAmountExpression ? '' : InvoicedAmount.toString(),
        ReceivedAmount: ReceivedAmount.isNaN() || !ReceivedAmountExpression ? '' : ReceivedAmount.toString(),

        AcquisitionTotalPrice: AcquisitionTotalPrice.isNaN() ? '' : AcquisitionTotalPrice.toString(),
        UnitAcquisitionPrice: UnitAcquisitionPrice.isNaN() ? '' : atLeastTwoDecimals(UnitAcquisitionPrice),
        AcquisitionTotalVat: AcquisitionTotalVat.isNaN() ? '' : AcquisitionTotalVat.toFixed(2),

        UnitSalePriceWithVat: UnitSalePriceWithVat.isNaN() ? '' : UnitSalePriceWithVat.toString(),
        UnitSaleVat: unitSaleVAT.isNaN() ? '' : unitSaleVAT.toString(),
        TotalSaleVat: totalSaleVAT.isNaN() ? '' : totalSaleVAT.toFixed(2),
        TotalSaleWithoutVat: totalSaleWithoutVAT.isNaN() ? '' : totalSaleWithoutVAT.toFixed(2),

        unitPercentGrossProfit: unitPercentGrossProfit.isNaN() ? '' : unitPercentGrossProfit.toString(),
    };
}
