import {callable} from '@agmedia/firebase-browser-helpers';
import {Card} from 'antd';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import Typography from '../../components/Typography';

import classes from './index.module.scss';

export default function VerifyEmailView() {
    const {token: rawToken} = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const token = rawToken.replaceAll('%2e', '.').split('.').map(part => {
                    try {
                        return JSON.parse(atob(part));
                    }
                    catch {}
                });

                if (typeof token[1] === 'object' && Object.keys(token[1]).length > 0) {
                    if (token[1].exp && Date.now() / 1000 >= token[1].exp) {
                        setLoading(false);
                        setError('Linkul de confirmare a expirat');
                        return;
                    }

                    await callable.europe_west1.verifyEmail({
                        token: rawToken.replaceAll('%2e', '.'),
                    });

                    setLoading(false);
                }
                else {
                    setLoading(false);
                    setError('Linkul de confirmare este invalid');
                }
            }
            catch {
                setLoading(false);
                setError('Linkul de confirmare este invalid');
            }
        })();
    }, [rawToken]);

    return (
        <div style={{padding: '32px 16px'}}>
            <Card style={{maxWidth: '400px', margin: '0 auto'}}>
                <div className={classes['VerifyEmail__logo']}>
                    <div className={classes['VerifyEmail__logo__image']} />
                    <Typography
                        className={classes['VerifyEmail__logo__text']}
                        variant="pMedium"
                        weight="medium"
                    >
                        zFactură
                    </Typography>
                </div>
                {loading ? (
                    <div style={{textAlign: 'center'}}><Spinner /></div>
                ) : (
                    error ? (
                        <>
                            <Typography color="error" variant="h6">{error}</Typography>
                            <Typography style={{margin: '16px 0 8px'}}>Poți să-ți trimiți alt link de confirmare de pe pagina <Link to="/settings">Setări cont</Link>.</Typography>
                        </>
                    ) : (
                        <div style={{textAlign: 'center'}}>
                            <Typography style={{marginBottom: '8px'}} variant="h6">Felicitări, ți-ai confirmat adresa de email!</Typography>
                            <Link style={{display: 'inline-block'}} to="/">
                                <Button size="large" variant="primary">Continuă</Button>
                            </Link>
                        </div>
                    )
                )}
            </Card>
        </div>
    );
}
