import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Font, pdf} from '@react-pdf/renderer';
import {PDFs, T_PDFSdata, T_PDFs} from '@vantix/functions/isomorphic/pdf';
import {loadFonts} from '@vantix/functions/isomorphic/pdf/fonts';
import {saveAs} from 'file-saver';
import {TFunction} from 'i18next';
import {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import Spinner from '@/components/Spinner';

import classes from './index.module.scss';

loadFonts(Font, '/fonts');

export interface PDFPreviewProps {
    url?: string,
    filename?: string,
    onDone?: () => void,
    children?: React.ReactElement,
    mode?: 'print' | 'download',
}

export function PDFPreview({url, filename, children, onDone}: PDFPreviewProps) {
    const [numPages, setNumPages] = useState(0);

    const refreshingBlob = useRef(false);
    const [blobURL, setBlobURL] = useState<string>();

    useEffect(() => {
        let cancelled = false;

        pdf(children).toBlob().then(blob => {
            if (cancelled) {
                return;
            }

            refreshingBlob.current = true;
            setBlobURL(URL.createObjectURL(blob));
        });

        return () => {
            cancelled = true;
        };
    }, []);

    const onDownload = useCallback(async ({bloburl, filename}: {
        bloburl: string,
        filename: string,
    }) => {
        const genericTypeBlob = new Blob([await(await fetch(bloburl)).blob()], {type: 'application/pdf'});
        saveAs(genericTypeBlob, filename);
    }, []);

    useEffect(() => {
        if (typeof onDone === 'function' && blobURL) {
            onDownload({
                bloburl: blobURL,
                filename,
            }).then(onDone);
        }
    }, [blobURL]);

    if (typeof onDone === 'function') {
        return (
            <Spinner size="small" />
        );
    }
    if (!url && !blobURL) {
        return null;
    }

    return (
        <div className={classes['PDFPreview']}>
            <FontAwesomeIcon
                icon={faDownload}
                onClick={() => onDownload({bloburl: blobURL, filename})}
            />

            <Document
                file={url || blobURL}
                loading={null}
                onLoadSuccess={(resolvedPDF) => {
                    setNumPages(resolvedPDF.numPages);
                }}
            >
                {Array.from({length: numPages}).map((_, page) => (
                    <Fragment key={page}>
                        <Page
                            pageNumber={page + 1}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            scale={4}
                        />
                        {page < numPages - 1 ? <br /> : null}
                    </Fragment>
                ))}
            </Document>
        </div>
    );
}

export interface DocumentRendererProps<TPDF extends keyof T_PDFs> {
    type: TPDF,
    data: T_PDFSdata[TPDF],

    filename?: PDFPreviewProps['filename'],
    onDone?: PDFPreviewProps['onDone'],
    mode?: PDFPreviewProps['mode'],

    t?: TFunction,
}

export default function DocumentRendererView<TPDF extends keyof T_PDFs>({filename, type, data, mode, t, onDone}: DocumentRendererProps<TPDF>) {
    const PDFDocument = PDFs[type].PDF;

    return (
        <PDFPreview
            filename={filename}
            mode={mode}
            onDone={onDone}
        >
            <PDFDocument
                data={data}
                t={t}
            />
        </PDFPreview>
    );
}
