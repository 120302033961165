import {callable} from '@agmedia/firebase-browser-helpers';
import {sanitizeCIF} from '@vantix/functions/isomorphic/stringTools';
import {Spin} from 'antd';
import {useEffect, useState} from 'react';

import {useRTDBLoaded, useRTDBSubscription} from 'project-rtdb';

export default function RoCompanyName({CompanyID}: {CompanyID: string}) {
    const SanitisedCompanyID = sanitizeCIF(CompanyID);

    const Name = useRTDBSubscription([...P_ANAF_Companies, SanitisedCompanyID, 'Name']);
    const loadedName = useRTDBLoaded([...P_ANAF_Companies, SanitisedCompanyID, 'Name']);
    const [loadingOnDemandName, setLoadingOnDemandName] = useState(false);

    useEffect(() => {
        if (Name || !loadedName) {
            return;
        }

        (async () => {
            setLoadingOnDemandName(true);

            try {
                await callable.europe_west1.updateAnafCompanyData({
                    cifs: [SanitisedCompanyID],
                });
            }
            catch {}

            setLoadingOnDemandName(false);
        })();
    }, [Name, loadedName, SanitisedCompanyID]);

    if (!Name && (!loadedName || loadingOnDemandName)) {
        return (
            <Spin size="small" />
        );
    }

    return (
        Name || `RO${SanitisedCompanyID}`
    );
}
