import {forwardRef} from 'react';
import {UseControllerProps, useController, useFormContext} from 'react-hook-form';

import Checkbox, {CheckboxProps} from '../Checkbox';
import Typography from '../Typography';

export type FormCheckboxProps = UseControllerProps & CheckboxProps;

function FormCheckbox({
    disabled,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    ...rest
}: FormCheckboxProps, ref) {
    const {control} = useFormContext();

    const {field: {onBlur, onChange, value}, fieldState: {error}} = useController({
        control,
        disabled,
        name,
        rules,
        shouldUnregister,
        defaultValue,
    });

    return (
        <>
            <Checkbox
                {...rest}
                ref={ref}
                checked={value}
                disabled={disabled}
                onBlur={onBlur}
                onChange={event => onChange({target: {value: event.target.checked}})}
            />
            {error?.message ? (
                <Typography
                    color="error"
                    style={{marginTop: '4px'}}
                    variant="pSmall"
                >{error.message}</Typography>
            ) : null}
        </>
    );
}

export default forwardRef(FormCheckbox);
