import {StyleSheet, Text} from '@react-pdf/renderer';
import React from 'react';

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        backgroundColor: '#FFF',
        fontSize: 9,
        fontWeight: 'normal',
        paddingHorizontal: 50,
        paddingVertical: 50,
    },
    bold: {
        fontWeight: 'bold',
    },
});

export const B = ({children}: {children?: React.ReactNode}) => (
    <Text style={styles.bold}>{children}</Text>
);
