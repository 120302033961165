import {faPen, faTrash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {calculateCompleteStockReceptionItem} from '@vantix/functions/isomorphic/stockReceptions';
import {P_ProductData_Source_Shop, P_StockReception_Source_Shop} from '@vantix/rtdb-rules/default';
import {Popconfirm} from 'antd';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import {useMemo, useRef, useState} from 'react';
import {useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDeepCompareMemo} from 'use-deep-compare';

import Button from '@/components/Button';
import FormSelect from '@/components/FormSelect';
import Spinner from '@/components/Spinner';
import {Tooltip, TooltipContent, TooltipTrigger} from '@/components/Tooltip';
import Typography from '@/components/Typography';
import {useProductData} from '@/utils/indexeddb';
import {RTDB, useRTDBSubscription} from 'project-rtdb';

import {FormInputs, LastUpdatedInputs, LastUpdatedInputsContext} from '../common';
import EditableCell from '../EditableCell';
import EditOrCreateProductModal from '../Modals/EditProduct';

import PopulateFields from './PopulateFields';
import RTDBSync from './RTDBSync';
import SyncInputs from './SyncInputs';

import classes from '../StockReceptionTable/index.module.scss';


const VAT_OPTIONS = ['19', '9', '5', '0'].map(e => ({value: e, label: e + '%'}));

function ProductName({ProductID}: {ProductID: string}) {
    const Product = useProductData({ProductID});

    return Product?.Name || '';
}

export interface StockReceptionRowProps {
    ShopID: string,
    ReceptionID: string,
    ItemID: string,
    index: number,
    disabled?: boolean,
}

function StockReceptionRow({ItemID, ShopID, ReceptionID, index, disabled, FormItem}: StockReceptionRowProps & {
    FormItem: FormInputs['Items'][string],
}) {
    const {t} = useTranslation(['stock-reception', 'product']);

    const RTDBItem = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items', ItemID]);

    const lastUpdatedInputsRef = useRef<string[]>(RTDBItem?.AcquisitionTotalPrice ? ['AcquisitionTotalPrice', 'UnitAcquisitionPrice'] : ['UnitAcquisitionPrice', 'AcquisitionTotalPrice']);
    const lastUpdatedInputsContextValue = useMemo<LastUpdatedInputs>(() => ({
        onChange: (fieldName) => {
            lastUpdatedInputsRef.current = [...new Set([
                fieldName,
                ...lastUpdatedInputsRef.current,
            ])];
        },
    }), []);

    const Product = useProductData({ProductID: FormItem?.ProductID, ProductCode: FormItem?.ProductCode});
    const completeItem = calculateCompleteStockReceptionItem(FormItem);

    const [editOrCreatedProductModal, setEditOrCreatedProductModal] = useState<{
        productID: string,
        create: boolean,
        code?: string,
        itemID?: string,
    }>();

    const unitName = Product?.Unit ? t(`product:unit/${Product.Unit}`) : '';

    const focusedFieldNameRef = useRef('');

    const EditableCellSharedProps = useMemo(() => ({
        ItemID,
        onFocus: (fieldName: string) => focusedFieldNameRef.current = fieldName,
        onBlur: () => focusedFieldNameRef.current = '',
    }), [ItemID]);

    return (
        <LastUpdatedInputsContext.Provider value={lastUpdatedInputsContextValue}>
            <div className={classes['StockReceptionTable__row']}>
                <RTDBSync
                    ItemID={ItemID}
                    ReceptionID={ReceptionID}
                    ShopID={ShopID}
                    lastUpdatedInputsRef={lastUpdatedInputsRef}
                />
                <PopulateFields
                    ItemID={ItemID}
                    ReceptionID={ReceptionID}
                    ShopID={ShopID}
                    focusedFieldNameRef={focusedFieldNameRef}
                />
                <SyncInputs
                    ItemID={ItemID}
                    lastUpdatedInputsRef={lastUpdatedInputsRef}
                />
                {!FormItem?.Loaded ? (
                    <div className={clsx(classes['StockReceptionTable__row'], classes['StockReceptionTable__row--loading'])}>
                        <div className={classes['StockReceptionTable__stickyLeftColumn']}>
                            <div>
                                <Spinner size="small" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {FormItem?.IsDiscount ? (
                            <>
                                <div className={classes['StockReceptionTable__stickyLeftColumn']}>
                                    <div className={clsx(classes['StockReceptionTable__row__index'], classes['StockReceptionTable__cell--text'])}>{index + 1}</div>
                                    <div className={classes['StockReceptionTable__cell--input']} style={{paddingRight: '6px'}}>
                                        <EditableCell
                                            {...EditableCellSharedProps}
                                            FieldName="Name"
                                            disabled={disabled}
                                        />
                                    </div>
                                </div>
                                <div />
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {FormItem?.InvoicedAmount}
                                </div>
                                <div />
                                <div className={classes['StockReceptionTable__cell--input']}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="UnitAcquisitionPrice"
                                        disabled={disabled}
                                        inputProps={{style: {paddingRight: '40px'}}}
                                        rightAdornment="RON"
                                    />
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {FormItem?.AcquisitionTotalPrice}RON
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {completeItem.AcquisitionTotalVat ? completeItem.AcquisitionTotalVat + 'RON' : ''}
                                </div>
                                <div />
                                <div className={classes['StockReceptionTable__cell--text']}>
                                    <FormSelect
                                        disabled={disabled}
                                        name={`Items.${ItemID}.VATRateSale`}
                                        options={VAT_OPTIONS}
                                        placeholder="%"
                                        rules={{
                                            required: {
                                                message: 'Câmp obligatoriu',
                                                value: true,
                                            },
                                        }}
                                    />
                                </div>
                                <div />
                                <div />
                                <div />
                            </>
                        ) : (
                            <>
                                <div className={classes['StockReceptionTable__stickyLeftColumn']}>
                                    <div className={clsx(classes['StockReceptionTable__row__index'], classes['StockReceptionTable__cell--text'])}>{index + 1}</div>
                                    <div className={classes['StockReceptionTable__cell--text']}>
                                        {Product?.ID ? (
                                            <Tooltip>
                                                <TooltipTrigger
                                                    className={classes['StockReceptionTable__row__productName']}
                                                    onClick={() => setEditOrCreatedProductModal({create: false, productID: Product.ID})}
                                                >
                                                    <Typography variant="pLarge">{Product.Name}</Typography>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <Typography>{Product.Name}</Typography>
                                                </TooltipContent>
                                            </Tooltip>
                                        ) : (
                                            FormItem?.ProductCode ? (
                                                <Button
                                                    size="small"
                                                    style={{backgroundColor: '#FFFFFF'}}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setEditOrCreatedProductModal({
                                                            create: true,
                                                            productID: RTDB.newKey([...P_ProductData_Source_Shop, ShopID, 'Product']),
                                                            itemID: ItemID,
                                                            code: FormItem?.ProductCode,
                                                        });
                                                    }}
                                                >Adaugă produs nou</Button>
                                            ) : (
                                                null
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className={classes['StockReceptionTable__cell--text']}>
                                    {Product?.Code?.Value || FormItem?.ProductCode || ''}
                                </div>
                                <div className={clsx(classes['StockReceptionTable__cell--input'], classes['StockReceptionTable__cell--number'])}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="InvoicedAmountExpression"
                                        disabled={disabled}
                                        helper={FormItem?.InvoicedAmountExpression && BigNumber(FormItem?.InvoicedAmountExpression).toString() !== FormItem?.InvoicedAmount ? FormItem?.InvoicedAmount + unitName : undefined}
                                        inputProps={{style: {paddingRight: unitName ? '32px' : undefined}}}
                                        rightAdornment={unitName}
                                    />
                                </div>
                                <div className={clsx(classes['StockReceptionTable__cell--input'], classes['StockReceptionTable__cell--number'])}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="ReceivedAmountExpression"
                                        color={FormItem?.InvoicedAmount === FormItem?.ReceivedAmount ? undefined : 'warning'}
                                        disabled={disabled}
                                        helper={FormItem?.ReceivedAmountExpression && BigNumber(FormItem?.ReceivedAmountExpression).toString() !== FormItem?.ReceivedAmount ? FormItem?.ReceivedAmount + unitName : undefined}
                                        inputProps={{style: {paddingRight: unitName ? '32px' : undefined}}}
                                        rightAdornment={unitName}
                                    />
                                </div>
                                <div className={clsx(classes['StockReceptionTable__cell--input'], classes['StockReceptionTable__cell--number'])}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="UnitAcquisitionPrice"
                                        disabled={disabled}
                                        inputProps={{style: {paddingRight: '40px'}}}
                                        rightAdornment="RON"
                                    />
                                </div>
                                <div className={clsx(classes['StockReceptionTable__cell--input'], classes['StockReceptionTable__cell--number'])}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="AcquisitionTotalPrice"
                                        disabled={disabled}
                                        inputProps={{style: {paddingRight: '40px'}}}
                                        rightAdornment="RON"
                                    />
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {completeItem.AcquisitionTotalVat ? completeItem.AcquisitionTotalVat + 'RON' : ''}
                                </div>
                                <div className={clsx(classes['StockReceptionTable__cell--input'], classes['StockReceptionTable__cell--number'])}>
                                    <EditableCell
                                        {...EditableCellSharedProps}
                                        FieldName="UnitSalePrice"
                                        disabled={disabled}
                                        inputProps={{style: {paddingRight: '40px'}}}
                                        rightAdornment="RON"
                                    />
                                </div>
                                <div className={classes['StockReceptionTable__cell--text']}>
                                    <FormSelect
                                        disabled={disabled}
                                        name={`Items.${ItemID}.VATRateSale`}
                                        options={VAT_OPTIONS}
                                        placeholder="%"
                                        rules={{
                                            required: {
                                                message: 'Câmp obligatoriu',
                                                value: true,
                                            },
                                        }}
                                    />
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {completeItem.unitPercentGrossProfit ? (
                                        BigNumber(completeItem.unitPercentGrossProfit).multipliedBy('100').toFixed(0) + '%'
                                    ) : ''}
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {completeItem.TotalSaleWithoutVat ? (
                                        completeItem.TotalSaleWithoutVat + 'RON'
                                    ) : ''}
                                </div>
                                <div className={classes['StockReceptionTable__cell--number']}>
                                    {completeItem.TotalSaleVat ? (
                                        completeItem.TotalSaleVat + 'RON'
                                    ) : ''}
                                </div>
                                {/* <div className={classes['StockReceptionTable__cell--text']}>
                                    {FormItem?.Remarks || ''}
                                </div> */}
                            </>
                        )}
                        <div className={classes['StockReceptionTable__stickyRightColumn']}>
                            <div className={classes['StockReceptionTable__cell--text']}>
                                <Popconfirm
                                    cancelText="Anulează"
                                    description="Ești sigur că vrei să elimini acest produs din recepție?"
                                    icon={<FontAwesomeIcon fixedWidth icon={faTrash} style={{marginRight: '4px'}} />}
                                    okButtonProps={{danger: true}}
                                    okText="Elimină"
                                    title="Elimină produsul din recepție"
                                    onConfirm={async () => {
                                        await RTDB.set([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items', ItemID], null);

                                        await new Promise(() => {}); // wait until the element gets removed
                                    }}
                                >
                                    <Button
                                        disabled={disabled}
                                        size="small"
                                        special="red"
                                        variant="outlined"
                                    >
                                        <FontAwesomeIcon icon={faTrash} size="1x" />
                                    </Button>
                                </Popconfirm>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {Object.entries(Product?.RequiresPurchaseOf || {}).map(([RequiredProductID, multiplier]) => {
                return (
                    <div key={RequiredProductID} className={classes['StockReceptionTable__row']}>
                        <div className={classes['StockReceptionTable__stickyLeftColumn']}>
                            <div className={clsx(classes['StockReceptionTable__row__index'], classes['StockReceptionTable__cell--text'])} />
                            <div className={classes['StockReceptionTable__cell--text']}>
                                <div className={classes['StockReceptionTable__row__productName']}>
                                    <Typography variant="pLarge">
                                        <ProductName ProductID={RequiredProductID} />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div />
                        <div className={classes['StockReceptionTable__cell--number']}>
                            {BigNumber(FormItem?.InvoicedAmount).multipliedBy(multiplier).toString()}buc
                        </div>
                        <div className={classes['StockReceptionTable__cell--number']}>
                            {BigNumber(FormItem?.InvoicedAmount).multipliedBy(multiplier).toString()}buc
                        </div>
                        <div className={classes['StockReceptionTable__cell--number']}>
                            0.50RON
                        </div>
                        <div className={classes['StockReceptionTable__cell--number']}>
                            {BigNumber(FormItem?.InvoicedAmount).multipliedBy(multiplier).multipliedBy('0.5').toFixed(2)}RON
                        </div>
                        <div />
                        <div />
                        <div className={classes['StockReceptionTable__cell--text']}>
                            <FormSelect
                                disabled
                                defaultValue="0"
                                name={`SGR${RequiredProductID}VATRateSale`}
                                options={VAT_OPTIONS}
                                placeholder="%"
                            />
                        </div>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                );
            })}
            <EditOrCreateProductModal
                ProductID={editOrCreatedProductModal?.productID}
                ShopID={ShopID}
                create={editOrCreatedProductModal?.create}
                defaultValues={{Code: editOrCreatedProductModal?.code}}
                images={RTDBItem?.Images}
                onUpdate={setEditOrCreatedProductModal}
            />
        </LastUpdatedInputsContext.Provider>
    );
}

export default function StockReceptionRowContainer(props: StockReceptionRowProps) {
    const FormItem = useWatch<FormInputs>({name: `Items.${props.ItemID}`}) as FormInputs['Items'][string];

    const RTDBItem = useRTDBSubscription([...P_StockReception_Source_Shop, props.ShopID, 'Reception', props.ReceptionID, 'Items', props.ItemID]);
    const Product = useProductData({ProductID: RTDBItem?.ProductID, ProductCode: RTDBItem?.ProductCode});

    const rowProps = {
        ...props,
        FormItem: structuredClone(FormItem),
    };
    const content = useDeepCompareMemo(() => (
        <StockReceptionRow {...rowProps} />
    ), [rowProps]);

    if (Product === undefined) {
        return (
            <div className={clsx(classes['StockReceptionTable__row'], classes['StockReceptionTable__row--loading'])}>
                <div className={classes['StockReceptionTable__stickyLeftColumn']}>
                    <div>
                        <Spinner size="small" />
                    </div>
                </div>
            </div>
        );
    }
    else {
        return content;
    }
}
