import {P_StockReception_Source_Shop} from '@vantix/rtdb-rules/default';
import {Modal} from 'antd';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';

import {getProductDataViaCode} from '@/apps/checkout-station/views/CashierCheckout/Themes/Vistrum/Numpad';
import Button from '@/components/Button';
import Input from '@/components/Input';
import Typography from '@/components/Typography';
import {useProductData} from '@/utils/indexeddb';
import {RTDB, useRTDBSubscription} from 'project-rtdb';

interface FormInputs {
    Code: string,
}

export default function AddReceptionItemModal({ReceptionID, ShopID, open, onClose}: {ReceptionID?: string; ShopID: string; open: boolean; onClose: (newItemID?: string) => void}) {
    const Reception = useRTDBSubscription([...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID]);

    const {register, watch, handleSubmit, reset, setError, clearErrors, formState: {errors, isSubmitting}} = useForm<FormInputs>();

    const writeBuffer = watch('Code');
    const writeBufferProduct = useProductData({ProductCode: writeBuffer});

    useEffect(() => {
        const timeout = setTimeout(() => {
            reset();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [open]);

    const onSubmit = useCallback(async (data: FormInputs) => {
        if (!open) {
            return;
        }

        clearErrors('Code');

        const Product = await getProductDataViaCode(data.Code);

        if (!Product?.ID) {
            setError('Code', {
                message: 'Produs negăsit',
            });

            return;
        }
        if (Object.values(Reception?.Items || {}).some(Item => Item?.ProductID === Product.ID)) {
            setError('Code', {
                message: 'Produsul face parte din recepție deja',
            });

            return;
        }

        const basePath = [...P_StockReception_Source_Shop, ShopID, 'Reception', ReceptionID, 'Items'] as const;
        const newID = RTDB.newKey(basePath);
        await RTDB.set([...basePath, newID], {
            ID: newID,
            ProductID: Product.ID,
        });

        onClose(newID);
    }, [ShopID, ReceptionID, Reception, onClose, open]);

    return (
        <Modal
            destroyOnClose
            footer={null}
            open={open}
            width={600}
            onCancel={() => onClose()}
            onClose={() => onClose()}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h6">
                    Adaugă produs în recepție
                </Typography>

                <Input
                    autoComplete='off'
                    error={errors?.Code?.message}
                    helper={!writeBuffer ? '' : (
                        <Typography color="primary" variant="h6" weight="bold">{writeBufferProduct?.Name || 'Produs negăsit'}</Typography>
                    )}
                    label="Cod produs"
                    placeholder="5 942105 007124"
                    style={{
                        color: writeBuffer ? undefined : 'rgba(0, 0, 0, 0.4)',
                    }}
                    {...register('Code', {
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                    })}
                />

                <Button
                    disabled={isSubmitting}
                    size="large"
                    style={{margin: '12px auto'}}
                    variant="primary"
                >Adaugă produs în recepție</Button>
            </form>
        </Modal>
    );
}
