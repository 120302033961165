import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import React, {ForwardedRef, ReactHTML} from 'react';

import Typography from '../Typography';

import classes from './index.module.scss';

export type CheckboxProps = Parameters<ReactHTML['input']>[0] & {
    label?: React.ReactNode,
    error?: string,
};

function Checkbox({
    id,
    label,
    error,
    className,
    style,
    ...rest
}: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) {
    return (
        <div className={clsx(classes['Checkbox'], className)} style={style}>
            <Typography
                color="primary"
                element="label"
                htmlFor={id}
                variant="pMedium"
            >
                <input
                    {...rest}
                    ref={ref}
                    id={id}
                    type="checkbox"
                />
                <div className={classes['Checkbox__checkmark']}>
                    <FontAwesomeIcon icon={faCheck} />
                </div>
                <span>{label}</span>
            </Typography>
            <AnimatePresence initial={false}>
                {error ? (
                    <Typography
                        animate={{height: 'auto'}}
                        color="error"
                        element={motion.div}
                        exit={{height: 0}}
                        initial={{height: 0}}
                        style={{overflow: 'hidden', marginTop: '4px'}}
                        transition={{duration: 0.1}}
                        variant="pSmall"
                    >{error}</Typography>
                ) : null}
            </AnimatePresence>
        </div>
    );
}

export default React.memo(React.forwardRef<HTMLInputElement, InputProps>(Checkbox));
