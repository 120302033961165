import {faPrint} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popconfirm} from 'antd';
import BigNumber from 'bignumber.js';
import {useCallback, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import Button from '@/components/Button';
import FormSelect from '@/components/FormSelect';
import Input from '@/components/Input';
import Spinner from '@/components/Spinner';
import Typography from '@/components/Typography';
import {useProductData} from '@/utils/indexeddb';
import store from 'project-store';

interface FormInputs {
    quantity: string,
    popoverConfirmed: boolean,
    printer: 'zebra' | 'epson',
}

const printerOptions = [
    {
        label: 'Etichete autoadezive (Zebra)',
        value: 'zebra',
    },
    {
        label: 'Etichete raft (Epson)',
        value: 'epson',
    },
];

export default function ProductLabelPrinter({ProductID, ShopID, onClose}: {ProductID: string; ShopID: string; onClose: () => void}) {
    const Product = useProductData({ProductID});

    const form = useForm<FormInputs>({
        defaultValues: {
            quantity: '1',
            popoverConfirmed: false,
        },
    });
    const {register, handleSubmit, watch, setValue, formState: {errors, isSubmitting}} = form;

    const quantity = watch('quantity');
    const quantityNumber = BigNumber(quantity).isPositive() ? BigNumber(quantity).toNumber() : 0;

    const printerType = watch('printer');


    const [popover, setPopover] = useState(false);
    const onSubmit = useCallback(async (data: FormInputs) => {
        if (!data.printer) {
            return;
        }

        if (BigNumber(data.quantity).isGreaterThan('20') && !data.popoverConfirmed) {
            setPopover(true);

            return;
        }

        if (data.printer === 'zebra') {
            store.dispatch.generics.update({
                labelsToPrint: {
                    [ProductID]: {
                        Amount: BigNumber(data.quantity).toNumber(),
                    },
                },
            });
        }
        else {
            // todo
        }

        onClose();
    }, [ProductID, ShopID, popover, onClose]);

    return (
        <FormProvider {...form}><form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h5">Imprimă etichete</Typography>
            <Typography
                color="secondary"
                style={{margin: '16px 0 8px'}}
                variant="h6"
            >Produs: <Typography color="primary" display="inline">{Product?.Name || ''}</Typography></Typography>
            <div style={{marginBottom: '8px'}}>
                <Typography
                    color="secondary"
                    style={{marginBottom: '4px'}}
                    variant="pMedium"
                    weight="medium"
                >Imprimantă</Typography>
                <FormSelect
                    defaultValue="zebra"
                    name="printer"
                    options={printerOptions}
                    placeholder="Tip etichetă"
                    rules={{
                        required: {
                            message: 'Câmp obligatoriu',
                            value: true,
                        },
                    }}
                />
            </div>
            <Input
                autoComplete="off"
                error={errors?.quantity?.message}
                errorProps={{
                    variant: 'pLarge',
                }}
                label="Număr etichete"
                placeholder="42"
                {...register('quantity', {
                    required: {
                        message: 'Câmp obligatoriu',
                        value: true,
                    },
                    pattern: {
                        message: 'Număr invalid',
                        value: /^\s*\d+\s*$/,
                    },
                })}
            />
            <br />
            <Popconfirm
                cancelText="Anulează"
                description={`Ești sigur că vrei să imprimi ${quantityNumber} etichete?`}
                icon={<FontAwesomeIcon fixedWidth icon={faPrint} style={{marginRight: '4px'}} />}
                okText="Imprimă"
                open={popover}
                title="Imprimă etichete"
                onCancel={() => setPopover(false)}
                onConfirm={() => {
                    setValue('popoverConfirmed', true);
                    setPopover(false);

                    handleSubmit(onSubmit)();
                }}
            >
                <Button
                    disabled={isSubmitting || printerType === 'epson'}
                    size="large"
                    type="submit"
                    variant="primary"
                >{isSubmitting ? (
                    <Spinner size="small" />
                ) : (
                    <>Imprimă {quantityNumber} {quantityNumber === 1 ? 'etichetă' : 'etichete'}</>
                )}</Button>
            </Popconfirm>
        </form></FormProvider>
    );
}
