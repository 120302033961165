/* eslint-disable react/prop-types */
import {TD, TH, TR, Table} from '@ag-media/react-pdf-table';
import {Document, Page, Text, View} from '@react-pdf/renderer';
import {T_DB_ShopProduct, T_DB_StockReception, T_DB_Supplier} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {format} from 'date-fns';
import React from 'react';

import {PDFProps, T_PDFDeclaration} from '../..';
import {calculateUnitGrossProfit} from '../../../products';
import {calculateCompleteStockReceptionItem} from '../../../stockReceptions';
import {capitalize} from '../../../stringTools';
import {styles} from '../common';

export interface NIRData {
    Reception: T_DB_StockReception,
    Suppliers: Record<string, T_DB_Supplier>,
    Products: Record<string, T_DB_ShopProduct>,
    operatorName: string,
    comments?: string,
    companyInfo?: string,
}

export const NIR: T_PDFDeclaration<NIRData> = {
    PDF: ({data: {Reception, Products, Suppliers, comments, operatorName, companyInfo}, t}: PDFProps<NIRData>) => {
        const SGRAmount = {
            Invoiced: BigNumber('0'),
            Received: BigNumber('0'),
        };
        const SGR_PRODUCT_ID = '2l6yfqcAhM4PnkjhKyFUhY'; // todo: handle this better

        const rows = (
            Object.values(Reception?.Items || {})
                .filter(Item => Item?.ID)
                .map((Item, index) => {
                    const Product = Products?.[Item.ProductID];

                    const completeItem = calculateCompleteStockReceptionItem(Item);

                    SGRAmount.Invoiced = SGRAmount.Invoiced.plus(BigNumber(Product?.RequiresPurchaseOf?.[SGR_PRODUCT_ID] || '0').multipliedBy(completeItem.InvoicedAmount));
                    SGRAmount.Received = SGRAmount.Received.plus(BigNumber(Product?.RequiresPurchaseOf?.[SGR_PRODUCT_ID] || '0').multipliedBy(completeItem.ReceivedAmount));

                    const ValoareReceptie = BigNumber(completeItem.ReceivedAmount).multipliedBy(completeItem.UnitAcquisitionPrice);
                    const ValoareVanzareTotala = BigNumber(completeItem.UnitSalePriceWithVat).multipliedBy(completeItem.ReceivedAmount);

                    const output = {
                        NrCrt: index + 1,
                        NumeProdus: completeItem.Name || Product?.Name || '',
                        UM: t(`unit/${Product?.Unit || 'pcs'}`),

                        CantitateDoc: completeItem.InvoicedAmount,
                        PretAchizitie: completeItem.UnitAcquisitionPrice ? BigNumber(completeItem.UnitAcquisitionPrice).toFixed(2) : '',
                        ValoareAchizitie: completeItem.AcquisitionTotalPrice,
                        TVAAchizitie: completeItem.VATRateAcquisition ? `${completeItem.VATRateAcquisition}%` : '',
                        TVATotalAchizitie: completeItem.AcquisitionTotalVat,

                        CantitateFizica: completeItem.ReceivedAmount,
                        ValoareReceptie: ValoareReceptie.isNaN() ? '' : ValoareReceptie.toFixed(2),

                        Adaos: completeItem.IsDiscount || !completeItem.unitPercentGrossProfit ? '' : BigNumber(completeItem.unitPercentGrossProfit).multipliedBy('100').toFixed(2) + '%',
                        AdaosUnitar: completeItem.IsDiscount || !completeItem.unitPercentGrossProfit ? '' : calculateUnitGrossProfit({
                            UnitSalePrice: completeItem.UnitSalePriceWithVat,
                            VATRateAcquisition: completeItem.VATRateAcquisition,
                            UnitAcquisitionPrice: completeItem.UnitAcquisitionPrice,
                        }).toFixed(2),

                        TVAVanzare: completeItem.IsDiscount || !completeItem.VATRateSale ? '' : `${completeItem.VATRateSale}%`,
                        TVAUnitarVanzare: completeItem.IsDiscount ? '' : completeItem.UnitSaleVat,
                        TVATotalVanzare: completeItem.IsDiscount ? '' : completeItem.TotalSaleVat,

                        ValoareVanzareUnitara: completeItem.IsDiscount ? '' : completeItem.UnitSalePriceWithVat,
                        ValoareVanzareTotala: completeItem.IsDiscount || ValoareVanzareTotala.isNaN() ? '' : ValoareVanzareTotala.toFixed(2),
                    };

                    return output;
                })
        ).filter(Boolean);

        // todo: maybe we could do this for all items?
        if (SGRAmount.Invoiced.isGreaterThan('0') || SGRAmount.Received.isGreaterThan('0')) {
            const Product = Products?.[SGR_PRODUCT_ID];

            rows.push({
                NrCrt: rows.length + 1,
                NumeProdus: Product.Name,
                UM: t(`unit/${Product.Unit}`),

                CantitateDoc: SGRAmount.Invoiced.toString(),
                PretAchizitie: BigNumber('0.5').toFixed(2),
                ValoareAchizitie: BigNumber('0.5').multipliedBy(SGRAmount.Invoiced).toFixed(2),
                TVAAchizitie: '0%',
                TVATotalAchizitie: '0.00',

                CantitateFizica: SGRAmount.Received.toString(),
                ValoareReceptie: BigNumber(SGRAmount.Received).multipliedBy('0.5').toFixed(2),

                Adaos: '0%',
                AdaosUnitar: '0.00',

                TVAVanzare: '0%',
                TVAUnitarVanzare: '0.00',
                TVATotalVanzare: '0.00',

                ValoareVanzareUnitara: BigNumber('0.5').toFixed(2),
                ValoareVanzareTotala: BigNumber('0.5').multipliedBy(SGRAmount.Received).toFixed(2),
            });
        }

        for (const [index, row] of rows.entries()) {
            row.NrCrt = index + 1;
        }

        /* eslint-disable unicorn/no-array-reduce */
        const totals = {
            Cantitate: rows.map(row => row.CantitateDoc).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toString(),
            ValoareAchizitie: rows.map(row => row.ValoareAchizitie).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toFixed(2),
            TVATotalAchizitie: rows.map(row => row.TVATotalAchizitie).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toFixed(2),
            CantitateFizica: rows.map(row => row.CantitateFizica).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toString(),
            ValoareAchizitieReceptie: rows.map(row => row.ValoareReceptie).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toFixed(2),
            TVATotalVanzare: rows.map(row => row.TVATotalVanzare).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toFixed(2),
            ValoareVanzareTotala: rows.map(row => row.ValoareVanzareTotala).filter(e => !BigNumber(e).isNaN()).reduce((acc, val) => acc.plus(val), BigNumber('0')).toFixed(2),
        };
        /* eslint-enable unicorn/no-array-reduce */


        const weightings = [
            0.02,
            0,
            0.025,

            0.045,
            0.035,
            0.04,
            0.025,
            0.04,

            0.05,
            0.05,

            0.045,
            0.04,

            0.025,
            0.035,
            0.043,

            0.04,
            0.045,
        ];
        weightings[1] = Math.round((1 - weightings.reduce((acc, val) => acc + val)) * 100) / 100;

        const borderStyle = '1px solid #454545';

        const tableHeader = (
            <>
                <TH style={{lineHeight: 1.4}}>
                    <TD style={{padding: '0 2px'}} weighting={weightings[0]} />
                    <TD style={{padding: '0 0px'}} weighting={weightings[1]} />
                    <TD style={{padding: '0 1.75px'}} weighting={weightings[2]} />

                    <TD style={{justifyContent: 'center', textAlign: 'center', padding: '2px 11px'}} weighting={weightings.slice(3, 8).reduce((acc, val) => acc + val)}><Text hyphenationCallback={(e) => [e]}>Valori Achiziție Documente</Text></TD>
                    <TD style={{justifyContent: 'center', textAlign: 'center', padding: '2px 4px'}} weighting={weightings.slice(8, 10).reduce((acc, val) => acc + val)}><Text hyphenationCallback={(e) => [e]}>Valori Achiziție Recepție</Text></TD>
                    <TD style={{justifyContent: 'center', textAlign: 'center', padding: '2px 4px'}} weighting={weightings.slice(10, 12).reduce((acc, val) => acc + val)}><Text hyphenationCallback={(e) => [e]}>Adaos Comercial</Text></TD>
                    <TD style={{justifyContent: 'center', textAlign: 'center', padding: '2px 6px 2px 6.75px'}} weighting={weightings.slice(12, 15).reduce((acc, val) => acc + val)}><Text hyphenationCallback={(e) => [e]}>TVA Vânzare</Text></TD>
                    <TD style={{justifyContent: 'center', textAlign: 'center', padding: '2px 4px'}} weighting={weightings.slice(15, 17).reduce((acc, val) => acc + val)}><Text hyphenationCallback={(e) => [e]}>Valoare Vânz. Inclusiv TVA</Text></TD>
                </TH>
                <TH style={{lineHeight: 1.4}}>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'left'}}><Text hyphenationCallback={(e) => [e]}>Nr. Crt.</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'flex-start', textAlign: 'left'}}><Text hyphenationCallback={(e) => [e]}>Denumire produs</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>UM</Text></TD>

                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Cantitate Doc.</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Preț Unitar</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Valoare Ach.</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>TVA</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>TVA Total</Text></TD>

                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Cantitate Fizică</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Valoare Achiziție</Text></TD>

                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Adaos</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Adaos Unitar</Text></TD>

                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>TVA</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>TVA Unitar</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>TVA Total</Text></TD>

                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Val. Unitară</Text></TD>
                    <TD style={{padding: '2px 2px', justifyContent: 'center', textAlign: 'center'}}><Text hyphenationCallback={(e) => [e]}>Val. Totală</Text></TD>
                </TH>
            </>
        );
        const tableFooter = (
            <TH style={{fontWeight: 'medium'}}>
                <TD
                    style={{
                        justifyContent: 'flex-end',
                        textAlign: 'right',
                        padding: '4px 4px 4px 17px',
                        border: 0,
                    }}
                    weighting={weightings.slice(0, 3).reduce((acc, val) => acc + val)}
                ><Text hyphenationCallback={(e) => [e]} style={{textTransform: 'uppercase'}}>Total general</Text></TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[3]}>{totals.Cantitate}</TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[4]} />
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[5]}>{totals.ValoareAchizitie}</TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[6]} />
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[7]}>{totals.TVATotalAchizitie}</TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[8]}>{totals.CantitateFizica}</TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[9]}>{totals.ValoareAchizitieReceptie}</TD>

                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[10]} />
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[11]} />

                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[12]} />
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[13]} />

                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[14]}>{totals.TVATotalVanzare}</TD>
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[15]} />
                <TD style={{border: 0, padding: '2px 3.5px'}} weighting={weightings[16]}>{totals.ValoareVanzareTotala}</TD>
            </TH>
        );
        const endFooter = (
            <>
                <View
                    style={{
                        marginTop: '12pt',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        fontWeight: 'medium',
                    }}
                >
                    <Text>Comisie Recepție:</Text>
                    <Text>Primit în Gestiune:</Text>
                    <Text>Data:</Text>
                    <Text />
                </View>
                {comments ? <Text style={{marginTop: '8pt'}}>Observații: {comments}.</Text> : null}
            </>
        );
        const TableRowRenderer = ({row}: {row: typeof rows[number]}) => (
            <TR style={{fontSize: '6.5pt'}}>
                <TD style={{justifyContent: 'flex-end', padding: '0 5px 0 2px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: borderStyle}}>{row.NrCrt}</TD>
                <TD style={{justifyContent: 'flex-start', textAlign: 'left', padding: '2px 4px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.NumeProdus}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{capitalize(row.UM)}</TD>

                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.CantitateDoc}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.PretAchizitie}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.ValoareAchizitie}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.TVAAchizitie}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.TVATotalAchizitie}</TD>

                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.CantitateFizica}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.ValoareReceptie}</TD>

                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.Adaos}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.AdaosUnitar}</TD>

                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.TVAVanzare}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.TVAUnitarVanzare}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.TVATotalVanzare}</TD>

                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: 0, borderBottom: borderStyle, borderLeft: 0}}>{row.ValoareVanzareUnitara}</TD>
                <TD style={{padding: '0 3.5px', borderTop: 0, borderRight: borderStyle, borderBottom: borderStyle, borderLeft: 0}}>{row.ValoareVanzareTotala}</TD>
            </TR>
        );

        const Supplier = Suppliers?.[Reception?.SupplierID];

        const pageSizes = {
            one: 30,
            moreThanOne: 37,
        };
        const rowHeights = rows.map(row => Math.ceil(row.NumeProdus.length / 52));
        const rowHeightsSum = rowHeights.reduce((acc, val) => acc + val, 0);

        const isMoreThanOnePage = rowHeightsSum > pageSizes.one;
        const pageSize = isMoreThanOnePage ? pageSizes.moreThanOne : pageSizes.one;

        const rowPages: (typeof rows)[] = [[]];

        let pageCapacity = isMoreThanOnePage ? pageSize - 5 : pageSize;
        for (const [i, row] of rows.entries()) {
            const rowHeight = rowHeights[i];

            if (pageCapacity - rowHeight <= 0) {
                rowPages.push([]);
                pageCapacity = pageSize;
            }

            pageCapacity -= rowHeight;
            rowPages.at(-1).push(row);
        }

        const formattedNIRDate = format(Reception.NIRDate || Date.now(), 'dd.MM.yyyy');

        const PageFooterRenderer = ({pageNumber}: {pageNumber: number}) => (
            <View style={{marginTop: 'auto', borderTop: '1px solid #757575'}}>
                <View style={{display: 'flex', flexDirection: 'row', marginTop: '2pt', width: '100%'}}>
                    <Text style={{fontWeight: 'bold', fontSize: '8pt'}}>NOTA DE INTRARE RECEPTIE SI CONSTATARE DIFERENTE nr. {Reception.NIRID || 'schiță'} din: {formattedNIRDate} la factura {Supplier?.Name} nr. {Reception.SupplierInvoiceNumber}{Reception.SupplierInvoiceDate ? ` din ${Reception.SupplierInvoiceDate}` : ''}</Text>
                    <Text style={{marginLeft: 'auto'}}>Pag. {pageNumber}/{rowPages.length}</Text>
                </View>
                <Text style={{marginTop: '2pt'}}>{companyInfo}</Text>
                <Text style={{marginTop: '2pt', fontSize: '6pt'}}>Tipărit{operatorName ? ` de ${operatorName}` : ''} la {format(Date.now(), 'dd.MM.yyyy HH:mm:ss')}</Text>
            </View>
        );

        return (
            <Document>
                <Page
                    orientation="landscape"
                    size="A4"
                    style={{
                        ...styles.page,
                        paddingVertical: '1.25cm',
                        paddingHorizontal: '1cm',
                    }}
                >
                    <View style={{fontSize: '7pt', display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: '12pt',
                            }}
                        >NOTA DE INTRARE RECEPTIE SI CONSTATARE DIFERENTE nr. {Reception.NIRID || 'schiță'} din: {formattedNIRDate}</Text>
                        <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: '12pt',
                                marginBottom: '20pt',
                            }}
                        >Factura {Supplier?.Name} nr. {Reception.SupplierInvoiceNumber}{Reception.SupplierInvoiceDate ? ` din ${Reception.SupplierInvoiceDate}` : ''}</Text>
                        <Text style={{marginBottom: '4pt'}}>
                            Subsemnatii, membri ai comisiei de receptie, am procedat la receptionarea valorilor materiale
                            furnizate de: {Supplier?.Name}, constatandu-se urmatoarele:
                        </Text>
                        {rowPages.length === 1 ? (
                            <>
                                <Table
                                    tdStyle={{
                                        justifyContent: 'flex-end',
                                    }}
                                    weightings={weightings}
                                >
                                    {tableHeader}
                                    {rowPages[0].map((row, index) => (
                                        <TableRowRenderer
                                            key={index}
                                            row={row}
                                        />
                                    ))}
                                    {tableFooter}
                                </Table>
                                {endFooter}
                            </>
                        ) : (
                            <Table
                                tdStyle={{
                                    justifyContent: 'flex-end',
                                }}
                                weightings={weightings}
                            >
                                {tableHeader}
                                {rowPages[0].map((row, index) => (
                                    <TableRowRenderer
                                        key={index}
                                        row={row}
                                    />
                                ))}
                            </Table>
                        )}
                        <PageFooterRenderer pageNumber={1} />
                    </View>
                </Page>
                {rowPages.slice(1).length > 0 ? (
                    rowPages.slice(1).map((rowPage, index) => (
                        <Page
                            key={index}
                            orientation="landscape"
                            size="A4"
                            style={{
                                ...styles.page,
                                paddingVertical: '1.25cm',
                                paddingHorizontal: '1cm',
                            }}
                        >
                            <View style={{fontSize: '7pt', display: 'flex', flexDirection: 'column', height: '100%'}}>
                                <Table
                                    tdStyle={{
                                        justifyContent: 'flex-end',
                                    }}
                                    weightings={weightings}
                                >
                                    {tableHeader}
                                    {rowPage.map((row, index) => (
                                        <TableRowRenderer
                                            key={index}
                                            row={row}
                                        />
                                    ))}
                                    {index === (rowPages.length - 2) ? tableFooter : null}
                                </Table>
                                {index === (rowPages.length - 2) ? endFooter : null}
                                <PageFooterRenderer pageNumber={index + 2} />
                            </View>
                        </Page>
                    ))
                ) : null}
            </Document>
        );
    },
};
