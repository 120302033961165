import {callable} from '@agmedia/firebase-browser-helpers';
import {Modal} from 'antd';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {RTDB, useRTDBSubscription} from 'project-rtdb';

import Button from '../../../components/Button';
import RoCompanyName from '../../../components/RoCompanyName';
import Typography from '../../../components/Typography';
import {WatchPath} from '../../../utils/Routing';

import classes from './index.module.scss';

function CompanyListItem({CompanyID}: {CompanyID: T_DB_CompanyID}) {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const userID = useSelector(state => state.user?.ID);
    const OrganizationIDs = useRTDBSubscription([...P_Zoho_Users, userID, 'Organizations']);
    const ExistingLinks = useSelector(() => RTDB.store.selectPath([...P_Zoho_AnafLink_ByOrganization]));
    const OrganizationIDLinkedToCompanyID = useMemo(() => Object.entries(ExistingLinks || {}).find(([, cID]) => cID === CompanyID)?.[0], [CompanyID, ExistingLinks]);
    const LinkedOrganizationName = useRTDBSubscription([...P_Zoho_Organizations, OrganizationIDLinkedToCompanyID, 'Name']);

    return (
        <>
            {[
                ...Object.keys(OrganizationIDs?.Invoice || {}),
                ...Object.keys(OrganizationIDs?.Books || {}),
            ].map(OrganizationID => (
                <WatchPath key={OrganizationID} path={[...P_Zoho_AnafLink_ByOrganization, OrganizationID]} />
            ))}
            <li className={classes['Companies__listItem']}>
                <div>
                    <Typography color="primary" weight="medium">RO{CompanyID}</Typography>
                    <Typography color="secondary"><RoCompanyName CompanyID={CompanyID} /></Typography>
                </div>
                <Button
                    size="small"
                    special="red"
                    style={{display: 'inline-block'}}
                    variant="outlined"
                    onClick={() => setModalOpen(true)}
                >Șterge autorizarea</Button>
            </li>
            <Modal
                cancelText="Anulează"
                confirmLoading={loading}
                okText="Șterge asocierea"
                okType="danger"
                open={modalOpen}
                title="Șterge asocierea"
                onCancel={() => setModalOpen(false)}
                onOk={async () => {
                    if (loading) {
                        return;
                    }

                    setLoading(true);
                    try {
                        await callable.europe_west1.removeANAFRights({
                            CompanyID,
                        });
                    }
                    catch {}
                    setLoading(false);
                }}
            >
                <Typography>Ești sigur că vrei să ștergi autorizarea ANAF eFactura pentru <Typography display="inline" weight="medium">RO{CompanyID} - <RoCompanyName CompanyID={CompanyID} /></Typography>?</Typography>
                {LinkedOrganizationName ? (
                    <>
                        <br />
                        <Typography color="error">Organizația Zoho <b>{LinkedOrganizationName}</b> folosește această autorizare și nu va mai putea trimite sau primi facturi prin zFactura!</Typography>
                    </>
                ) : null}
            </Modal>
        </>
    );
}

export default function CompaniesSettings() {
    const userID = useSelector(state => state.user?.ID);

    const AvailableCompanies = useRTDBSubscription([...P_RBAC_Dynamic_ByUser, userID, 'CompanyAccess']);

    return (
        <>
            <Typography variant="h6" weight="medium">Societăți accesibile</Typography>
            {Object.keys(AvailableCompanies || {}).map(CompanyID => (
                <WatchPath key={CompanyID} path={[...P_ANAF_Companies, CompanyID]} />
            ))}
            {Object.keys(AvailableCompanies || {}).length > 0 ? (
                <>
                    <Typography color="secondary" variant="pMedium">Aici poți vedea lista de companii în numele cărora ai dreptul să trimiți documente. Pentru a obține acces la alte societăți trebuie să <Link to="/setari-cont/anaf-auth">autorizezi accesul</Link> la ANAF SPV.</Typography>
                    <br />
                    <ul className={classes['Companies__list']}>
                        {Object.keys(AvailableCompanies || {}).map(CompanyID => (
                            <CompanyListItem
                                key={CompanyID}
                                CompanyID={CompanyID}
                            />
                        ))}
                    </ul>
                </>
            ) : (
                <Typography color="primary" style={{marginTop: '16px'}} variant="pLarge">Aici vei vedea lista de companii în numele cărora ai dreptul să trimiți documente. Pentru a obține acces trebuie să <Link to="/setari-cont/anaf-auth">autorizezi accesul</Link> la ANAF SPV.</Typography>
            )}
        </>
    );
}
