import {faPen, faPlus, faPrint} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {decodeRTDBNumber, encodeRTDBNumber} from '@vantix/functions/isomorphic/firebase';
import {T_MONTH} from '@vantix/functions/isomorphic/generics';
import {calculateCompleteStockReceptionItem} from '@vantix/functions/isomorphic/stockReceptions';
import {stripDiacritics} from '@vantix/functions/isomorphic/stringTools';
import {P_StockReception_Source_Shop, P_Supplier_Source_Shop, T_DB_StockReception} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import {format} from 'date-fns';
import roLocale from 'date-fns/locale/ro';
import {onValue, orderByChild, query, ref, startAt} from 'firebase/database';
import {MRT_ColumnDef, MRT_TableOptions, MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {MRT_Localization_RO} from 'material-react-table/locales/ro';
import {Suspense, lazy, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import Button from '@/components/Button';
import Spinner from '@/components/Spinner';
import {useRouteSavedTablePreferences} from '@/utils/tables';
import {devMode} from 'config';
import {RTDB, useRTDBSubscription} from 'project-rtdb';

import classes from './index.module.scss';

const GenerateNIRPDF = lazy(() => import('../StockReceptionEditor/GenerateNIRPDF'));

export default function StockReceptionsList() {
    const {t} = useTranslation('stock-reception');
    const ShopID = useSelector(state => state.user?.settings?.DefaultShopID);
    const Suppliers = useRTDBSubscription([...P_Supplier_Source_Shop, ShopID, 'Supplier']);
    const [StockReceptions, setStockReceptions] = useState<Record<string, T_DB_StockReception>>({});

    useEffect(() => {
        const unsubscribe = onValue(
            query(
                ref(RTDB.db, [...P_StockReception_Source_Shop, ShopID, 'Reception'].join('/')),
                orderByChild('Created'),
                startAt(encodeRTDBNumber(Date.now() - T_MONTH)),
            ),
            (snap) => {
                setStockReceptions(snap.val());
            },
        );

        return () => {
            unsubscribe();
        };
    }, [ShopID]);

    const tableData = useMemo(() => (
        Object.values(StockReceptions || {})
            .filter(StockReception => StockReception?.ID)
            .map(StockReception => ({
                ...StockReception,
                Created: decodeRTDBNumber(StockReception.Created),
                SupplierName: Suppliers?.[StockReception.SupplierID]?.Name,
                TotalAcquisitionPrice: (
                    Object.values(StockReception.Items || {})
                        .map(Item => {
                            const completeItem = calculateCompleteStockReceptionItem(Item);

                            return completeItem.AcquisitionTotalPrice;
                        })
                        .reduce((acc, val) => acc.plus(val), BigNumber('0'))
                        .decimalPlaces(2)
                        .toNumber()
                ) || '',
            }))
            .slice(0, devMode ? 1000 : undefined)
    ), [StockReceptions, Suppliers]);

    const columns = useMemo<MRT_ColumnDef<typeof tableData[number]>[]>(() => [
        {
            id: 'Created',
            header: 'Dată deschidere',
            accessorKey: 'Created',
            filterVariant: 'date-range',
            muiFilterDatePickerProps: {
                views: ['year', 'month', 'day'],
                format: 'dd.MM.yyyy',
            },
            muiTableHeadCellProps: {
                className: classes['DateHeaderCell'],
            },
            sortDescFirst: true,
            Cell: ({cell}) => format(cell.getValue() as number, 'HH:mm dd MMM yyyy', {locale: roLocale}),
            size: 200,
        },
        {
            id: 'NIRID',
            header: 'Nr. NIR',
            accessorKey: 'NIRID',
            filterFn: 'startsWith',
            size: 150,
        },
        {
            id: 'SupplierInvoiceID',
            header: 'Nr. Factură',
            accessorKey: 'SupplierInvoiceNumber',
            filterFn: 'includesString',
            size: 130,
        },
        {
            id: 'Status',
            header: 'Status',
            accessorKey: 'Status',
            accessorFn: (reception) => t(`status/${reception.Status}`),
            filterVariant: 'multi-select',
            size: 150,
        },
        {
            id: 'SupplierName',
            header: 'Furnizor',
            accessorKey: 'SupplierName',
            filterFn: (row, columnID, searchFor) => (
                stripDiacritics(row.original[columnID]).toLowerCase()
                    .includes(stripDiacritics(searchFor).toLowerCase())
            ),
            size: 450,
        },
        {
            id: 'TotalAcquisitionPrice',
            header: 'Valoare achiziție',
            accessorKey: 'TotalAcquisitionPrice',
            filterVariant: 'range',
            muiTableHeadCellProps: {
                className: classes['DateHeaderCell'],
            },
            size: 180,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
    ], []);

    const [currentlyPrintingReceptionIDs, setCurrentlyPrintingReceptionIDs] = useState<string[]>([]);

    const renderRowActions = useCallback<MRT_TableOptions<typeof tableData[number]>['renderRowActions']>(({row}) => (
        <div style={{margin: '2px 0', display: 'flex', gap: '4px'}}>
            <Link key="edit" style={{textDecoration: 'none'}} to={`./edit/${row.original.ID}`}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        // setEditedTX([row.original.TXID, row.original.origin]);
                        // setEditModalOpen(true);
                    }}
                >
                    <FontAwesomeIcon icon={faPen} size="1x" />
                </Button>
            </Link>
            <Button
                size="small"
                variant="outlined"
                onClick={() => {
                    setCurrentlyPrintingReceptionIDs(old => [...new Set([...old, row.original.ID])]);
                }}
            >
                {currentlyPrintingReceptionIDs.includes(row.original.ID) ? (
                    <Suspense fallback={<Spinner size="small" />}>
                        <GenerateNIRPDF
                            ReceptionID={row.original.ID}
                            ShopID={ShopID}
                            mode="print"
                            onDone={() => setCurrentlyPrintingReceptionIDs(old => old.filter(id => id !== row.original.ID))}
                        />
                        <Spinner size="small" />
                    </Suspense>
                ) : (
                    <FontAwesomeIcon icon={faPrint} size="1x" />
                )}
            </Button>
        </div>
    ), [ShopID, currentlyPrintingReceptionIDs]);

    const tablePreferences = useRouteSavedTablePreferences<typeof tableData[number]>({
        defaults: {
            columnFilters: [
                {
                    id: 'Status',
                    value: ['submitted'].map(e => t(`status/${e}`)),
                },
            ],
            sorting: [
                {
                    id: 'NIRID',
                    desc: true,
                },
                {
                    id: 'Created',
                    desc: true,
                },
            ],
            pagination: {
                pageIndex: 0,
                pageSize: devMode ? 25 : 100,
            },
        },
    });

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        enableSortingRemoval: false,
        enableGrouping: false,
        enableExpanding: false,
        enableColumnActions: false,
        enableRowActions: true,
        enableRowVirtualization: devMode,
        enableFacetedValues: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableTableFooter: true,
        autoResetAll: false,
        enableTopToolbar: true,
        enableGlobalFilter: false,
        renderTopToolbarCustomActions: () => !ShopID ? null : (
            <>
                <div style={{marginLeft: 'auto'}} />
                <Link style={{textDecoration: 'none'}} to={`./edit/${RTDB.newKey([...P_StockReception_Source_Shop, ShopID, 'Reception'])}/new`}>
                    <Button variant="outlined">
                        Adaugă recepție&nbsp;&nbsp;<FontAwesomeIcon icon={faPlus} size="lg" />
                    </Button>
                </Link>
            </>
        ),
        positionActionsColumn: 'last',
        renderRowActions,
        memoMode: 'cells',
        localization: MRT_Localization_RO,
        initialState: {
            density: 'compact',
            showColumnFilters: true,
            columnSizing: {
                'mrt-row-actions': 150,
            },
        },
        state: {
            ...tablePreferences.state,
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: '#f5f5f5',
                },
            },
        },
        ...tablePreferences,
    });

    return (
        <div style={{background: '#FFF'}}>
            <div className={classes['StockReceptionsList']}>
                <MaterialReactTable
                    table={table}
                />
            </div>
        </div>
    );
}
