import api from "!../../../../../../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.93.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../../../../node_modules/.pnpm/postcss-loader@5.3.0_postcss@8.4.41_webpack@5.93.0/node_modules/postcss-loader/dist/cjs.js!../../../../../../../../../node_modules/.pnpm/sass-loader@13.3.3_sass@1.77.8_webpack@5.93.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[4].use[3]!./index.module.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);


if (module.hot) {
  if (!content.locals || module.hot.invalidate) {
    var isEqualLocals = function isEqualLocals(a, b, isNamedExport) {
  if (!a && b || a && !b) {
    return false;
  }

  var p;

  for (p in a) {
    if (isNamedExport && p === 'default') {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (a[p] !== b[p]) {
      return false;
    }
  }

  for (p in b) {
    if (isNamedExport && p === 'default') {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!a[p]) {
      return false;
    }
  }

  return true;
};
    var oldLocals = content.locals;

    module.hot.accept(
      "!!../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.93.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../../../../node_modules/.pnpm/postcss-loader@5.3.0_postcss@8.4.41_webpack@5.93.0/node_modules/postcss-loader/dist/cjs.js!../../../../../../../../../node_modules/.pnpm/sass-loader@13.3.3_sass@1.77.8_webpack@5.93.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[4].use[3]!./index.module.scss",
      function () {
        if (!isEqualLocals(oldLocals, content.locals, undefined)) {
                module.hot.invalidate();

                return;
              }

              oldLocals = content.locals;

              update(content);
      }
    )
  }

  module.hot.dispose(function() {
    update();
  });
}

export default content.locals || {};