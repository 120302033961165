import {faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {calculatePriceWithVAT} from '@vantix/functions/isomorphic/products';
import {stripDiacritics} from '@vantix/functions/isomorphic/stringTools';
import Label from '@vantix/functions/isomorphic/zebra-zpl/label';
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@/components/Typography';
import {db} from '@/dexie';
import {useProductData} from '@/utils/indexeddb';
import {SCALE_VENDOR_IDS, SCANNER_VENDOR_IDS} from '@/utils/WebSerial';
import store from 'project-store';

import classes from './index.module.scss';

function ProductLabelRenderer({ProductID, label}: {ProductID: string; label: {Amount: number}}) {
    const Product = useProductData({ProductID});

    return (
        <div className={classes['ProductLabel']}>
            <Typography className={classes['ProductLabel__name']}>
                {Product?.Name}
            </Typography>
            <span>{label?.Amount}</span>
        </div>
    );
}

export default function SerialLabelPrinter() {
    const labelsToPrint = useSelector(state => state.generics.labelsToPrint);

    const [minimized, setMinimized] = useState(false);
    const totalLabelsToPrint = Object.values(labelsToPrint || {}).map(e => e.Amount).reduce((acc, val) => acc + val, 0);

    useEffect(() => {
        if (!(totalLabelsToPrint > 0)) {
            return;
        }

        (async () => {
            try {
                let ports = await window.navigator.serial.getPorts();

                if (ports.length === 0) {
                    await window.navigator.serial.requestPort({
                        filters: [],
                    });
                    ports = await window.navigator.serial.getPorts();
                }

                const port = ports.find(port => (
                    !SCANNER_VENDOR_IDS.includes(port.getInfo().usbVendorId)
                    && !SCALE_VENDOR_IDS.includes(port.getInfo().usbVendorId)
                ));

                await port.open({
                    baudRate: 9600,
                });
                const writer = port.writable.getWriter();

                while (true) { // eslint-disable-line no-constant-condition
                    const labels = store.getState().generics.labelsToPrint;

                    if (Object.keys(labels || {}).length === 0) {
                        break;
                    }

                    const productID = Object.keys(labels)[0];
                    const product = await db.productData.get(productID);
                    const label = labels[productID];

                    for (let printsRemaining = label.Amount; printsRemaining > 0; printsRemaining--) {
                        const label = new Label(35, 25, 8, {minify: false});

                        const barcodeYStart = 25 - 12;

                        if (product.Code.Type === 'EAN13' || product.Code.Type === 'EAN8') {
                            label.drawEAN({
                                position: {
                                    x: 6,
                                    y: barcodeYStart - (product.Code.Type === 'EAN13' ? 0 : 1),
                                },
                                height: 8,
                                length: product.Code.Type === 'EAN13' ? 13 : 8,
                                data: (product.Code.Value || '').slice(0, -1),
                                printHri: true,
                                codeModuleWidth: product.Code.Type === 'EAN13' ? 2 : 3,
                            });
                        }
                        else if (product.Code.Type === 'CODE128') {
                            label
                                .drawCode128({
                                    position: {
                                        x: 0,
                                        y: barcodeYStart,
                                    },
                                    charHeight: 8,
                                    codeModuleWidth: 2,
                                    data: stripDiacritics(product.Code.Value || '').toUpperCase(),
                                    printHri: false,
                                })
                                .raw(`^FB${35 * 8},1,0,C`)
                                .drawText({
                                    position: {
                                        x: 1,
                                        y: barcodeYStart + 8 + 1,
                                    },
                                    text: stripDiacritics(product.Code.Value || '').toUpperCase() + String.raw`\&`,
                                    font: 'ZERO',
                                    charHeight: 16,
                                    charWidth: 16,
                                });
                        }
                        label
                            .raw(`^FB${35 * 8},2,0,L`)
                            .drawText({
                                position: {
                                    x: 1,
                                    y: 2,
                                },
                                text: stripDiacritics(product.Name || '').toUpperCase().slice(0, 50),
                                font: 'D',
                                charHeight: 10,
                                charWidth: 10,
                            });
                        label
                            .drawText({
                                position: {
                                    x: 1,
                                    y: 8,
                                },
                                text: `Pret ${calculatePriceWithVAT(product.Price, product.VATRate).toFixed(2)} Lei`,
                                font: 'Q',
                                charHeight: 8,
                                charWidth: 8,
                            });

                        // label.drawBox({
                        //     position: {
                        //         x: 0,
                        //         y: 1,
                        //     },
                        //     width: 35,
                        //     height: 24,
                        //     thickness: 2,
                        // });

                        await writer.write(new Uint8Array([...label.toZPL()].map(e => e.charCodeAt(0))));

                        await new Promise(resolve => setTimeout(resolve, 0)); // todo: improve UI and set approx print time
                        store.dispatch.generics.update({
                            labelsToPrint: {
                                [productID]: {
                                    Amount: printsRemaining,
                                },
                            },
                        });
                    }

                    const newLabels = {...store.getState().generics.labelsToPrint};
                    delete newLabels[productID];

                    store.dispatch.generics.set({
                        labelsToPrint: newLabels,
                    });
                }

                await writer.close();
                await port.close();
            }
            catch (error) {
                console.log(error);
            }
        })();
    }, [totalLabelsToPrint > 0]);

    if (Object.keys(labelsToPrint || {}).length === 0) {
        return null;
    }

    return (
        <div
            className={clsx(classes['SerialLabelPrinter'], {
                [classes['SerialLabelPrinter--minimized']]: minimized,
            })}
        >
            <Typography
                className={classes['SerialLabelPrinter__title']}
                color="secondary"
                variant="pSmall"
                weight="medium"
                onClick={() => setMinimized(!minimized)}
            >
                Etichete în curs de imprimare ({totalLabelsToPrint})
                <FontAwesomeIcon
                    icon={minimized ? faChevronUp : faChevronDown}
                    style={{marginLeft: 'auto'}}
                />
            </Typography>

            <div className={classes['SerialLabelPrinter__scroll']}>
                {Object.entries(labelsToPrint).map(([ProductID, label]) => (
                    <ProductLabelRenderer key={ProductID} ProductID={ProductID} label={label} />
                ))}
            </div>
        </div>
    );
}
